import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import GraphQlHandler from "api/craft/GraphQlHandler";
import { useLocation } from 'react-router-dom';
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithImage.js";
import OurCommitment from "components/features/TwoColCompany.js";
import CompanyProfile from "components/features/TwoColCompanyProfile.js";
import OurFuture from "components/features/OneColCompanyFuture.js";
import Essence from "components/features/ThreeColCompanyEssence.js";
import TermsAndCondition from "components/features/OneColCompanyTerms.js";
import KeyBenefits from "components/features/OneColKeyCommonBenefits.js";
import Careers from "components/features/TwoColWithButton.js";
import CorporateGovernance from "components/features/TwoColCompanyGovernance.js";
import CorporateStandards from "components/features/OneColCompanyStandards.js";
import DirectorImage from "components/features/OneColVisionWithImage.js";
import Content from "components/cards/TabCardGridCommonContent.js";
import Downloads from "components/features/MultiColumnProductDownloads.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import Values from "components/features/FiveColCompanyValues.js";

import jobsImageSrc from "images/jobs.jpg";
import styled from "styled-components";
import {HeroHeading as HeroHeadingBase, HeroSmallHeading as HeroSmallHeadingBase} from "./components/misc/Headings";
import { HR as HRBase } from "./components/misc/Layouts";
import CommonContent from "./commonContent";

const HR = tw(HRBase)``;
const HeroHeading = tw(
    HeroHeadingBase
)`pt-4 text-3xl md:text-5xl leading-extratight max-w-md
`;
const HeroSmallHeading = tw(
    HeroSmallHeadingBase
)`pt-4 text-3xl md:text-5xl leading-extratight max-w-xl
`;

export default () => {
  const [entries, setCompanyEntry] = useState([]);
  const [globals, setGlobals] = useState([]);
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw``;

  const location = useLocation();
  useEffect(() => {
    GraphQlHandler.query(`
      query MyQuery {
        entries(slug: "company") {
            ... on company_company_Entry {
                  id
                  heading
                  subHeading
                  
                  twoColumnTextAndImage {
                    ... on twoColumnTextAndImage_BlockType {
                      id
                      sectioncontent
                      sectionheading
                      imageDirection
                      file {
                        id
                        url
                      }
                      sectionimage {
                          id
                          url
                         }
                    }
                  }

                  termsAndConditions {
                    ... on termsAndConditions_BlockType {
                      id
                      PurchaseEquipment {
                        url
                      }
                      purchaseServices {
                        url
                      }
                      supplyEquipment {
                        url
                      }
                      supplyEquipmentAndMinor {
                        url
                      }
                      supplyServices {
                        url
                      }
                    }
                  }

                  testimonial {
                    ... on testimonial_BlockType {
                      id
                      tname
                      imageUpload {
                        url
                      }
                      desccontent
                    }
                  }

                  twoColumnSection {
                    ... on twoColumnSection_BlockType {
                      id
                      heading
                      firstColumn
                      secondColumn
                    }
                  }

                  jobs {
                    ... on jobs_BlockType {
                      id
                      heading
                      imageUpload {
                        url
                      }
                      description
                      smallheading
                    }
                  }
                  keyBenefits {
                    ... on keyBenefits_BlockType {
                      id
                      keyBenefitContent
                      showTitle
                      keyBenefitsTitle
                    }
                  }
                  downloads {
                    ... on downloads_BlockType {
                      id
                      downloadType
                      description
                      buttonToggle
                      file {
                        ... on downloads_Asset {
                          id
                          url
                        }
                      }
                      externalUrl
                    }
                  }
                  heroImage {
                    url
                    id
                  }
                  heroIcon {
                    id
                    url
                  }
                  ourFuture {
                    ... on ourFuture_BlockType {
                      id
                      futureContent
                      showhidetitle
                      heading
                    }
                  }
                  standards {
                    ... on standards_BlockType {
                      id
                      description
                    }
                  }
                  ourCommitment {
                    ... on ourCommitment_BlockType {
                      id
                      heading
                      smallheading
                      description
                      secondColumnHeading
                      secondColumnSmallHeading
                      secondColumnDescription
                      secondAsset {
                        url
                      }
                      asset {
                        url
                      }
                    }
                  }
                }
              }
              globalSets(handle: "general") {
                ... on general_GlobalSet {
                  contentTabs{
                    ... on contentTabs_BlockType {
                      id
                      video {
                        id
                        url
                        mimeType
                        size
                      }
                      galleryimages {
                        url
                      }
                      contentTab
                      titleTab
                    }
                  }
                }
              } 
            }
            
      

    `)
        .then((data) => {
            setCompanyEntry(data.entries);
            setGlobals(data.globalSets);
        });

        
  }, [location])
 
  return (

    <AnimationRevealPage>
        {
        (entries || []).map((companyEntry, index) =>
                <>
                        <Hero key={index}
                          heading={<HeroHeading>{companyEntry.heading}<HeroSmallHeading>{companyEntry.subHeading}</HeroSmallHeading></HeroHeading>}
                          smallHeading={<></>}
                          imageSrc={companyEntry.heroImage}
                          iconAlt={<>Company</>}
                          iconSrc={companyEntry.heroIcon}
                          imageCss={imageCss}
                          largeHeader={false}

                    />
                     <KeyBenefits
                                 contents={companyEntry.keyBenefits}     
                    />
                    <OurCommitment 
                    smallHeading={companyEntry.ourCommitment[0].smallheading}

                    secondsmallheading={companyEntry.ourCommitment[0].secondColumnSmallHeading}
                   
                    heading={companyEntry.ourCommitment[0].heading}

                    description={companyEntry.ourCommitment[0].description}

                    video={companyEntry.ourCommitment[0].asset}
                    
                    secondheading={companyEntry.ourCommitment[0].secondColumnHeading}

                    secondescription={companyEntry.ourCommitment[0].secondColumnDescription}
                    
                    secondvideo={companyEntry.ourCommitment[0].secondAsset}
                    />


                    <OurFuture 
                    heading={companyEntry.ourFuture[0].showTitle}
                    description={companyEntry.ourFuture[0].futureContent}/>

                    <DirectorImage 
                    description={companyEntry.testimonial[0].desccontent}
                    smallHeading={companyEntry.testimonial[0].tname}
                    imageSrc={companyEntry.testimonial[0].imageUpload}
                    />


                    <Essence />
                    

                    <CompanyProfile 
                    heading={companyEntry.twoColumnTextAndImage[0].sectionheading}
                    description={companyEntry.twoColumnTextAndImage[0].sectioncontent}
                    imageSrc={companyEntry.twoColumnTextAndImage[0].sectionimage}
                    primaryButtonUrl={companyEntry.twoColumnTextAndImage[0].file}
                    />
                    
                    {/* <Values /> */}
                    
                    <CorporateGovernance 
                    heading={companyEntry.twoColumnSection[0].heading}
                    colonedescription={companyEntry.twoColumnSection[0].firstColumn}
                    coltwodescription={companyEntry.twoColumnSection[0].secondColumn}
                    />

                    <CorporateStandards
                     description={companyEntry.standards[0].description}
                    />
                    <Downloads
                        downloads={companyEntry.downloads}
                    />
                    <Careers
                        heading={companyEntry.jobs[0].heading}
                        smallHeading={companyEntry.jobs[0].smallheading}
                        primaryButtonText={<>View Jobs</>}
                        description={companyEntry.jobs[0].description}
                        primaryButtonUrl={"/careers/"}
                        imageSrc = {companyEntry.jobs[0].imageUpload}
                        textOnLeft={true}
                    />
                    {
                        (globals || []).map((global, index) =>
                            <Content
                                heading={"Solutions"}
                                tabs={global.contentTabs}
                                contentId={"solutions"}
                                description = "Innovative thinking is in our DNA. Since our inception, our engineers and sales teams have proactively worked to understand our customers needs and develop tailored technology solutions fit for Australia's unique environment.<br/>  Our commitment is to develop and innovate, to support our clients by delivering solutions that will continue to power Australian industry."
                            />
                        )
                    }

                    <TermsAndCondition
                      primaryButtonUrl={companyEntry.termsAndConditions[0].PurchaseEquipment}
                      primaryButtonUrlPurchaseServices={companyEntry.termsAndConditions[0].purchaseServices}
                      primaryButtonUrlSupplyEquipment={companyEntry.termsAndConditions[0].supplyEquipment}
                      primaryButtonUrlSupplyEquipmentMinor={companyEntry.termsAndConditions[0].supplyEquipmentAndMinor}
                      primaryButtonUrlSupplyServices={companyEntry.termsAndConditions[0].supplyServices}
                    />

                    <CommonContent
                        showCompanies={false}
                    />
                  
                  
                    
                   
                </>
             )
         }
         <>
          

        
          </>
          
          <Footer />

    </AnimationRevealPage>
  );
}
