import React, {useState, Component, useEffect, useRef } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, SmallHeading as SmallHeadingBase } from "components/misc/Headings.js";
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/Toshiba Header Images - Careers.png";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded bg-contain bg-no-repeat bg-center h-full`,
  ]);
  const TextContent = tw.div`!inline-block p-6 mx-auto lg:py-8 text-left md:text-left`;
  
  const SmallHeading = tw(SmallHeadingBase)`text-left md:text-left`;
  const Heading = tw(SectionHeading)`mt-4 text-left text-3xl sm:text-4xl lg:text-5xl text-left md:text-left leading-tight`;
  const Description = tw.p`mt-4 text-left md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
  
  const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
  const Input = tw.input`mt-6 pl-4 rounded-sm text-black first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
  const Textarea = styled(Input).attrs({as: "textarea"})`
    ${tw`h-24`}
  `;
  const Formcontainer = tw.div`!mx-auto bg-gray-200 mt-8 p-6 lg:p-20 rounded-3xl max-w-md lg:max-w-xl`;
  const Instructions = tw.div`text-black mt-6`;
  const Select = tw.select`p-2 w-full text-black`;

  const Locations = tw.div`text-black first:mt-0 py-3 focus:outline-none font-medium `;
  const Departments = tw.div`first:mt-0  py-3 focus:outline-none font-medium `;
  const Department = tw.div`p-2 text-black inline-block`;
  const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;
  const CV = tw.input `!p-2 !w-full text-black first:mt-0 py-3 focus:outline-none font-medium`;
  const ResultArea = tw.div`mt-8 font-bold text-2xl `;
const  submitButtonText = "Send";
const textOnLeft = true;
const smallHeading = "";
const locationName = "State / Territory or Region";
const heading = "Contact Us";
const description = "Please select a department to send your enquiry to and complete the fields below to submit an enquiry";

export default class ContactUsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            department: '',
            email: '',
            firstname: '',
            lastname: '',
            company: '',
            position: '',
            phone: '',
            location: '',
            subject: '',
            information: '',
            result: '',
            recaptchaValue: '',
            verified: false,
            isSubmitting: false,
            isFormVisible: true
        };
    }

    render(){

        const handleCheckboxChange = (event) => {
            const { checked, value } = event.target;
            this.setState(prevState => {
                let departments = [...prevState.departments];
                if (checked) {
                    departments.length > 0 ? departments.push(" "+value) : departments.push(value);
                } else {
                    departments = departments.filter(department => department !== value);
                }
                return { departments };
            });
        };
        const showForm = (event) => {
            this.setState({ isFormVisible: true });
            this.setState({ result: "" });
            this.setState({isSubmitting: false })
        };
        const handleSubmit = (event) => {
            this.setState({
                isSubmitting: true
            },
            () => {
                event.preventDefault();
                //const axios = require('axios');

                if (!this.state.verified) {
                    console.log('Captcha is required!');
                    this.setState({
                        isSubmitting: false
                    })
                    return {success: false, message: 'Captcha is required.'};

                }
                const formData = new FormData();
                formData.append("tag", this.state.department);
                formData.append("email", this.state.email);
                formData.append("firstname", this.state.firstname);
                formData.append("lastname", this.state.lastname);
                formData.append("company", this.state.company);
                formData.append("position", this.state.position);
                formData.append("department", this.state.department);
                formData.append("phone", this.state.phone);
                formData.append("location", this.state.location);
                formData.append("subject", this.state.subject);
                formData.append("information", this.state.information);
                formData.append("recaptchaValue", this.state.recaptchaValue);
                // console.log(this.state.location);
                var xmlhttp = new XMLHttpRequest();
                xmlhttp.onreadystatechange = () => {
                    if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
                        const responseText = xmlhttp.responseText;
                        //console.log(responseText);
                        this.setState({ isFormVisible: false });
                        this.setState({ result: responseText });
                    }
                    else {
                        this.setState({
                            isSubmitting: false
                        })
                    }
                };
                xmlhttp.open("POST", "/scripts/process_email.php", true);
                xmlhttp.send(formData);
            })
        }

        const verifyCaptcha = (event) => {
            this.setState({
                recaptchaValue: event
            })
            event !== null ?
                this.setState({
                    verified: true
                })
             : this.setState({
                    verified: false
                });

        }


        return (
        <TextContent>
            <Formcontainer>
                
                {smallHeading && <SmallHeading>{smallHeading}</SmallHeading>}
                <Heading>{heading}</Heading>
                {description &&
                    <Description dangerouslySetInnerHTML={{__html: description}}></Description>}
                {this.state.isFormVisible && (
                    <Form onSubmit={handleSubmit}>
                        <Departments>
                            <Select name="department" required id="department" onChange={(event) => this.setState({ department: event.target.options[event.target.selectedIndex].value })} value={this.state.department}>
                                <option value="general-helpdesk">General</option>
                                <option value="hr-helpdesk">Human Resources</option>
                                <option value="qhse-helpdesk">QHSE</option>
                                <option value="iss-helpdesk">Sales ISS</option>
                                <option value="ess-helpdesk">Sales ESS</option>
                            </Select>
                        </Departments>
                        <Input type="email" name="email" onChange={(event) => this.setState({ email: event.target.value })} value={this.state.email} placeholder="Your Email Address" required/>

                        <Input type="text" name="firstname" onChange={(event) => this.setState({ firstname: event.target.value })} value={this.state.firstname}placeholder="First Name" required/>

                        <Input type="text" name="lastname" onChange={(event) => this.setState({ lastname: event.target.value })} value={this.state.lastname}placeholder="Last Name"/>
                        <Input type="text" name="company" onChange={(event) => this.setState({ company: event.target.value })} value={this.state.company}placeholder="Company"/>
                        <Input type="text" name="position" onChange={(event) => this.setState({ position: event.target.value })} value={this.state.position}placeholder="Position"/>
                        <Input type="tel" name="phone" pattern="^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$" onChange={(event) => this.setState({ phone: event.target.value })} value={this.state.phone}placeholder="Phone" required/>

                        <Instructions>Please select your preferred State / Territory or Region: </Instructions>
                        <Locations>
                        <Select name={locationName} required id={locationName} onChange={(event) => this.setState({ location: event.target.options[event.target.selectedIndex].value })} value={this.state.location}>
                                <option value="" disabled>Please select one</option>
                                <option value="New South Wales">New South Wales</option>
                                <option value="Victoria">Victoria</option>
                                <option value="Queensland">Queensland</option>
                                <option value="South Australia">South Australia</option>
                                <option value="Western Australia">Western Australia</option>
                                <option value="Tasmania">Tasmania</option>
                                <option value="Northern Territory">Northern Territory</option>
                                <option value="Australian Capital Territory">Australian Capital Territory</option>
                                <option value="Asia">Asia</option>
                                <option value="Europe">Europe</option>
                                <option value="North America">North America</option>
                                <option value="South America">South America</option>
                            </Select>
                        </Locations>

                        <Input type="text" name="subject" onChange={(event) => this.setState({ subject: event.target.value })} value={this.state.subject}placeholder="Subject" required/>

                        <Textarea name="message" required onChange={(event) => this.setState({ information: event.target.value })} value={this.state.information}
                                  placeholder="Your enquiry"/>
                       <br/>
                        <ReCAPTCHA
                            sitekey="6Lckuj0kAAAAANgDTtJRlCcFOhVU1qhJfZoTkOT6"
                            onChange={verifyCaptcha}

                        />

                        <SubmitButton type="submit" disabled={this.state.isSubmitting}>{submitButtonText}</SubmitButton>
                    </Form>
                )}
                {!this.state.isFormVisible && (
                    <Form onSubmit={showForm}>
                        <SubmitButton type="submit" >Send another email</SubmitButton>
                    </Form>
                )}
            <ResultArea>{this.state.result}</ResultArea>
        </Formcontainer>
        </TextContent>
        );
    }
}
