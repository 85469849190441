import React, {useEffect, useState} from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useLocation } from 'react-router-dom';
import GraphQlHandler from "api/craft/GraphQlHandler";
import { NavHashLink as Link} from 'react-router-hash-link';
import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";
import logo from "../../images/logo-toshiba.svg";
import searchIcon from "../../images/search-icon.svg";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import ScrollToTop from "react-scroll-to-top";

const Header = tw.header`
max-w-screen-xl mx-auto lg:pl-6 
`;

export const NavMenu = tw.div`inline-block h-24 pt-8 z-50 xl:pr-10`;
export const NavLinkContainer = styled.div`
  ${tw`inline-block`};

  :hover .subNavLinks{
    display: block !important;
  }

`;
export const NavLinks = tw.div`block text-white h-24`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */

export const NavLink = styled(Link)`
  ${tw`block text-lg my-2 lg:text-sm lg:mx-6 lg:my-0`};
  ${tw`font-semibold tracking-wide transition duration-300`};
  ${tw`pb-1 border-b-2 border-transparent hover:border-primary-500`};

`;
export const SubNavLinks = styled.div`
  ${tw`inline-block text-white h-24 hidden absolute z-10 pt-0 rounded-lg`};

  `;

export const SubNavLink = styled(Link)`
  ${tw`block text-sm lg:text-sm lg:mx-6 lg:my-0`};
  ${tw`font-semibold tracking-wide transition duration-300`};
  ${tw`pb-1 border-transparent hover:border-primary-500`};
  ${tw`min-h-5 bg-primary-500 pt-5 pl-5 pr-5 pb-5 hover:bg-secondary-500`};
    :hover .tertiaryNavLinks{
    display: block !important;
  }
`;
export const TertiaryNavLinks = tw.div`inline-block text-white hidden relative lg:absolute z-10 mb-4 mt-4 lg:-mt-10 pt-0 lg:ml-42 min-w-full`;
export const TertiaryNavLink = styled(Link)`
  ${tw`block text-sm lg:text-sm lg:mx-6 lg:my-0`};
  ${tw`font-semibold tracking-wide transition duration-300`};
  ${tw`border-b-2 border-transparent hover:border-primary-500`};
  ${tw`min-h-5 bg-primary-500 pt-5 pl-5 pr-5 pb-5 hover:bg-secondary-500`};
`;
const TopArrow = tw.span`text-red-700`;
export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded text-black
  hocus:bg-gray-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-32 mr-3 md:w-40 sm:w-40 lg:w-40 xl:w-40`}
  }
`;
export const SearchLink = styled(NavLink)`
  ${tw`inline items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-5 mr-3 inline`}
  }
`;
// hocus:text-primary-500
export const MobileNavLinksContainer = tw.nav`flex flex-1 p-8 items-center justify-between mx-auto`;
export const NavToggle = tw.button`
z-50 fixed xl:hidden text-white bg-red-1000 mx-6 focus:outline-none hover:bg-primary-500 p-4 text-center right-0
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-50 fixed top-0 inset-x-0 mx-4 my-2 p-8 border border-red-600 text-center rounded-lg text-gray-900 bg-white float-right h-8/12`}
  ${NavLinks} {
    ${tw`flex flex-col items-center text-red-1000 pt-16`}
  }
  
`);

export const DesktopNavLinks = tw.nav`
  hidden justify-between mx-auto
`;

export default ({ roundedHeaderButton = false,
  logoLink,
  searchLink, 
  links, 
  className, 
  collapseBreakpointClass = "lg",
  nodeContent = null }) => {
  /*
   * This header component accepts an optionals "links" prop that specifies the links to render in the navbar.
   * This links props should be an array of "NavLinks" components which is exported from this file.
   * Each "NavLinks" component can contain any amount of "NavLink" component, also exported from this file.
   * This allows this Header to be multi column.
   * So If you pass only a single item in the array with only one NavLinks component as root, you will get 2 column header.
   * Left part will be LogoLink, and the right part will be the the NavLinks component you
   * supplied.
   * Similarly if you pass 2 items in the links array, then you will get 3 columns, the left will be "LogoLink", the center will be the first "NavLinks" component in the array and the right will be the second "NavLinks" component in the links array.
   * You can also choose to directly modify the links here by not passing any links from the parent component and
   * changing the defaultLinks variable below below.
   * If you manipulate links here, all the styling on the links is already done for you. If you pass links yourself though, you are responsible for styling the links or use the helper styled components that are defined here (NavLink)
   */
  const [navigationItems, setNavigationItems] = useState([]);
  const [industriesLinks, setIndustriesItems] = useState([]);
  const [productCategoryLinks, setProductCategoryItems] = useState([]);
  const [productLinks, setProductItems] = useState([]);
  const [servicesLinks, setServicesItems] = useState([]);
  const location = useLocation();
  useEffect(() => {
    GraphQlHandler.query(`
      query MyQuery {
        navigationNodes(level: 1, navHandle: "main") {
          nodeUri
          id
          title
          ... on main_Node {
            id
            children {
              id
              title
              nodeUri
              ... on main_Node {
                id
                children {
                  id
                  title
                  nodeUri
                }
              }
              
            }
          }
        }
        industriesLinks : industriesCategoriesEntries {
          ... on industriesCategories_default_Entry {
            id
            title
            uri
            industryLinks {
              id
              uri
              title
            }
          }
        }
        productCategories: categoriesEntries(level: 1) {
          ... on categories_category_Entry {
            id
            uri
            title
            children {
              id
              uri
              title
            }
          }
        }
        productEntries(treatAsCategory: "yes") {
          ... on product_products_Entry {
            id
            uri
            title
          }
        }
        servicesCategories: servicesCategoriesEntries(level: 1) {
          ... on servicesCategories_default_Entry {
            id
            uri
            title
            children {
              id
              uri
              title
            }
          }
        }
      }

    `)
    .then((data) => {
      setNavigationItems(data.navigationNodes);
      setIndustriesItems(data.industriesLinks);
      setProductCategoryItems(data.productCategories);
      setProductItems(data.productEntries);
      setServicesItems(data.servicesCategories);
    });


  }, [location])

  const defaultLinks = [

    <NavMenu>
      <NavLinks key={1}>
        {
          (navigationItems || []).map((node, index) =>
              <NavLinkContainer key={index}>
                    <NavLink key={node.id} to={`/${node.nodeUri}${!node.nodeUri.toString().includes("#") ? "/" : ""}`}>
                        {node.title}
                        { node.title == "Contact us" || node.title == "Solutions"  ||
                            <span className="nav-indicators"> &gt; </span>
                      }
                    </NavLink>
                
                  <SubNavLinks key={node.id + index} className={"subNavLinks"}>
                    {
                      (node.children || []).map((child, childIndex) =>
                      <SubNavLink key={parseInt(child.id + childIndex)} to={`/${child.nodeUri}`}>{child.title}
                       </SubNavLink>
                      )
                    }
                    
                  {/*Third Level Nav's for Products*/}
                  {node.title == "Products" &&
                      (productCategoryLinks || []).map((child, childIndex) =>
                          <SubNavLink key={child.id} to={`/${child.uri}`}>{child.title}
                           { child.children && child.children.length > 0 &&
                              <span className="sub-nav-indicators"> &gt; </span>
                            }
                            {(child.children &&
                                <TertiaryNavLinks key={parseInt(child.id + childIndex)}
                                                  className={"tertiaryNavLinks"}>
                                  {(child.children || []).map((tertiaryLink, thirdChildIndex) =>
                                      <TertiaryNavLink key={parseInt(thirdChildIndex.id + thirdChildIndex)}
                                                       to={`/${tertiaryLink.uri}`}>{tertiaryLink.title}</TertiaryNavLink>
                                  )}
                                </TertiaryNavLinks>
                            )}
                          </SubNavLink>
                      )
                  }
                  
                  { node.title == "Products" &&
                      /*Some products can be set and treated as a category, so they need to be added to the menu*/
                      (productLinks || []).map((child, childIndex) =>
                          <SubNavLink key={childIndex} to={`/${child.uri}`}>{child.title}</SubNavLink>
                      )
                  }
                  {/*Third Level Nav's for Industries*/}
                  { node.title == "Industries" &&
                     (industriesLinks || []).map((child, childIndex) =>
                          <SubNavLink key={child.id} to={`/${child.uri}`}>{child.title}
                            { child.industryLinks && child.industryLinks.length > 0 &&
                              <span className="sub-nav-indicators"> &gt; </span>
                            }
                            {(child.industryLinks &&
                            <TertiaryNavLinks key={parseInt(child.id + childIndex)} 
                                            className={"tertiaryNavLinks"}>
                              {(child.industryLinks || []).map((tertiaryLink, thirdChildIndex) =>
                                      <TertiaryNavLink key={parseInt(tertiaryLink.id + thirdChildIndex)} to={`/${tertiaryLink.uri}`}>{tertiaryLink.title}</TertiaryNavLink>
                              )}
                            </TertiaryNavLinks>
                          )}
                          </SubNavLink>
                      )
                  }
                    {/*Third Level Nav's for Services*/}
                    { node.title == "Services" &&
                        (servicesLinks || []).map((child, childIndex) =>
                            <SubNavLink key={child.id} to={`/${child.uri}`}>{child.title}
                              { child.industryLinks && child.industryLinks.length > 0 &&
                                  <span className="sub-nav-indicators"> &gt; </span>
                              }
                              {(child.industryLinks &&
                                  <TertiaryNavLinks key={parseInt(child.id + childIndex)} className={"tertiaryNavLinks"}>
                                    {(child.industryLinks || []).map((tertiaryLink, thirdChildIndex) =>
                                        <TertiaryNavLink key={parseInt(tertiaryLink.id + thirdChildIndex)} to={`/${tertiaryLink.uri}`}>{tertiaryLink.title}</TertiaryNavLink>
                                    )}
                                  </TertiaryNavLinks>
                              )}
                            </SubNavLink>
                        )
                    }
                  </SubNavLinks>

              </NavLinkContainer>
          )
        }

      {/* <SearchLink href="/" className="search-icon">
        <img src={searchIcon} alt="search" />
      </SearchLink>
      <PrimaryLink href="/#" className="search-button">Sign In</PrimaryLink> */}
      </NavLinks>
      
    </NavMenu>
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink to="/" className="companylogo">
      <img src={logo} alt="logo" />
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  return (
    <Header className={className || "header-light headersection"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
      </DesktopNavLinks>

      <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
        {logoLink}
        <MobileNavLinks initial={{ x: "150%", display: "none" }} animate={animation} css={collapseBreakpointCss.mobileNavLinks}>
          {links}
        </MobileNavLinks>
        <NavToggle onClick={toggleNavbar} className={showNavLinks ? "open" : "closed"}>
          {showNavLinks ? <CloseIcon tw="w-6 h-6" /> : <MenuIcon tw="w-6 h-6" />}
        </NavToggle>
      </MobileNavLinksContainer>
      <ScrollToTop smooth
        viewBox="0 0 24 24" 
        component={<TopArrow>&#8593;</TopArrow>} />
    </Header>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:flex`,
    desktopNavLinks: tw`sm:hidden`,
    mobileNavLinksContainer: tw`sm:flex`
  },
  md: {
    mobileNavLinks: tw`md:flex`,
    desktopNavLinks: tw`md:hidden`,
    mobileNavLinksContainer: tw`md:flex`
  },
  ipad: {
    mobileNavLinks: tw`lg:flex`,
    desktopNavLinks: tw`lg:hidden`,
    mobileNavLinksContainer: tw`lg:flex`
  },
  lg: {
    // mobileNavLinks: tw`lg:flex`,
    // desktopNavLinks: tw`lg:hidden`,
    // mobileNavLinksContainer: tw`lg:flex`
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xxl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  }
};
