import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { NavHashLink as HashLink} from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';
import { css } from "styled-components/macro"; //eslint-disable-line
import LogoImage from "images/logo-toshiba.svg";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";
import { ReactComponent as LinkedInIcon } from "images/linkedIn.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
import GraphQlHandler from "../../api/craft/GraphQlHandler";


const Container = tw.div`relative bg-secondary-500 text-gray-100 -mx-8`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10 pr-10 pl-10 pb-0 lg:pb-10`;
const TwoColumns = tw.div`flex flex-wrap md:justify-between md:ml-8 lg:ml-0`;
const FourColumns = tw.div`flex text-left flex-wrap pl-10 pb-0 pt-0 text-center sm:text-left sm:justify-start md:justify-between -mt-12 lg:px-2`;
const Column = tw.div`text-left pl-0 pt-0 mb-0 sm:mb-12 pb-0 w-full lg:pt-10 sm:px-0 sm:w-1/2 md:w-1/2 md:p-10 mt-12 lg:mb-16 lg:w-auto lg:pt-16 lg:pb-10 lg:w-1/4`;
const ColumnHeading = tw.h5`uppercase font-bold`;
const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3 before:content-['> ']`;

const Link = styled(HashLink)`
  ${tw`border-b-2 leading-6 border-transparent hocus:border-gray-100 pb-1 transition duration-300`}
`;
const BottomDivider = tw.div`my-16 pt-16 mt-0 mb-2 border-b-2 border-white w-full lg:hidden md:hidden sm:block`;
const Divider = tw.div`my-16 mt-0 mb-16 border-b-2 border-white w-full`;
const OneColRow = tw.div`flex-row mb-0 p-0 mt-16 sticky bottom-0 w-full h-16 flex flex-col md:flex-row items-center justify-center h-12 bg-gray-700 text-white mt-3 mb-0 sm:mb-0`;
const CopywrightNotice = tw.p`text-center text-xs pb-0 sm:text-xs mt-0 p-2 md:mt-0 font-medium text-gray-400`;
const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-40`; /* filter-white-100*/
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-100`;
const MobileSocialLinksContainer = tw.div`flex object-center items-center mx-auto mt-8 md:mt-0 flex sm:block flex object-center items-center mx-auto md:hidden lg:hidden`;
const SocialLinksContainer = tw.div`hidden mt-0 w-0 md:mt-0 flex sm:hidden md:block lg:block ml-auto md:mr-20 lg:mr-10 sm:mr-0`;

const MobileSocialLink = styled.a`
  ${tw`mx-auto cursor-pointer p-12 rounded-full text-gray-900 hover: text-secondary-900 transition duration-300 sm:text-center mx-auto flex`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const SocialLink = styled.a`
  ${tw`hidden m-0 w-0 p-0 md:block lg:block cursor-pointer p-2 rounded-full text-gray-900 hover:text-secondary-900 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {
  const location = useLocation();
  const [productCatsMenuNav, setProductCatsMenuNav] = useState([]);
  const [productMenuNav, setProductMenuNav] = useState([]);
  const [quickLinksNav, setQuickLinksNav] = useState([]);
  const [companyNav, setCompanyNav] = useState([]);
  const [servicesNav, setServicesNav] = useState([]);
  const [globals, setGlobals] = useState([]);
  useEffect(() => {
    GraphQlHandler.query(`
    query MyQuery {
      productCategories : categoriesEntries(level: 1) {
        ... on categories_category_Entry {
          id
          uri
          title
        }
      }
      productEntries(treatAsCategory: "yes") {
          ... on product_products_Entry {
            id
            uri
            title
          }
        }
      serviceCategories : servicesCategoriesEntries(level: 1) {
        ... on servicesCategories_default_Entry {
          id
          uri
          title
        }
      }
       footerNav: navigationNodes(level: 1, navHandle: "footerQuickLinks") {
          ... on footerQuickLinks_Node {
            id
            title
            nodeUri
            children {
              uri
              title
            }
          }
        }
        companyNav: navigationNodes(level: 1, navHandle: "main", title: "Company") {
          ... on main_Node {
            id
            title
            nodeUri
            children {
              nodeUri
              title
            }
          }
        }
      globalSets {
        ... on general_GlobalSet {
          id
          name
          footerCopyright
        }
        ... on social_GlobalSet {
          id
          name
          socialplatforms {
            ... on socialplatforms_BlockType {
              id
              uri
              socialLink
            }
          }
        }
      }   
    }


    `)
      .then((data) => {
        setProductCatsMenuNav(data.productCategories);
        setProductMenuNav(data.productEntries);
        setQuickLinksNav(data.footerNav);
        setCompanyNav(data.companyNav);
        setServicesNav(data.serviceCategories);
        setGlobals(data.globalSets);
        
      });
     

  }, [location])

  function refresh() {
    window.location.reload();
    window.scrollTo(0, 0);
  }

  return (
    <Container>
      {/* some-page-wrapper */}
      <Content className="row">
        <TwoColumns>
          <Column>
          <HashLink to="/">
            <LogoContainer>
              <LogoImg src={LogoImage} className="logoFooter"/>
            </LogoContainer>
            </HashLink>
          </Column>
          <Column className="hidden-xs">
            <SocialLinksContainer>
              {
                globals.map((global, index) => (
                    global.name === "Social" &&
                  <SocialLink href={global.socialplatforms[0].socialLink} target="_blank" key={index}>
                    <LinkedInIcon />
                  </SocialLink>
                ))
              }

            </SocialLinksContainer>
          </Column>
        </TwoColumns>
        <Divider />
        <FourColumns>
          <Column>
            <ColumnHeading>Products</ColumnHeading>
            <LinkList>
              {
                (productCatsMenuNav || []).map((node, index) =>
                      <LinkListItem key={index} id="products" onClick={refresh}>
                        <Link to={`/${node.uri}`}>{node.title}</Link>
                      </LinkListItem>

                )
              }
              {
                (productMenuNav || []).map((node, index) =>
                    <LinkListItem key={index}  onClick={refresh}>
                      <Link to={`/${node.uri}`}>{node.title}</Link>
                    </LinkListItem>

                )
              }
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Services</ColumnHeading>
            <LinkList>
              {
                (servicesNav || []).map((node, index) =>
                    <LinkListItem key={index}  onClick={refresh}>
                      <Link to={`/${node.uri}`}>{node.title}</Link>
                    </LinkListItem>

                )
              }
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Company</ColumnHeading>
            <LinkList>
              { companyNav &&
                (companyNav || []).map((node, index) =>
                    (node.children || []).map((childNode, childIndex) =>
                   

                      childNode.title === "Careers" ?
                          (<LinkListItem key={childIndex} onClick={refresh}> <Link to={`/${childNode.nodeUri}` }>{childNode.title} </Link> </LinkListItem> )
                      :
                          (<LinkListItem key={childIndex}> <Link to={`/${childNode.nodeUri}` }>{childNode.title} </Link> </LinkListItem> )

                  
                   )
                )
              }
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Quick Links</ColumnHeading>
            <LinkList>
            <LinkListItem onClick={refresh}><Link to="/">Home</Link></LinkListItem>
              {
                (quickLinksNav || []).map((node, index) =>
                    <LinkListItem key={index}  onClick={refresh}>
                      <Link key={node.id} to={`/${node.nodeUri}${!node.nodeUri.toString().includes("#") ? "/" : ""}`}>{node.title}</Link>
                    </LinkListItem>

                )
              }
            </LinkList>
          </Column>
        </FourColumns>
        <BottomDivider/>
      <MobileSocialLinksContainer>
        {
          globals.map((global, index) => (
              global.name === "Social" &&
            <MobileSocialLink key={index} href={global.socialplatforms[0].socialLink} target="_blank">

                <LinkedInIcon />
            </MobileSocialLink>
          ))
        }
       </MobileSocialLinksContainer>
      </Content>
      <OneColRow>
        {
          globals.map((global, index) => (
              global.name === "General" &&
                    <CopywrightNotice key={index}>{global.footerCopyright}</CopywrightNotice>

          ))
        }

      </OneColRow>
     
    </Container>
  );
};
