import React,{useState} from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, SmallHeading as SmallHeadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import BrochureImage from "images/brochure.svg";
import ManualImage from "images/manual.svg";
import DownloadImage from "images/download.svg";
import WarrantyImage from "images/warranty.svg";
import ContactImage from "images/contact.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import { Modal,Dialog } from 'react-aria-components';
import MarketingForm from "components/forms/MarketingForm";


const Heading = tw(SectionHeading)``;
const SmallHeading = tw(SmallHeadingBase)`font-bold`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`flex items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto flex flex-wrap justify-around`}
`;
const Column = styled.div`
  ${tw`w-full items-center flex max-w-xs mr-5 mt-6 w-5/6 sm:w-full md:w-5/6 lg:w-1/6 xl:w-1/6 px-0`}
  box-shadow: 0px 3px 6px #00000029;
`;
const Card = styled.a`
  ${tw`flex flex-col h-full mx-auto px-6 py-8 rounded-full transition-transform duration-300 hover:cursor-pointer transform hover:scale-105`}
  .imageContainer {
    ${tw`text-center rounded-full p-4 mx-auto bg-white object-center`}
    img {
      ${tw`w-16 h-16 mx-auto`}
    }
  }
  .btitle {
    ${tw`mt-4 font-bold text-base text-center leading-none text-black`}
  }
  .description {
    ${tw`mt-4 text-sm font-medium text-gray-700`}
  }
  .link {
    ${tw`mt-auto inline-flex left-0 pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

const ModalExtended = tw(Modal)`fixed flex top-0 left-0 bg-gray-700/50 w-full h-full justify-center items-center z-20 outline-none`;
const DialogExtended =tw(Dialog)`flex bg-white  overflow-auto outline-none w-full lg:w-auto h-full lg:h-auto lg:rounded`

export default function Downloads({

  downloadTypes = [
    {
      imageSrc: BrochureImage,
      iconType: "brochure"
    },
    {
      imageSrc: ManualImage,
      iconType: "manual"
    },
    {
      imageSrc: DownloadImage,
      iconType: "others"
    },
    {
      imageSrc: WarrantyImage,
      iconType: "warranty"
    },
    {
      imageSrc: ContactImage,
      iconType: "contact"
    }
  ],
  downloads = null,
  RenderedComponent = null,
  linkText = "Find out More",
  heading = "",
  smallHeading = "",
  description = "",
  imageContainerCss = null,
  imageCss = null,
  link = "",
  slug,
  tag,
}) {
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */

  const [isModalOpen, setModalOpen] = useState(false);
  const supportedSlugs = ['scib-batteries-1','high-voltage-motors','flameproof-motors-ex-d','vhe-series-motors'];
  const hasMarketingForm = supportedSlugs.includes(slug);

  return (
    <Container>
      <ContentWithPaddingXl>
        {smallHeading && <SmallHeading>{smallHeading}</SmallHeading>}
        {heading && <Heading>{heading}</Heading>}
        {description && <Description>{description}</Description>}
        <ThreeColumnContainer>
            {downloads &&
                downloads.map((card, i) => {
                  return <Column key={i} className="card-flex">
                        <Card 
                          href={ hasMarketingForm
                            ? null
                            : card.file.length > 0  
                                ? (link = card.file[0].url)
                                : (link = card.externalUrl)
                          } 
                          onClick={ hasMarketingForm
                            ? (()=>setModalOpen(true))
                            : null
                          }
                          target="_blank"
                        >
                          <span className="imageContainer" css={imageContainerCss}>
                              {downloadTypes.map((dltype) => (
                                  <>
                                      {dltype.iconType == card.downloadType && (
                                          <img src={dltype.imageSrc} alt="" css={imageCss}/>
                                      )}
                                  </>
                              ))}
                          </span>
                          <span className="btitle">{card.description}</span>
                        </Card>
                    </Column>
                }
                )
            }
            {/*Add the Contact Us Link on every page that has this downloads section*/}
            <Column key="contact"  className="card-flex">
                <Card href="/contact-us/">
                 <span className="imageContainer" css={imageContainerCss}>
                       <img src={ContactImage} alt="" css={imageCss}/>
                </span>
                    <span className="btitle">Contact Us</span>
                </Card>
            </Column>
        </ThreeColumnContainer>
        <ModalExtended 
          isDismissable
          isOpen={isModalOpen} 
          onOpenChange={setModalOpen}
         
        >
          <DialogExtended aria-label={'dialog'} style={{zIndex:75}} >
            <MarketingForm tag={tag} setModalOpen={setModalOpen}/>
          </DialogExtended>        
        </ModalExtended>
      </ContentWithPaddingXl>
    </Container>
  );
};
