import React from "react";
import tw from "twin.macro";
// import { ReadMoreToggler } from 'read-more-read-less-toggler';
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, SmallHeading as SmallHeadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import SolutionImageSrc from "images/solution-1.png";
// import TriangleDecoration from "images/purple-triangle.svg";
// import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
// import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
// import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

const Container = tw.div`relative pb-20 pt-20 lg:pt-0 md:pt-0 mt-10 text-center bg-blue-1000`;
const TwoColumn = tw.div`py-0 p-2 mx-auto text-center md:justify-between max-w-screen-xl mx-auto py-0 md:pt-16 pb-0 items-center mx-auto`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative rounded-full mx-auto mb-10`;
const TextColumn = styled(Column)(props => [
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded-full w-48 h-48 mx-auto`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
],
    `
    padding: 0px;
    `
);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const SmallHeading = tw(SmallHeadingBase)`pt-6 text-white text-center text-lg md:text-center`;
const Description = tw.p`px-20 italic text-center font-hairline text-white md:text-left text-sm md:text-sm font-hairline lg:text-sm font-medium leading-normal font-hairline mx-10 sm: px-0`;


export default ({
  smallHeading = "Shimizu-san - (Title/Position)",
  description = "We at Toshiba understand that satisfying our customer’s needs is essential to the success of our company. This success can be seen in our daily activities that incorporate Toshiba’s innovative and creative products, technologies and services with local knowledge and solutions. We are proud of Toshiba’s history and its path to the future.  Please come and join us as we move forward to contribute to a better world.",
  primaryButtonText = "Read More",
  primaryButtonUrl = "#",
  imageSrc = SolutionImageSrc,
  buttonRounded = false,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  showDecoratorBlob = true,
  textOnLeft = false,
  features = null,
  iconRoundedFull = true,
  iconFilled = true,
  iconContainerCss = null
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.


  return (
    <Container>
      <TwoColumn>
      <ImageColumn>
        <div className="imageBanner">
          <Image src={imageSrc[0]?.url} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
        </div>
        </ImageColumn>
        <TextColumn>
          <TextContent>
            <Description dangerouslySetInnerHTML={{ __html: description}} />
            <SmallHeading dangerouslySetInnerHTML={{ __html: smallHeading}} />
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
