import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import {Controls, PrevButton, NextButton, CardSlider} from "components/misc/Slider";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";

import {motion} from "framer-motion";
import companyDemo from "../../images/companies-logo.svg";
import caseStudyDemo from "images/case-study-1.png";
import Decorator from "images/decorator-triangle.svg";

const Container = tw.div`relative`;
const DecoratorTriangle = styled.div `
.decorator {
  float:left;
  // width: 600px !important;
  // margin-top: 78px !important;
  // height: 57rem !important;
}
`;

const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24 `;
const LeftColumn = tw.div`relative lg:w-8/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:w-4/12 flex flex-col justify-center`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)`flex`;
const SmallHeading = tw.div`tracking-wider text-sm font-medium text-primary-500 `;

const Card = tw(motion.a)`h-full flex! flex-col max-w-sm relative focus:outline-none`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-144 w-144 sm:h-72 bg-cover bg-center`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4 text-black`;


const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;
const CardButton = tw(PrimaryButtonBase)`text-sm mt-4`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6 uppercase`;
export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    autoplay: false,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    dots: false,
    draggable: true,
  };

  /* Change this according to your needs */
  const images = [
        {
          imageSrc: caseStudyDemo
        },
        {
          imageSrc: caseStudyDemo
        },
        {
          imageSrc: caseStudyDemo
        }
      ],
  linkText = "Read More",
  heading = "",
  smallHeading = "Company Images"
  return (
    <Container className="content-tab-slider">
        {images.map((image, index) => (
            <div>URL{image.url}</div>
        ))}
        <CardSlider ref={setSliderRef} {...sliderSettings}>

          {images.map((image, index) => (
            <Card key={index} >
                URL: {image.url}
                <CardImage imageSrc={image.url} />
            </Card>

          ))}

        </CardSlider>
        <Controls className="indicators">
            <PrevButton onClick={sliderRef?.slickPrev}><ChevronLeftIcon/></PrevButton>
            <NextButton onClick={sliderRef?.slickNext}><ChevronRightIcon/></NextButton>
        </Controls>

    </Container>
  );
};
