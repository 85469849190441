import React, { useEffect, useState  } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {SectionHeading, HeroHeading as HeroHeadingBase, HeroSmallHeading as HeroSmallHeadingBase} from "components/misc/Headings";

import Services from "components/features/ThreeColSliderServices.js";
import Companies from "components/features/FourColSliderCompanies.js";
import Industries from "components/features/ThreeColSliderIndustries.js";

import GraphQlHandler from "api/craft/GraphQlHandler";
import Products from "./components/features/ThreeColSliderProducts";


export default ({
    showIndustries = true,
    showServices = true,
    showCompanies = false,
    showProducts = true
}) => {
  const [industries, setIndustriesEntry] = useState([]);
  const [services, setServicesEntry] = useState([]);
  const [globalContent, setGlobalContent] = useState([]);
  const [productCats, setProductCats] = useState([]);
  const [productAsCats, setProductAsCats] = useState([]);

    useEffect(() => {
      GraphQlHandler.query(`
      query MyQuery {
          industries : entries(section: "industriesCategories") {
            ... on industriesCategories_default_Entry {
              id
              cardImage: industriesCategoryImage {
                ... on generalImages_Asset {
                  id
                  url
                }
              }
              heading
              uri
            }
          }
          globalContent : globalSets(handle: "general") {
            ... on general_GlobalSet {
              industriesSection {
                ... on industriesSection_BlockType {
                  id
                  heading
                  shortDescription
                }
              }
            }
          }   
          services: servicesCategoriesEntries {
            ... on servicesCategories_default_Entry {
              id
              heading
              cardImage : servicesCategoryImage {
                url
              }
              uri
              slug
              shortDescription
            }
          } 
          productCats: categoriesEntries(level: 1) {
            ... on categories_category_Entry {
              id
              title
              productCategoryImage {
                ... on productImages_Asset {
                  id
                  url
                }
              }
              uri
            }
          }
          productAsCats : productEntries(treatAsCategory: "Yes") {
            ... on product_products_Entry {
              id
              uri
              title
              
              productCategoryImage : productMainImage {
                ... on productImages_Asset {
                  id
                  url
                }
              }
            }
          }     
        }

      `)
      .then((data) => {
          setIndustriesEntry(data.industries);
          setServicesEntry(data.services);
          setGlobalContent(data.globalContent);
          setProductCats(data.productCats);
          setProductAsCats(data.productAsCats);
      });

    }, [])

  return (
        <>
            {showProducts &&
                    <Products
                        heading={<>Our Products</>}
                        cards={productCats.concat(productAsCats)}

                    />
            }
            {showServices &&
                <Services
                    cards={services}
                    imageContainerCss={tw`p-2!`}
                    imageCss={tw`w-20! h-20!`}
                />
            }
            {showIndustries &&
                (globalContent || []).map((content) =>
                    <Industries
                        smallHeading={<>Industries</>}
                        heading={content.industriesSection[0].heading}
                        description={content.industriesSection[0].shortDescription}
                        cards={industries}
                        showDescription={false}
                    />
                )
            }
            {showCompanies &&
                <Companies

                />
            }
        </>

  );
}

