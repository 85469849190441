import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import GraphQlHandler from "api/craft/GraphQlHandler";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithImage.js";
import Solutions from "components/features/TwoColSolutions.js";
import KeyBenefits from "components/features/OneColKeyBenefitsSolutions.js";
import Content from "components/cards/TabCardGridCommonContent.js";
import InfoRows from "components/features/VerticalRowsWithInfoProducts.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import styled from "styled-components";


import {HeroHeading as HeroHeadingBase, HeroSmallHeading as HeroSmallHeadingBase} from "./components/misc/Headings";
import { HR as HRBase } from "./components/misc/Layouts";
import CommonContent from "./commonContent";

const HR = tw(HRBase)``;
const HeroHeading = tw(
    HeroHeadingBase
)`pt-4 text-3xl md:text-5xl leading-extratight max-w-md
`;
const HeroSmallHeading = tw(
    HeroSmallHeadingBase
)`pt-4 text-3xl md:text-5xl leading-extratight max-w-xl
`;

export default () => {
  const [entries, setSolutionsEntry] = useState([]);
  const SmallHeading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw``;

  useEffect(() => {
    GraphQlHandler.query(`
      query MyQuery {
             entries(section: "solutions") {
                ... on solutions_default_Entry {
                  id
                  title
                  contentTabs(title: "") {
                    ... on contentTabs_BlockType {
                      id
                      video {
                        id
                        url
                        mimeType
                        size
                      }
                      galleryimages {
                        id
                        url
                        title
                      }
                      contentTab
                      titleTab
                    }
                  }
                  keyBenefits {
                        ... on keyBenefits_BlockType {
                          id
                          keyBenefitContent
                          showTitle
                          keyBenefitsTitle
                        }
                      }
                  heroImage {
                    ... on productImages_Asset {
                      id
                      url
                    }
                    url
                  }
                  infoRows {
                    ... on infoRows_BlockType {
                      id
                      displaytitle
                      description
                      image {
                        id
                        url
                      }
                    }
                  }
                  icon {
                    url
                  }
                }
              }
            }

    `)
        .then((data) => {
            setSolutionsEntry(data.entries);
        });


  }, [])
  return (

    <AnimationRevealPage>
        {
        (entries || []).map((solutionsEntry, index) =>


                <>


                    <Hero
                        key={index}
                        heading={<HeroHeading>{solutionsEntry.heading}<HeroSmallHeading>{solutionsEntry.subHeading}</HeroSmallHeading></HeroHeading>}
                        smallHeading={<></>}
                        imageSrc={solutionsEntry.heroImage}
                        iconAlt={<>Solutions</>}
                        iconSrc={solutionsEntry.heroIcon}
                        imageCss={imageCss}
                    />

                    <KeyBenefits
                                 key={index}
                                 className="keyBenefits"
                                 heading={solutionsEntry.keyBenefits[0].keyBenefitsTitle !== null ? solutionsEntry.keyBenefits[0].keyBenefitsTitle : "Key Benefits"}
                                 contents={solutionsEntry.keyBenefits}

                    />

                    <Content
                        key={index}
                        tabs={solutionsEntry.contentTabs}

                    />


                    <InfoRows
                        key={index}
                        information={solutionsEntry.infoRows}
                        heading={<>Hydrogen energy business</>}
                        smallHeading={<>Learn More</>}
                        linkText={<>Find Out More</>}
                    />
                </>
             )
         }
         <>
          <HR/>
          <Solutions

          />
             <CommonContent/>
         </>

          <Footer />

    </AnimationRevealPage>
  );
}
