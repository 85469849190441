import React, {useState} from "react";
import tw from "twin.macro";

const emptyForm = {
  name:'',
  email:'',
  isExistingCustomer:false,
  isRequestingMore:false,
}

const Container = tw.div`container mx-auto p-8 bg-white rounded mt-16 lg:mt-0 `;
const TopRow = tw.div`flex justify-end mb-4 `;
const FormHeading = tw.div`mb-8 text-2xl  text-gray-800`;
const CloseBtn = tw.button` flex items-center pl-2 pr-1 py-1 mt-2 text-gray-800 rounded bg-gray-200 hover:bg-gray-300`;
const Form = tw.div`bg-white`;
const TopPanel = tw.div`mr-8 w-full `;
const BottomPanel = tw.div`flex flex-col  lg:w-[480px]`;
const InputLabel = tw.div`block text-gray-600 text-sm ml-1`;
const CheckboxesBlock = tw.div`mt-4`;
const CheckboxWrapper = tw.div`flex items-center mt-2`;
const Checkbox = tw.input`w-4 h-4`;
const CheckboxText = tw.span`ml-2 text-sm text-gray-800`;
const SendButton = tw.button` w-40  bg-blue-500 hover:bg-blue-600 px-8 py-2 rounded border-2 border-transparent text-white focus:outline-none focus-visible:border-gray-700 focus-visible:border-2`;

const inputFieldClass = "appearance-none bg-gray-200 rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none border-2 border-gray-200 focus:border-gray-500 placeholder:italic placeholder:text-gray-400 ";

const InputField = tw.input`${inputFieldClass}`;
const ValidationMessage = tw.p`text-xs ml-1 mt-1 text-red-400 text-right`;
const CheckboxesHint = tw.div`text-xs`;
const CloseCrossSVG = tw.svg`h-5 w-5 ml-1`;
const BottomRow = tw.div`flex justify-end pt-4`;
const InputFieldsWrapper = tw.div``;
const FeedbackMessageDiv = tw.div`h-4 flex items-center mt-4 text-sm`;
const FeedbackMessage = tw.div` ml-2`;
// const ResponseErrorDiv = tw.div`mb-4 md:mb-0 text-xs text-gray-600 mt-2`;



export default function MarketingForm({tag='new products', setModalOpen}) {

  const [formState, setFormState] = useState(emptyForm);
  const [errors, setErrors] = useState({});
  const [isFormSubmited, setFormSubmited] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState({text:'', success: false, error:''});
  const [formSuccess, setFormSuccess] = useState(false);

  const handleFormChange = e => {
    const newFormState = {
      ...formState,
      [e.target.name]: e.target.type === 'checkbox' 
        ? e.target.checked 
        : e.target.value 
    }

    feedbackMessage.text && setFeedbackMessage({text:'', success:true});
    setFormState(newFormState);
    validateForm(newFormState, isFormSubmited);
        
  }

  const validateForm = (formState, isFormSubmited) => {
    if(isFormSubmited){
      let newErrors = {...errors}
      //name
      if(!formState.name){
        newErrors={...newErrors, name:'Name is required'}
      };     
      if(formState.name && formState.name.length<3){
        newErrors={...newErrors,name:'Minimum 3 characters'}
      };        
      if(formState.name && formState.name.length>=3){
        newErrors={...newErrors, name:''}
      };
        
      //email
      if(!formState.email){
        newErrors={...newErrors, email:'Email is required'}
      };      
      if(formState.email && /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/.test(formState.email)){
        newErrors={...newErrors, email:''}          
      };
      if(formState.email && !(/^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/.test(formState.email))){
        newErrors={...newErrors, email:'Email is not valid'}         
      };
      
      //validate ticks
      
      if(isFormSubmited && (formState.isExistingCustomer || formState.isRequestingMore)){
        newErrors={...newErrors, hasMinOneTick:true}
      };
      if(!formState.isExistingCustomer && !formState.isRequestingMore){
        newErrors={...newErrors, hasMinOneTick:false}
      };

      const isValidated = 
        !newErrors.name &&
        !newErrors.email &&
        newErrors.hasMinOneTick;
      
      setErrors(newErrors); 
      return isValidated;
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();
    setFormSubmited(true);
    const isFormValidated = validateForm(formState, true);
    setFeedbackMessage({text:'', success:true});
        
    if(isFormValidated){
      setLoadingState(true);
      setFeedbackMessage({text:'', success:true});

      let formData = new FormData();
      formData.append('tag',tag);
      formData.append('name',formState.name);
      formData.append('email',formState.email);
      formData.append('existing_customer',formState.isExistingCustomer);
      formData.append('receive_marketing_material',formState.isRequestingMore);
  
      const requestOptions = {
        method: 'POST',         
        body: formData,
      };

      fetch('/scripts/process_marketing_form.php', requestOptions)
          .then(response => response.json())
          .then(jsonResponse => {
            // console.log(jsonResponse);
            setTimeout(() => {
              setLoadingState(false);
              jsonResponse.status==='200' && setFormSuccess(true);
              setFeedbackMessage({
                text: jsonResponse.message, 
                success:jsonResponse.status==='200'? true : false
              })
            }, 500)
          })
          .catch(error => {
            console.error('Error processing the form', error);
            setTimeout(() => {
              setLoadingState(false);
              setFeedbackMessage({
                text:'Sorry your request could not be sent.', 
                success:false, 
                error:error.message
              })
            }, 500)
            
        });

      setFormState(emptyForm);

    }
    
  }; 

  return (
    <Container>
      {!formSuccess && 
        <>
          <TopRow>
            <CloseBtn onClick={()=>setModalOpen(false)}>
              {'Close'}
              <CloseCrossSVG 
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </CloseCrossSVG>
            </CloseBtn>
          </TopRow>

          <FormHeading>{`Download ${tag} Brochure`}</FormHeading>
        </>
      }

      <Form >
        {!formSuccess &&
          <InputFieldsWrapper>
            <TopPanel>         
              <div>
                <InputLabel htmlFor="name">
                  Name
                </InputLabel>
                <InputField 
                  name='name'
                  type="text"
                  id="name"
                  placeholder="your name"
                  value={formState.name}
                  onChange={handleFormChange}
                />

                <ValidationMessage  role="alert">
                  {errors.name || '\u00A0'}
                </ValidationMessage>
                
              </div>

              <div className="">
                <InputLabel htmlFor="email">
                  Email
                </InputLabel>
                <InputField 
                  name='email'
                  type="text"
                  id="email"
                  placeholder="your email"
                  onChange={handleFormChange}
                  value={formState.email}
                  />

                  <ValidationMessage role="alert">
                    {errors.email || '\u00A0'}
                  </ValidationMessage>
                  
              </div>            
            </TopPanel>

            <BottomPanel>
              <CheckboxesBlock>
                <CheckboxesHint                
                  style={ errors.hasMinOneTick || !isFormSubmited
                    ? {color:'#374151'}
                    : {color:'#F87171'}
                  }
                >{'Please select at least one of the following:'}</CheckboxesHint>
                <div>              
                  <CheckboxWrapper>
                    <Checkbox 
                      onChange={handleFormChange}
                      checked={formState.isExistingCustomer}
                      name='isExistingCustomer'
                      type="checkbox" 
                      />
                    <CheckboxText>I am an existing customer</CheckboxText>
                  </CheckboxWrapper>
                  <CheckboxWrapper>
                    <Checkbox 
                      onChange={handleFormChange}
                      checked={formState.isRequestingMore}
                      name='isRequestingMore'
                      type="checkbox" 
                      />
                    <CheckboxText>I would like to receive more Information</CheckboxText>
                  </CheckboxWrapper>
                </div>
                
              </CheckboxesBlock>              
            </BottomPanel>
          </InputFieldsWrapper>
        }

        <FeedbackMessageDiv
          style={feedbackMessage.success? {color:'#01C470'}:{color:'#f7596d'}}>
          { (feedbackMessage.text && !feedbackMessage.success) &&
              <svg 
                width="16" height="16" viewBox="0 0 16 16" fill="none" 
                xmlns="http://www.w3.org/2000/svg">
              <circle cx="8" cy="8" r="8" fill="#f7596d"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M7.25795 8.04051L4.08366 11.2193L4.79027 11.9269L7.96396 8.74872L11.203 11.9978L11.909 11.2896L8.67057 8.04112L11.9994 4.7076L11.2928 4L7.96456 7.33291L4.70601 4.06419L4 4.7724L7.25795 8.04051Z" fill="white"/>
            </svg>}
            
            {(feedbackMessage.text && feedbackMessage.success) && <svg 
                width="16" height="16" viewBox="0 0 16 16" fill="none" 
                xmlns="http://www.w3.org/2000/svg">
                <circle cx="8" cy="8" r="8" fill="#01C470"/>
                <path d="M13.2531 5.88908L6.88909 12.253" stroke="white"/>
                <path d="M3.35352 8.01041L7.59616 12.253" stroke="white"/>
              </svg>
          }
          <FeedbackMessage>{feedbackMessage.text}</FeedbackMessage>
        </FeedbackMessageDiv>

        <BottomRow>
          {!formSuccess
            ? <SendButton type='button' onClick={onSubmit}>
                {loadingState
                  ? 'Processing...'
                  : 'Submit'
                }
              </SendButton>

            : <CloseBtn onClick={()=>setModalOpen(false)}>
                {'Close'}
                <CloseCrossSVG 
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </CloseCrossSVG>
              </CloseBtn>
          }          
        </BottomRow>
        
      </Form>
    </Container>
  );
}
