import React, { useState } from "react";
import { motion } from "framer-motion";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import {css} from "styled-components/macro";


const Container = tw.div`relative pr-10 pl-10 mb-20 mx-auto`;
const Content = tw.div`max-w-screen-xl !mx-auto mt-20`;
const Heading = tw(SectionHeading)`text-3xl mb-10 !text-left`;
const HeadingWithControl = tw.div`pt-0 flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;

// const Card = styled.a(props => [
//   tw`max-h-full w-full flex-grow md:max-w-xs lg:max-w-sm relative
//   !my-3 pt-0 m-0 grid justify-items-stretch md:m-3 lg:m-4 `,
//   `
//   justify-content: center;
//   box-shadow: 0px 3px 3px #00000029;
//   display: inline-block;
//   @media screen and (max-width: 768px) {
//     .card {
//       width: 100%;
//     }
//   }
//   @media screen and (min-width: 769px) {
//     .card {
//       margin:50px 50px 50px 50px;
//     }
//   }
// `
// ]);

const Card = styled.a(props => [
  tw`max-h-full w-full flex-grow md:max-w-xs lg:max-w-sm relative
  !my-3 pt-0 m-0 grid justify-items-stretch md:m-3 lg:m-4 justify-center`,
  `
  box-shadow: 0px 3px 3px #00000029;
  display: inline-block;
  @media screen and (min-width: 769px) {
    .card {
    }
  }
  @media screen and (max-width: 768px) {
    .card {
    }
  }`
]);

const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center`
]);

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;

const TextInfo = tw.div`p-6`;
const TitleContainer = tw.div`flex flex-col text-center`;
const Title = tw.h5`text-2xl min-h-32 font-bold text-black !text-center`;
const Description = styled.p`
    ${tw`text-sm p-6 min-h-72 leading-normal -mt-5 text-gray-700`}
    &.productDescription {
        border-left: 1px solid black;
    }
    ul {
        ${tw`list-disc pl-4`}
    }
`;
const Link = tw.a``;
const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;
const CardButton = tw(PrimaryButtonBase)`!w-full items-center text-sm uppercase text-primary-500 bg-white border-solid border-4 border-primary-500`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6 uppercase`;
export default ({
  cards = [],
  linkText = "More Info",
  heading = "",
  showHeading = true,
  titleAsLink = false,
  externalUrl = null,
  
}) => {
  return (
    <Container>
      <Content>
        { showHeading && cards.length > 0 && <Heading>{heading}</Heading> }
          {cards.map((card, index) => (
            
            <Card key={index} 
            href={`/${card.productLink && card.productLink.length > 0 ? card.productLink[0].uri : card.uri}`} target={card.target}>

                { card.categoryImage &&
                    card.categoryImage.map((cardCatImage, index) => (
                        <CardImage imageSrc={cardCatImage.url} />
                ))}
              
              
                <TextInfo className="xs-version">
                    <TitleContainer>
                      {titleAsLink ? '' : <Title>{card.title}</Title>}
                    </TitleContainer>
                    { card.shortDescription &&
                        <Description dangerouslySetInnerHTML={{__html: card.shortDescription}}></Description>
                    }

                  {card.externalUrl ? (
                        <Link as="a" href={`${card.externalUrl}`}><CardButton>{linkText}</CardButton></Link>
                        ) : (titleAsLink ? <CardButton>{card.title}</CardButton> : <CardButton>{linkText}</CardButton>)
                    }

                </TextInfo>
            </Card>
            
          ))}
      </Content>
    </Container>
  );
};


//    href={`${(card.externalUrl ? card.externalUrl : (card.productLink && card.productLink.length > 0 ? card.productLink[0].url : card.uri))}`} target={card.target}>