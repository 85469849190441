import React from "react";
import tw from "twin.macro";
// import { ReadMoreToggler } from 'read-more-read-less-toggler';
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, SmallHeading as SmallHeadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import SolutionImageSrc from "images/solution-1.png";
import {customContentStyles} from "components/misc/Typography.js";
// import TriangleDecoration from "images/purple-triangle.svg";
// import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
// import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
// import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

const Container = tw.div`relative bg-gray-100 p-10 mb-20`;
const TwoColumn = tw.div`py-0 md:justify-between max-w-screen-xl mx-auto py-0 md:pb-0 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  // tw`md:w-6/12 mt-16 md:mt-0`,
  // props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);
const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
],
    `
    padding: 15px;
    `
);
const TextContent = styled.div`
  ${tw`lg:py-8 md:text-left`}
  ${customContentStyles}
`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-2xl sm:text-2xl lg:text-2xl text-left md:text-left leading-tight tracking-tight font-almostbold`;
// const ReadMoreToggler = tw.div`relative pr-10 pl-10 pt-0 pb-0`;
const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-12 mr-6 text-sm inline-block text-center`,
  props.buttonRounded && tw`rounded-full`
]);

export default ({
  heading = (
    <Heading>
     Terms and Conditions
    </Heading>
  ),
  primaryButtonTextLabel = "Purchase Equipment",
  primaryButtonTextPurchase = "Purchase Services",
  primaryButtonTextSupply = "Supply Equipment",
  primaryButtonTextMinor = "Supply Equipment & Install (Minor)",
  primaryButtonTextServices = "Supply Services",
  primaryButtonUrl = "#",
  primaryButtonUrlPurchaseServices = "#",
  primaryButtonUrlSupplyEquipment = "#",
  primaryButtonUrlSupplyEquipmentMinor = "#",
  primaryButtonUrlSupplyServices = "#",
  imageSrc = SolutionImageSrc,
  buttonRounded = false,
  imageRounded = false,
  imageBorder = false,
  imageShadow = false,
  showDecoratorBlob = true,
  textOnLeft = false,
  features = null,
  iconRoundedFull = true,
  iconFilled = true,
  iconContainerCss = null
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.


  return (
    <Container id="terms">
      <TwoColumn>
        <TextColumn>
          <TextContent>
            <Heading>{heading}</Heading>
             <PrimaryButton><a href={primaryButtonUrl[0]?.url} target="_blank">{primaryButtonTextLabel}</a></PrimaryButton>
             <PrimaryButton><a href={primaryButtonUrlPurchaseServices[0]?.url} target="_blank">{primaryButtonTextPurchase}</a></PrimaryButton>
             <PrimaryButton><a href={primaryButtonUrlSupplyEquipment[0]?.url} target="_blank">{primaryButtonTextSupply}</a></PrimaryButton>
             <PrimaryButton><a href={primaryButtonUrlSupplyEquipmentMinor[0]?.url} target="_blank">{primaryButtonTextMinor}</a></PrimaryButton>
             <PrimaryButton><a href={primaryButtonUrlSupplyServices[0]?.url} target="_blank">{primaryButtonTextServices}</a></PrimaryButton>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};