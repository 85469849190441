import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { NavHashLink as HashLink} from 'react-router-hash-link';
import Header from "../headers/light.js";
import NavMenu from "../headers/light.js";
import LogoLink from "../headers/light.js";
import {CardSliderMain as CardSliderBase} from "components/misc/Slider";
import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import DesignIllustration from "../../images/design-illustration.svg";
import {HeroHeading as HeroHeadingBase, HeroSmallHeading} from "../misc/Headings";
import {motion} from "framer-motion";


const Container = tw.div`relative w-full flex flex-row justify-center items-start py-0 pt-0 pb-0 sm:p-0 max-w-7xl ml-auto mr-auto min-h-160`;
const HeaderBackground = tw.div`absolute hidden block w-full py-0 pt-0 pb-0 sm:p-0 ml-auto mr-auto lg:bg-gray-350 xl:bg-gray-350 md:hidden xl:block`;

const Heading = tw(
    HeroSmallHeading
)`mt-4 max-w-none pr-8 lg:max-w-md md:max-w-5xl`;

const IconImage = tw.div`mt-16 mb-2 bg-transparent w-20`;
const Column = styled.div`
  ${tw`max-w-sm`}
`;


const SmallHeading = tw.h2`font-medium text-gray-600 leading-snug max-w-3xl`;
const Paragraph = tw.div`ml-0 mr-0 mt-4 lg:text-sm lg:text-base font-medium text-black max-w-5xl lg:mr-0 lg:pr-56`;
const Breadcrumb = tw.p`text-left my-5 lg:my-8 text-sm lg:text-base font-medium text-gray-600 max-w-md lg:mx-0`;
const BreadcrumbLeader = tw.span`text-left my-5 lg:text-left my-8 text-sm lg:text-base font-medium text-black max-w-lg mx-auto lg:mx-0`;
const Parent = tw.span``;
const HighlightedText = tw.span`text-primary-500 px-1`;
const P = tw.span`text-gray-600`;
const FlexContainer = tw.div`flex flex flex-wrap-reverse flex-col justify-center items-center w-full lg:p-0 flex-row md:flex-nowrap items-center max-w-screen-xl mx-auto py-20 sm:flex-nowrap p-0`;

const RightColumn = tw.div`flex-grow-4 w-full absolute mt-0 p-0 sm:mt-0 text-base lg:mt-0 flex flex-col right-0 sm:mt-0 z-20 top-0`;

const LeftColumn = tw.div`flex-grow w-full bg-white absolute sm:mt-112 sm:bg-transparent md:bg-transparent lg:bg-transparent xl:bg-transparent pl-20 md:mt-128 ipad:mt-64 pl-0 pr-0 flex-shrink-0 relative text-left z-10 lg:mr-10 mt-80 lg:mt-16 pl-10 pr-10 xl:mt-0`;

//md:mt-128
/*clip-path-hero*/
const HeroContainer = styled.div`
  ${tw`flex justify-center items-center absolute right-0`}
  img {
      ${tw`max-h-full object-fill object-top lg:max-w-4xl xl:max-w-5xl`}
    }
    ${props => props.slider && tw`h-full w-full`}
    ${props => props.reduceHeroSize && tw``}
`;


const CardSlider = styled(CardSliderBase)`
    ${tw`w-full h-full absolute right-0`}
    ${props => props.reduceHeroSize && `
    
        @media only screen and (min-width: 830px) {
            img {
                clip-path: polygon(0 0%,100% 0%,100% 100%,70% 100%);
                height: 88%;
                width: 64rem;
                object-fit: fill;
            }
        }
    `}
    ${props => !props.reduceHeroSize && `
        @media only screen and (min-width: 830px) {
            img {
                clip-path: polygon(0 0%,100% 0%,100% 100%, 70% 100%);
            }
        }
    `}
`;
//556
const Card = tw.div`h-full w-full flex flex-col focus:outline-none`;

export default ({
  heading = "Toshiba Heading",
  smallHeading = "Toshiba Subheading",
  homeTag= "Home",
  description=null,
  heroExtraDetails = null,
  imageSrc=DesignIllustration,
  imageCss='',
  iconSrc=null,
  iconAlt=null,
  parent=null,
  children=null,
  breadcrumbType=null,
  breadcrumbName = null,
  subPage = true,
  largeHeader = true,
  headerSmallBG = tw`min-h-112`,
  headerBG = tw`min-h-152`,
  reduceHeroSize=false,
}) => {
    // useState is used instead of useRef below because we want to re-render when sliderRef becomes availabe (not null)
    const [sliderRef, setSliderRef] = useState(null);
    const sliderSettings = {
        arrows: false,
        autoplay: true,
        autoplaySpeed: 6000,
        slidesToShow: 1,
        dots: false,
        draggable: true,
        infinite: true
    };
  return (
    <>
      <Header />
      <HeaderBackground  css={largeHeader ? headerBG : headerSmallBG}/>
      <Container>
      <FlexContainer class="flex-container">
  <LeftColumn class="twoDiv"><div class="top logo">{LogoLink}</div>
  
  
  <div class="content">    { iconSrc &&
                <IconImage>
                    {subPage || iconSrc.length > 0 ?
                        iconSrc.map((icon, index) => (
                           <img key={index}
                                            src={icon.url}
                                            alt={iconAlt}
                            />
                        ))
                        : <img
                            src={iconSrc}
                            alt={iconAlt}
                        />
                    }
                </IconImage>
            }
            <Heading>{heading}</Heading>
            <SmallHeading>{smallHeading}</SmallHeading>
            {description &&
                <Paragraph>
                    <div dangerouslySetInnerHTML={{__html: description}}/>
                </Paragraph>
            }
            {heroExtraDetails &&
                <Paragraph>
                    {heroExtraDetails}
                </Paragraph>
            }
            {breadcrumbName &&
                <Breadcrumb>
                    <BreadcrumbLeader>You are here: <HashLink to="/"><P>{homeTag} &gt; </P></HashLink></BreadcrumbLeader>
                    {breadcrumbType} &gt;
                    {parent !== null && parent !== "" &&
                        <Parent> <a href={`/${parent.uri}`}>{parent.title}</a> &gt; </Parent>
                    }
                    <HighlightedText>{breadcrumbName}</HighlightedText>
                </Breadcrumb>
            }</div></LeftColumn>
  
  <RightColumn class="oneDiv">
  <div class="nav top">{NavMenu}</div><div class="image">  
  
  <HeroContainer slider={Array.isArray(imageSrc)} reduceHeroSize={reduceHeroSize}>
                {Array.isArray(imageSrc) ? (
                <CardSlider ref={setSliderRef} {...sliderSettings} top={true} end={true} reduceHeroSize={reduceHeroSize}>
                    {(imageSrc || []).map((image, index) =>
                        <Card key={index} >
                          <img className="imageclass-banner"
                            css={imageCss}
                            src={image.url}
                            alt="Hero"
                          />
                            {/*<CardImage imageSrc={image.url} />*/}
                        </Card>
                    )}
                </CardSlider>
                )   : (
                    <CardSlider reduceHeroSize={reduceHeroSize}>
                        <img className="imageclass-banner"
                            css={imageCss}
                            src={subPage ? imageSrc[0]?.url : imageSrc}
                            alt="Hero"
                        />
                    </CardSlider>
                        ) }


            </HeroContainer></div></RightColumn>
</FlexContainer>



     
      </Container>
    </>
  );
};


//#a0a0a5