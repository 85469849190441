import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import {SectionHeading, SectionHeading as HeadingTitle, SmallHeading as SmallHeadingBase} from "../misc/Headings.js";
import {PrimaryButton as PrimaryButtonBase} from "../misc/Buttons";
import { customContentStyles } from "components/misc/Typography.js"
const Container = tw.div`relative py-0 pt-0 pb-0 sm:p-0`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-0 `;

const HeadingInfoContainer = tw.div`flex flex-col`;
const SmallHeading = tw(SmallHeadingBase)`text-center text-lg md:text-center sm:text-center lg:text-left`;
const Heading = tw(
    SectionHeading
)`mt-4 pb-10 font-black text-center text-2xl sm:text-2xl text-center lg:text-2xl lg:text-left pl-0 md:text-center leading-tight`;
// const ReadMoreToggler = tw.div`relative pr-10 pl-10 pt-0 pb-0`;
const Description = styled.p`
    ${tw`break-normal mt-3 pr-8 text-left md:text-left text-sm md:text-base lg:text-sm font-medium leading-normal text-gray-700`}
    ${customContentStyles}

`;


const Content = tw.div`p-0 pl-20 block md:p-1 lg:block `;

const Card = styled.div(props => [
  tw`mt-2 pb-2 p-20 pl-0 w-full mx-auto md:flex justify-center items-center lg:p-0 sm:p-20 md:p-0 lg:p-3`
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded w-full flex-shrink-0 h-80 md:h-48 bg-cover bg-center sm:w-full md:w-1/2 lg:w-1/2`
]);
const Details = tw.div`bg-transparent mt-4 w-full bg-white text-left pt-10 ml-0 pl-6 md:mt-0 md:max-w-md sm:ml-0 md:ml-0 lg:ml-0 sm:w-full md:w-1/2 lg:w-1/2 xl:bg-gray-100 lg:bg-gray-100 md:bg-gray-100`;
// const Subtitle = tw.div`font-bold tracking-wide text-secondary-100 text-left`;
const Title = tw.h4`text-xl font-bold text-gray-900 mr-10`;
const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-12 text-sm float-none inline-block mx-auto md:mx-0 text-center w-48 lg:mx-0 float-right`,
  props.buttonRounded && tw`rounded-full`
]);

export default ({
  heading = "",
  smallHeading = "",
  linkText = "Read More",
  information = null
}) => {

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <SmallHeading>{smallHeading}</SmallHeading>
          <Heading><span>{heading}</span></Heading>
        </HeadingInfoContainer>

        <Content>
          {information.map((infoRow, i) => (
            <Card key={i}>
              <Image imageSrc={infoRow.image[0].url} />
              <Details>
                <Title>{infoRow.displaytitle}</Title>
                <Description dangerouslySetInnerHTML={{__html: infoRow.description}}/>
                {infoRow.linktocontent.length > 0  ? (
                      infoRow.linktocontent.map((infoRowLink, i) => (
                          <PrimaryButton as="a" key={i} href={`/${infoRowLink.uri}`}>{linkText}</PrimaryButton>
                      ))
                ) : (infoRow.externalLink &&
                      <PrimaryButton as="a" key={999} href={`${infoRow.externalLink}`}>{linkText}</PrimaryButton>
                  )}

              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
    </Container>
  );
};
