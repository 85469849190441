import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useLocation } from 'react-router-dom';
import GraphQlHandler from "api/craft/GraphQlHandler";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithImage.js";
import Solutions from "components/features/TwoColSolutions.js";
import SubCategories from "components/features/FourColSliderSubCategory.js";
import Products from "components/features/ThreeColCategory.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import Downloads from "components/features/MultiColumnProductDownloads.js";

import defaultHeroImage from "images/Toshiba Blue Texture.jpg";
import defaultHeroIcon from "images/map.svg";

import styled from "styled-components";
import {
    HeroHeading as HeroHeadingBase,
    HeroSmallHeading as HeroSmallHeadingBase,
    SectionHeading
} from "./components/misc/Headings";
import { HR as HRBase } from "./components/misc/Layouts";
import CommonContent from "./commonContent";

const HR = tw(HRBase)``;



const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-2xl sm:text-2xl lg:text-2xl text-center md:text-left leading-tight tracking-tight font-almostbold`;
// const ReadMoreToggler = tw.div`relative pr-10 pl-10 pt-0 pb-0`;
const Description = tw`max-w-lg`;
const HeroHeading = tw(
    HeroHeadingBase
)`pt-4 text-3xl md:text-5xl leading-extratight max-w-md
`;
const HeroSmallHeading = tw(
    HeroSmallHeadingBase
)`pt-4 text-3xl md:text-5xl leading-extratight max-w-md
`;
const categoryheading = "Categories";
const heading = "Products";
export default () => {
  const [mainEntryData, setMainEntryData] = useState([]);
  const [entries, setCategoryEntry] = useState([]);
  const [productAsCats, setProductAsCats] = useState([]);
  const [productEntries, setProductEntries] = useState([]);
  const [cats, setCats] = useState("");
  const [relatedToQuery, setRelatedToQuery] = useState("");
  const SmallHeading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw``;
  const imageCss = tw``;
  const location = useLocation();
  const subPage = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1) !== "" ?
      true : false
  ;

  const slug = subPage ?
      window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1) : "*"
  ;
   const level = subPage ? "" : ", level: 1"; // If its a subpage we want to get level 2, but if top level only get 1

  useEffect(() => {
    GraphQlHandler.query(`
        query MyQuery {
          mainEntryData: productsLPEntries {
            ... on productsLP_productsLP_Entry {
              id
              mainDescription
              subHeading
              heroIcon {
                ... on icons_Asset {
                  id
                  url
                }
              }
              heading
              heroImage {
                ... on headerImages_Asset {
                  id
                  url
                }
              }
            }
          }
          entries(section: "categories", slug: "${slug}" ${level}) {
            ... on categories_category_Entry {
              id
              slug
              uri
              heroImage {
                url
                id
              }
              productCategoryImage {
                url
                id
              }
              subHeading
              heading
              children {
                id
                title
                slug
                ... on categories_category_Entry {
                  id
                  uri
                  heading
                  productCategoryImage {
                    url
                    id
                  }
                  
                }
              }
              heroIcon {
                id
                url
              }
              mainDescription
              parent {
                id
                title
                uri
              }
            }
          }
            productAsCats : productEntries(treatAsCategory: "Yes") {
            ... on product_products_Entry {
              id
              uri
              heading : title
              slug
              
              productCategoryImage : productMainImage {
                ... on productImages_Asset {
                  id
                  url
                }
              }
            }
          }
        }

    `)
    .then((data) => {
        setMainEntryData(data.mainEntryData);
        setCategoryEntry(data.entries);
        setProductAsCats(data.productAsCats);
        var catsList = ""
        {(data.entries || []).map((entry) => {
            catsList = catsList == "" ? "\""+entry.slug+"\"" : catsList+",\""+entry.slug+"\""
            {(entry.children || []).map((children) => {
                catsList = catsList == "" ? "\""+children.slug+"\"" : catsList+",\""+children.slug+"\""
            } )}
        } )}
        setCats(catsList);
        // Top level we want to display all products, but otherwise we want to filter to the specific cat
        {subPage &&
            setRelatedToQuery("(relatedToEntries: {slug: [" + catsList + "]})");
        }
    });
  }, [location])
 useEffect(() => {
    GraphQlHandler.query(`
            query MyQuery {
              
              productEntries ${relatedToQuery}{
                ... on product_products_Entry {
                  id
                  title
                  slug
                  externalUrl
                  uri
                  shortDescription
                  productCategory {
                    id
                    slug
                  }
                  categoryImage : productMainImage {
                    id
                    url
                    ... on productImages_Asset {
                      id
                      url
                    }
                  }
                  heroIcon {
                    id
                    url
                  }
                }
              }
            }
    
        `)
        .then((data) => {
            setProductEntries(data.productEntries);
        });
}, [relatedToQuery, location]);

  return (

    <AnimationRevealPage>

        {
            (entries || []).map((categoryEntry, index) =>
                index == 0 &&
                    <>

                            <Hero
                                  key={index}
                                  heading={

                                      <HeroSmallHeading>{subPage ? categoryEntry.heading : "Products"}</HeroSmallHeading>}

                                  smallHeading={<></>}
                                  breadcrumbType={<>Products</>}
                                  breadcrumbName={subPage ? categoryEntry.heading : "All"}
                                  parent={categoryEntry.parent}
                                  children={categoryEntry.children}

                                  description={
                                    subPage ? categoryEntry.mainDescription : "View our range of products below"}
                                  iconSrc={subPage ? categoryEntry.heroIcon : mainEntryData[0].heroIcon}
                                  iconAlt={<>{categoryEntry.heading}</>}
                                  imageSrc={subPage ? categoryEntry.heroImage : mainEntryData[0].heroImage}
                                  imageCss={imageCss}
                                  subPage={subPage}

                            />
                          { categoryEntry.children.length > 0 &&
                                <SubCategories
                                    heading={heading}
                                    key={index}
                                    subCategories={subPage ? categoryEntry.children : entries.concat(productAsCats)}
                                    subPage={subPage}
                                />
                          }

                    </>

            )
        }

        {
          entries.length && 
          window.location.pathname == '/category/motors/high-voltage-motors' && 
          <Downloads
            key={`${entries[0].heading}_downloads`}
            downloads={[{
              buttonToggle: "true",
              description:"Brochure",
              downloadType:"brochure",
              externalUrl: null,
              file:[]
            }]}
            slug={entries[0].slug}
            tag={entries[0].heading}                          
          />                    
        }
    
        <Products
          heading={
           <Heading>
               {"Products"}
           </Heading>
       }
            cards={productEntries}
            // externallink={productEntries.externalUrl}
        />

        <Solutions

        />

        <CommonContent
            showCompanies={false}
            showProducts={false}
        />

      <Footer />
    </AnimationRevealPage>
  );
}
