import tw from "twin.macro";
import Slider from "react-slick";
import styled from "styled-components";
import {PrimaryButton as PrimaryButtonBase} from "./Buttons";

export const HeadingWithControl = tw.div`flex sm:flex-row flex-col sm:items-stretch  justify-between mt-5`;
export const HeadingWithControlCentred = tw.div`flex flex-col items-center sm:items-stretch  justify-between mt-5`;

export const Controls = tw.div`flex items-center justify-center items-center mt-10`;
export const ControlsTop = styled(Controls) `
  ${tw `mt-0!`}
`;
export const ControlsTopRight = styled(Controls) `
  ${tw `absolute right-0 top-0`}
`;
export const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-40! sm:mt-0 first:ml-0 ml-6 rounded-full p-2 text-primary-500 bg-white hocus:bg-secondary-500`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
export const ControlButtonCentred = styled(ControlButton)`
  ${tw`mt-0! flex flex-col items-center justify-center items-center`}
`;
export const ControlButtonTop = styled(ControlButton)`
  ${tw`mt-0! flex flex-col items-center justify-center items-center`}
`;
export const PrevButton = styled(ControlButton)`
    ${tw`relative z-10 float-left hidden md:block lg:block xl:block`}
`;
export const PrevButtonCentred = styled(ControlButtonCentred)`
    ${tw`relative z-10 float-left`}
`;
export const PrevButtonTop = styled(ControlButtonTop) `
  ${tw `relative hidden sm:block md:block lg:block z-10 float-left -mt-10!`}
`
export const NextButton = styled(ControlButton)`
    ${tw`relative z-10 float-right hidden md:block lg:block xl:block`}
`;
export const NextButtonCentred = styled(ControlButtonCentred)`
    ${tw`relative z-10 float-right`}
`;
export const NextButtonTop = styled(ControlButtonTop) `
  ${tw `relative hidden sm:block md:block lg:block z-10 float-right -mt-10!`}
`
export const CardSlider = styled(Slider)`
  
  ${tw`mt-10`}
  ${props => props.top && tw`mt-0`}
  .slick-track { 
    ${tw`flex`}
  }
  imageSrc: {
    width: 50rem;
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
  .slick-dots {
    ${tw`mt-10 mx-10 flex!`}
    color: grey;
    li {
      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      button {
        height: 8px;
        width: 100%;
        content: "";
        color: white;
        :active, :focus {
          outline: 0;
          border: none;
          -moz-outline-style: none;
        }
      }
      height: 8px;
      width: 100%;
      border: 2px solid grey;
      background-color: grey;
      display: inline-block;
      
      &.slick-active{
        border: 2px solid red;
        background-color: red;
        color: red;
      }
    }
  }
`;
export const CardSliderMain = styled(Slider)`
  ${tw`mt-16`}
  ${props => props.top && tw`mt-0`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
    ${props => props.end && tw`justify-end`}
  }
`;