import GraphQlHandler from "../../api/craft/GraphQlHandler";

export const ZohoForm = (formImport, divElement) => {
    (function () {
        console.log("IN FUNCTION");
        try{
            console.log("TRY STARTED");
            var f = document.createElement("iframe");
            console.log("CREATE ELEMENT");
            f.src = formImport;
            console.log("GET FORM");
            f.style.border="none";
            f.style.height="481px";
            f.style.width="100%";
            f.style.transition="all 0.5s ease";
            console.log("BEFORE ADD ELEMENT");
            var d = document.getElementById(divElement);
            d.appendChild(f);
            console.log("ADDED ELEMENT");
            window.addEventListener('message', function (event){
                console.log("IN EVENT LISTENER");
                var evntData = event.data;
                if( evntData && evntData.constructor == String ){
                    var zf_ifrm_data = evntData.split("|");
                    if ( zf_ifrm_data.length == 2 ) {
                        var zf_perma = zf_ifrm_data[0];
                        var zf_ifrm_ht_nw = ( parseInt(zf_ifrm_data[1], 10) + 15 ) + "px";
                        var iframe = document.getElementById(divElement).getElementsByTagName("iframe")[0];
                        if ( (iframe.src).indexOf('formperma') > 0 && (iframe.src).indexOf(zf_perma) > 0 ) {
                            var prevIframeHeight = iframe.style.height;
                            if ( prevIframeHeight != zf_ifrm_ht_nw ) {
                                iframe.style.height = zf_ifrm_ht_nw;
                            }
                        }
                    }
                }
            }, false);
        }catch(e){}
    })();

}

export default ZohoForm;