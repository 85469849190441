import React from "react";
import { NavHashLink as Link} from 'react-router-hash-link';
import tw from "twin.macro";
// import { ReadMoreToggler } from 'read-more-read-less-toggler';
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, SmallHeading as SmallHeadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import SolutionImageSrc from "images/Soltuions_image.jpg";
import TriangleDecoration from "images/purple-triangle.svg";
import {customContentStyles} from "components/misc/Typography.js";
// import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
// import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
// import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

const Container = tw.div`relative pr-10 pl-10 sm:pt-0 lg:pt-0 md:pt-0`;
const TwoColumn = tw.div`flex flex-col py-0 xl:flex-row justify-between max-w-screen-xl mx-auto py-0 md:pt-2 pb-0 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`xl:w-6/12 flex-shrink-0 relative md:pb-10`;
const TextColumn = styled(Column)(props => [
  tw`xl:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`xl:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
],
    `
    padding: 15px;
    @media screen and (max-width: 1200px) {
      padding: 0px;
    }
    `
);

const DecoratorBlob = styled.div(props => [
  `
  background-image: url("${TriangleDecoration}");
  left: -22px;
  top: -22px;
  width: 124px;
  height: 124px;
  `,
  tw`absolute fill-current text-primary-500 z-10 hidden lg:block xl:block md:block`,
]);


const TextContent = tw.div`lg:py-8 text-left md:text-left`;
const SmallHeading = tw(SmallHeadingBase)`text-left text-2xl md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-2xl sm:text-2xl lg:text-2xl text-left md:text-left leading-tight tracking-tight font-almostbold`;
// const ReadMoreToggler = tw.div`relative pr-10 pl-10 pt-0 pb-0`;
const Description = styled.p`
  ${tw`mt-8 text-left md:text-left text-sm md:text-base lg:text-lg font-medium leading-normal text-gray-700`}
  ${customContentStyles}
`;
const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-12 w-full text-sm inline-block mx-auto md:mx-0 md:w-2/6 text-center lg:mx-0 sm:w-2/6`,
  props.buttonRounded && tw`rounded-full`
]);


/*clip-path-hero*/
const IllustrationContainer = styled.div`
  ${tw`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none z-50`}
  img {
      clip-path: polygon(0 0%,100% 0%,100% 100%,70% 100%);
    }
`;

export default ({
  smallHeading = "Solutions",
  heading = (
    <Heading>
      Integrated Delivery and Support
    </Heading>
  ),
  description = "<p>Global expertise, local know how.</p> <p>Innovative thinking is in our DNA. Since our inception, our engineers and sales teams have been busy proactively seeking to understand our customers needs and develop tailored technology solutions fit for Australia&rsquo;s unique environment.</p><br/> <p>This customer-first approach is the backbone of Toshiba International Corporation&rsquo;s culture. It is what drives us forward, enabling us to build a reputation of high quality and exceptional reliability.</p><br/> <p>Toshiba will continue improving and enhancing the performance of our range. We are preparing the next generation of equipment and associated service capabilities, to support our portfolio of products, systems, and solutions.</p> <br/><p>Our commitment is to develop and innovate, to support our clients by delivering solutions that will continue to power Australian industry.</p>",
  primaryButtonText = "Read More",
  primaryButtonUrl = "#",
  imageSrc = SolutionImageSrc,
  buttonRounded = false,
  imageRounded = false,
  imageBorder = false,
  imageShadow = false,
  showDecoratorBlob = true,
  textOnLeft = false,
  features = null,
  iconRoundedFull = true,
  iconFilled = true,
  iconContainerCss = null
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.


  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          {/* img-overlay-text */}
        <div className="imageBanner">
          <Image src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
          {showDecoratorBlob && <DecoratorBlob/>}
          {/* <div class="overlay">
          &lt; Title of the installation goes here 	&gt;
        </div> */}
        </div>
        </ImageColumn>
        {/* blob */}
        {/* <svg id="10015.io" viewBox="0 0 480 480" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
	          <path fill="#474bff" d="M299,296Q176,352,154.5,203Q133,54,277.5,147Q422,240,299,296Z" />
            </svg> */}
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <SmallHeading>{smallHeading}</SmallHeading>
            <Heading>{heading}</Heading>
            {/* <ReadMoreToggler topGradient="black" bottomGradient="black" className="testinglen"> */}
            <Description dangerouslySetInnerHTML={{__html:description}}></Description>
            {/* </ReadMoreToggler> */}
            <Link to="/company#solutions"><PrimaryButton buttonRounded={buttonRounded} as="">
              {primaryButtonText}
            </PrimaryButton>
            </Link>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};