import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithImage.js";
import Content from "components/cards/TabCardGridCommonContent.js";
import Footer from "components/footers/FiveColumnWithBackground.js";

import CommonContent from "./commonContent.js";
import GraphQlHandler from "api/craft/GraphQlHandler";
import { HR as HRBase } from "./components/misc/Layouts";
import {
    HeroHeading as HeroHeadingBase,
    HeroSmallHeading as HeroSmallHeadingBase,
    SectionHeading
} from "./components/misc/Headings";
import PhotoGallery from "./components/features/FourColSliderProductsGallery";
import {useLocation} from "react-router-dom";
import { customContentStyles } from "components/misc/Typography.js"
import styled from "styled-components";
const HR = tw(HRBase)``;
const Heading = tw(SectionHeading)`text-3xl`;
const HeroHeading = tw(
    HeroHeadingBase
)`pt-4 text-3xl md:text-5xl leading-extratight max-w-md
`;
const HeroSmallHeading = tw(
    HeroSmallHeadingBase
)`pt-4 text-3xl md:text-5xl leading-extratight max-w-md
`;
const ContentContainer = styled.div`
    ${tw`pt-5`}
    ${customContentStyles}

`;
export default () => {
  const [singlePageEntry, setSinglePageEntry] = useState([]);
  const SmallHeading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw``;
  const location = useLocation();
  const slug = window.location.pathname.replace(/\/$/, "").substring(window.location.pathname.replace(/\/$/, "").lastIndexOf('/') + 1);

    useEffect(() => {
      GraphQlHandler.query(`
          query MyQuery {
              entry(slug: "${slug}") {
                title
                slug
                ... on contentPages_generalContent_Entry {
                  id
                  keyBenefits {
                    ... on keyBenefits_BlockType {
                      id
                      keyBenefitContent
                    }
                  }
                  subHeading
                  title
                  heroIcon {
                    slug
                    title
                    ... on icons_Asset {
                      id
                      url
                    }
                  }
                  infoRows {
                    ... on infoRows_BlockType {
                      id
                      description
                    }
                  }
                  heroImage {
                    ... on headerImages_Asset {
                      id
                      url
                    }
                  }
                  contentTabs {
                    ... on contentTabs_BlockType {
                      id
                      video {
                        id
                        url
                        mimeType
                        size
                      }
                      galleryimages {
                        url
                      }
                      contentTab
                      titleTab
                    }
                  }
                  galleryImages {
                    ... on productImages_Asset {
                      id
                      url
                      title
                    }
                  }
                }
              }
           }

      `)
        .then((data) => {
            setSinglePageEntry(data.entry);
        });
    }, [location])

  return (

    <AnimationRevealPage>

            <Hero
                  heading={<HeroSmallHeading>{singlePageEntry.title}</HeroSmallHeading>}
                  smallHeading={<></>}
                  breadcrumbType={<>Pages</>}
                  breadcrumbName={singlePageEntry.title}
                  children={<></>}
                  iconSrc={singlePageEntry.heroIcon}
                  iconAlt={singlePageEntry.title}
                  imageSrc={singlePageEntry.heroImage}
                  imageCss={imageCss}

            />
            {singlePageEntry.contentTabs &&
                <ContentContainer>
                    <Content
                        tabs={singlePageEntry.contentTabs}
                    />
                </ContentContainer>
            }

            {singlePageEntry.galleryImages && singlePageEntry.galleryImages.length > 0  &&
                <>
                    <HR/>
                    <PhotoGallery
                        galleryImages={singlePageEntry.galleryImages}

                    />

                </>
            }

        <HR/>

        <CommonContent/>


        <Footer />
    </AnimationRevealPage>
  );
}
