import React from "react";
import tw from "twin.macro";
// import { ReadMoreToggler } from 'read-more-read-less-toggler';
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, SmallHeading as SmallHeadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Description } from "components/misc/Typography.js";
import SolutionImageSrc from "images/solution-1.png";
// import TriangleDecoration from "images/purple-triangle.svg";
// import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
// import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
// import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

const Container = tw.div`relative mt-0 p-0`;
const TwoColumn = tw.div`py-0 pt-0 pl-8 pr-8 mx-auto text-center md:justify-between max-w-screen-xl mx-auto py-0 pb-0 items-center mx-auto lg:pl-4 lg:pl-4`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw``,
  // props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
],
    `
    padding: 15px;
    `
);

const TextContent = tw.div`text-center md:text-left`;
const SmallHeading = tw(SmallHeadingBase)`text-center text-lg pt-6 md:text-left`;
// const Subdescription = tw.p`mt-8 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-normal text-gray-700`;
const Heading = tw(
  SectionHeading
)`font-black text-left text-2xl sm:text-2xl lg:text-3xl text-center md:text-left leading-tight`;


const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-12 text-sm inline-block mx-auto md:mx-0 text-center lg:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);


export default ({
  smallHeading = "",
  smallHeadingone = "Phone +61 1800 845 144",
  heading = (
    <Heading>
     Terms and Conditions
    </Heading>
  ),
  description = "",
  coltwodescription = "This phone line offers the option of confidential and/or anonymous reporting of serious unacceptable conduct by TIC without the fear of victimisation, adverse treatment or retribution. TIC is committed to effectively and dealing fairly with the reporting of compliance/misconduct complaints and will ensure that suppliers, resellers or distributors genuinely reporting misconduct will not be dealt with disadvantageously.",
  primaryButtonText = " View Terms and Conditions",
  primaryButtonUrl = "#",
  imageSrc = SolutionImageSrc,
  buttonRounded = false,
  imageRounded = false,
  imageBorder = false,
  imageShadow = false,
  showDecoratorBlob = true,
  textOnLeft = false,
  features = null,
  iconRoundedFull = true,
  iconFilled = true,
  iconContainerCss = null
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.


  return (
    <Container>
      <TwoColumn>
        <TextColumn>
          <TextContent>
          {/* <SmallHeading>{smallHeading}</SmallHeading> */}
            { description && 
                <Description dangerouslySetInnerHTML={{__html: description}} />
            }
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
