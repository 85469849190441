import React, { useState } from "react";
import { motion } from "framer-motion";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import {
    ControlsTop as Controls,
    PrevButtonTop as PrevButton,
    NextButtonTop as NextButton,
    CardSlider,
    HeadingWithControl
} from "components/misc/Slider";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import ProductImage1 from "../../images/product-image-1.png";
import ProductImage2 from "../../images/product-image-2.png";
import ProductImage3 from "../../images/product-image-3.png";
import {css} from "styled-components/macro";
import { trueGray } from "tailwindcss/colors";

const Container = tw.div`relative pr-10 pl-10 py-0 mx-auto`;
const Content = tw.div`max-w-screen-xl mx-auto py-10 lg:py-16`;

const Ruler = tw.div`pl-4 border-l border-black`;
const Heading = tw(
  SectionHeading
)`mt-4 text-red-1000 !text-left text-2xl sm:text-2xl lg:text-2xl text-left md:text-left leading-tight tracking-tight font-almostbold`;
// const ReadMoreToggler = tw.div`relative pr-10 pl-10 pt-0 pb-0`;

const Card = styled.a(props => [
  tw``,
  `padding: 10px;`,
]);

const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-80! h-56 sm:h-64 bg-cover bg-center`
]);

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;


const TextInfo = tw.div`p-6 pb-20`;
const TitleContainer = tw.div`flex text-left flex-col sm:flex-row sm:justify-between`;
const Title = tw.h5`text-2xl font-bold text-black !text-left`;

const Description = styled.p`
    ${tw`text-sm leading-normal mt-2 sm:mt-4 text-gray-700 pl-5`}
    &.productDescription {
        border-left: 1px solid black;
    }
    ul {
        ${tw`list-disc pl-4`}
    }
`;


const CardButton = tw(PrimaryButtonBase)`mt-20 mb-4 text-sm uppercase absolute bottom-0`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6 uppercase`;

export default ({
    cards = [],
    linkText = "View More",
    heading = "Our Products"
  }) => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    infinite: true,
    dots: true,
    draggable: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };


  return (
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading>{heading}</Heading>
          <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}><ChevronLeftIcon/></PrevButton>
            <NextButton onClick={sliderRef?.slickNext}><ChevronRightIcon/></NextButton>
          </Controls>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {cards.map((card, index) => (
            <Card key={index} href={`/${card.uri}`} >
                {card.productCategoryImage &&
                    card.productCategoryImage.map((cardImage, index) => (
                        <CardImage imageSrc={cardImage.url}/>
                    ))
                }
              <TextInfo className="xs-version">
                <TitleContainer>
                  <Title>{card.title}</Title>

                </TitleContainer>

                <Description className="productDescription"
                     dangerouslySetInnerHTML={{ __html: card.shortDescription }}>
                </Description>
                <CardButton>{linkText}</CardButton>
              </TextInfo>
            </Card>
          ))}
        </CardSlider>
      </Content>
    </Container>
  );
};
