import React, {useState, Component, useRef} from "react";
import axios from 'axios';
import tw from "twin.macro";
import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, SmallHeading as SmallHeadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/Toshiba Header Images - Careers.png";
const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`!inline-block p-6 lg:py-8 text-left md:text-left`;

const SmallHeading = tw(SmallHeadingBase)`text-left md:text-left`;
const Heading = tw(SectionHeading)`mt-4 text-left text-3xl sm:text-4xl lg:text-5xl text-left md:text-left leading-tight`;
const Description = tw.p`mt-4 text-left md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 pl-4 rounded-sm text-black first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`;
const Formcontainer = tw.div`bg-gray-200 mt-8 p-6 lg:p-20 rounded-3xl max-w-md !mx-auto lg:max-w-xl`;
const Instructions = tw.div`text-black mt-6`;
const Select = tw.select`p-2 w-full`;

const Locations = tw.div`text-black first:mt-0 py-3 focus:outline-none font-medium `;
const Departments = tw.div`first:mt-0  py-3 focus:outline-none font-medium `;
const Department = tw.div`p-2 text-black inline-block`;
const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;
const CV = tw.input `!p-2 !w-full text-black first:mt-0 py-3 focus:outline-none font-medium`;
const ResultArea = tw.div`mt-8 font-bold text-2xl `;
const  submitButtonText = "Send";
const textOnLeft = true;
const smallHeading = "Contact Us";
const heading = "Talent Network";
const description = "";
const locationName = "location";
//const fileInput = useRef(null);
export default class TalentNetworkForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            fullname: '',
            location: '',
            departments: '',
            cv: '',
            information: '',
            result: '',
            recaptchaValue: '',
            verified: false,
            isSubmitting: false,
            isFormVisible: true
        };
    }

    render(){
        const handleCheckboxChange = (event) => {
            const { checked, value } = event.target;
            this.setState(prevState => {
                let departments = [...prevState.departments];
                if (checked) {
                    departments.length > 0 ? departments.push(" "+value) : departments.push(value);
                } else {
                    departments = departments.filter(department => department !== value);
                }
                return { departments };
            });
        };
        const setFileTarget = (event) => {

            this.setState({ cvname: event.target.files[0].name });
            this.setState({ cvfilename: event.target.files[0] });

        }
        const showForm = (event) => {
            this.setState({ isFormVisible: true });
            this.setState({ result: "" });
            this.setState({isSubmitting: false })
        };

        const handleSubmit = (event) => {
            this.setState({
                    isSubmitting: true
                },
                () => {
                event.preventDefault();
                if (!this.state.verified) {
                    console.log('Captcha is required!');
                    return {success: false, message: 'Captcha is required.'};
                }
                const file = event.target.cv.files[0];
                const formData = new FormData();
                formData.append("tag", "talent-network");
                formData.append("file", file);
                formData.append("email", this.state.email);
                formData.append("fullname", this.state.fullname);
                formData.append("location", this.state.location);
                formData.append("departments", this.state.departments);
                formData.append("information", this.state.information);
                formData.append("recaptchaValue", this.state.recaptchaValue);
                var xmlhttp = new XMLHttpRequest();
                xmlhttp.onreadystatechange = () => {
                    if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
                        const responseText = xmlhttp.responseText;
                        this.setState({ isFormVisible: false });
                        this.setState({ result: responseText });
                    }
                    else {
                        this.setState({
                            isSubmitting: false
                        })
                    }
                };
                xmlhttp.open("POST", "/scripts/process_email.php", true);
                xmlhttp.send(formData);
            })
        }

        const verifyCaptcha = (event) => {
            this.setState({
                ReCAPTCHAValue: event
            })
            event !== null ?
            this.setState({
                verified: true
            }) : 
            this.setState({
                verified: false
            })
        }

        return (
            <Container>
                <TwoColumn>
                    <ImageColumn>
                        <Image imageSrc={EmailIllustrationSrc}/>
                    </ImageColumn>
                    <TextColumn textOnLeft={textOnLeft}>
                        <TextContent>
                            <Formcontainer>
                                {smallHeading && <SmallHeading>{smallHeading}</SmallHeading>}
                                <Heading>{heading}</Heading>
                                {description &&
                                    <Description dangerouslySetInnerHTML={{__html: description}}></Description>}
                                {this.state.isFormVisible && (
                                    <Form onSubmit={handleSubmit}>
                                        <Input type="email" name="email" onChange={(event) => this.setState({ email: event.target.value })} value={this.state.email} placeholder="Your Email Address" required/>
                                        <Input type="text" name="name" onChange={(event) => this.setState({ fullname: event.target.value })} value={this.state.fullname}placeholder="Full Name" required/>
                                        <Instructions>Please select your preferred location: </Instructions>
                                        <Locations>
                                            <Select name={locationName} required id={locationName} onChange={(event) => this.setState({ location: event.target.options[event.target.selectedIndex].value })} value={this.state.location}>
                                                {this.props.locations.map((card, index) => (
                                                    <option value={card.title}>{card.title}</option>
                                                ))}
                                            </Select>
                                        </Locations>
                                        <Instructions>Please select your preferred departments. Multiple selections are
                                            allowed: </Instructions>
                                        <Departments>
                                            {this.props.departments.map((card, index) => (
                                                <Department>
                                                    <Input type="checkbox" id={card.id} name={card.id} value={card.title} onChange={handleCheckboxChange}/>
                                                    <label htmlFor={card.id}> {card.title}</label>
                                                </Department>
                                            ))}
                                        </Departments>
                                        <Instructions>Please upload your CV: </Instructions>
                                        <CV type="file"
                                            id="cv"
                                            name="cv"
                                            onChange={setFileTarget}
                                            required
                                            />
                                        {/*<button onClick={e => fileInput.current && fileInput.current.click()} className="btn btn-primary"/>
    */}
                                        <Textarea name="message" onChange={(event) => this.setState({ information: event.target.value })} value={this.state.information}
                                                  placeholder="Additional information to support your application"/>
                                                  <br/>
                                                     <ReCAPTCHA
                            sitekey="6Lckuj0kAAAAANgDTtJRlCcFOhVU1qhJfZoTkOT6"
                            onChange={verifyCaptcha}

                        />
                                        <SubmitButton type="submit" disabled={this.state.isSubmitting}>{submitButtonText}</SubmitButton>

                                    </Form>
                                )}

                                {!this.state.isFormVisible && (
                                    <Form onSubmit={showForm}>
                                        <SubmitButton type="submit" >Send another email</SubmitButton>
                                    </Form>
                                )}
                                <ResultArea>{this.state.result}</ResultArea>
                            </Formcontainer>
                        </TextContent>
                    </TextColumn>
                </TwoColumn>
            </Container>
        );
    }
}
