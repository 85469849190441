/* A simple iframe wrapper that makes 16:9 responsive embed */
import React from 'react';

export default ({ url, background="black", className="video", videoWidth="75%" }) => {
  return (
    <div
      className={className}
      style={{
        position: "relative",
        background: background,
        /*paddingBottom: "56.25%" /!* 16:9 *!/,*/
        paddingTop: 25,
        height: 0
      }}
    >

        <video
            width={videoWidth}
            height={videoWidth}
             controls>
            <source src={url} type="video/mp4"/>
        </video>
    </div>
  );
};
