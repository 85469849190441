import React from "react";
import tw from "twin.macro";
// import { ReadMoreToggler } from 'read-more-read-less-toggler';
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, SmallHeading as SmallHeadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import SolutionImageSrc from "images/solution-1.png";
import TriangleDecoration from "images/purple-triangle.svg";
// import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
// import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
// import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { customContentStyles } from "components/misc/Typography.js"

const Container = tw.div`relative pr-10 pl-10 sm:pt-0 lg:pt-0 md:pt-0`;
const TwoColumn = tw.div`flex flex-col py-0 xl:flex-row justify-between max-w-screen-xl mx-auto py-0 md:pt-2 pb-0 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0 pb-10`;
// const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`pl-0 ml-0 mr-0 xl:w-6/12 md:mt-0 sm:mt-0 lg:mt-10 pr-6`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
],
    `
    padding: 15px;
    `
);
const TextContent = tw.div`mr-2 text-left lg:py-8 md:text-left md:p-0`;
// const SmallHeading = tw(SmallHeadingBase)`text-center text-lg md:text-left`;
const Heading = tw(
  SectionHeading
)`font-black text-left text-2xl sm:text-2xl lg:text-2xl text-left md:text-left leading-tight tracking-tight font-almostbold pb-5`;
// const ReadMoreToggler = tw.div`relative pr-10 pl-10 pt-0 pb-0`;
const Description = styled.p`
${tw`text-left md:text-left text-sm md:text-base lg:text-lg font-medium leading-normal text-gray-700`}
  ${customContentStyles}
`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-12 text-sm inline-block mx-auto md:mx-0 w-2/6 text-center lg:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

export default ({
  smallHeading= "Governance",
  heading = (
    <Heading id="governance">
      Corporate Governance
    </Heading>
  ),
  colonedescription = "Toshiba Group Standards have been established to guide our activities so that we can operate under the principles of fairness, integrity and transparency. These policies act at a global level and reflect the commitment of Toshiba to act ethically and with integrity within all business relationships.TIC has implemented the following specific business programs:<br/><ul class='bullets'><li>• QHSE – quality management, workplace safe and health and environment</li><li>• Whistleblower program with internal and external access</li><li>• Modern Slavery commitment for its employees and suppliers</li><li>• Export control as part of Toshiba Group’s export management program</li><li>• CSR commitment</li></ul>",
  coltwodescription = "TIC is involved in compliance programs such as: <ul class='bullets'><li>• Toshiba global corporate audit programs</li><li>• ISO9001 – certification</li><li>• Workplace Health and Safety certification</li><li>• Ex motor certification</li><li>• Environment ISO____ certification</li><li>• Minimum Efficiency Performance Standards (MEPS)</li></ul><br/>Toshiba International Corporation Pty Ltd (TIC) understands that our legal compliance and social, ethical and environmental conduct have an impact on our reputation and standing as a positive corporate entity. We aim to be a responsible partner within the communities in which it operates.",
  primaryButtonText = "Read More",
  primaryButtonUrl = "#",
  imageSrc = SolutionImageSrc,
  buttonRounded = false,
  imageRounded = false,
  imageBorder = false,
  imageShadow = false,
  showDecoratorBlob = true,
  textOnLeft = false,
  features = null,
  iconRoundedFull = true,
  iconFilled = true,
  iconContainerCss = null
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.


  return (
    <Container id="governance">
      <TwoColumn>
      <TextColumn>
      <Heading dangerouslySetInnerHTML={{__html: heading}}
      />
          <TextContent>
            <Description dangerouslySetInnerHTML={{__html: colonedescription}}/>
          </TextContent>
        </TextColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
          <Description dangerouslySetInnerHTML={{__html: coltwodescription}}/>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};