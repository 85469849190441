import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, SmallHeading as SmallHeadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container as ContainerBase, ContentWithPaddingXl } from "components/misc/Layouts.js";
import SolutionImageSrc from "images/solution-1.png";
import { customContentStyles } from "components/misc/Typography.js"
const Container = tw(ContainerBase)`relative bg-secondary-500 mb-0 mt-0 pt-0 pb-0 inset-x-0 top-0 mb-0 text-left lg:-mt-10 z-10`;
const Column = tw.div`w-full max-w-2xl mx-auto p-3 md:max-w-none md:mx-0`;
const TextColumn = styled(Column)(props => [
  tw`md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`pl-10 pr-10 md:pr-24 lg:pr-24 md:ml-8 md:mr-12 lg:ml-12 lg:mr-12 xl:ml-0 xl:mr-0 md:order-last`
]);

const TextContent = tw.div`lg:py-20 text-center md:text-left`;

const SmallHeading = tw(SmallHeadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 text-white pl-0 md:pl-0 lg:pl-0 xl:pl-0 text-left text-2xl sm:text-2xl text-left lg:text-2xl text-left md:text-left leading-tight font-almostbold
`;
const Description = styled.div`
    ${tw`mt-4 text-left mr-2 md:text-left text-sm md:text-base lg:text-lg font-medium leading-normal text-white list-disc`}
   ${customContentStyles}
`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-12 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);


export default ({
  smallHeading = "Solutions",
  heading = (
    <>
    </>
  ),
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut",
  primaryButtonText = "Read More",
  primaryButtonUrl = "#",
  imageSrc = SolutionImageSrc,
  buttonRounded = false,
  imageRounded = false,
  imageBorder = false,
  imageShadow = false,
  showDecoratorBlob = true,
  textOnLeft = false,
  features = null,
  iconRoundedFull = true,
  iconFilled = true,
  iconContainerCss = null,
  blockborder = false,
  keyBenefitContent = false,
  contents = null
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.


  return (

    <Container className="keyBenefits">
      <ContentWithPaddingXl>
       { contents &&
          <Column>
            <TextColumn textOnLeft={textOnLeft}>
              <TextContent>

                {contents.map((benefit, index) => (
                  <>
                      <Heading>
                        {benefit.showTitle == "true" &&
                            heading
                        }
                      </Heading>
                      {benefit.keyBenefitContent &&
                        <Description dangerouslySetInnerHTML={{ __html: benefit.keyBenefitContent }}/>
                      }
                  </>
                  ))}


              </TextContent>
            </TextColumn>
          </Column>
        }
      </ContentWithPaddingXl>
    </Container>
  );
};
