import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import {SectionHeading, SmallHeading as SmallHeadingBase} from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import {ControlButton, PrevButton, NextButton, CardSlider} from "components/misc/Slider";

import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";

import {motion} from "framer-motion";
import companyLogo1 from "images/companyLogo1.svg";
import companyLogo2 from "images/companyLogo2.svg";
import companyLogo3 from "images/companyLogo3.svg";
import companyLogo4 from "images/companyLogo4.svg";

const Container = tw.div`relative my-0 py-0 pt-0 pb-16`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)`flex`;
const SmallHeading = tw.div`tracking-wider text-sm font-medium text-primary-500 `;

const Card = tw(motion.a)`h-full flex! flex-col max-w-sm relative focus:outline-none`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}"); background-repeat:no-repeat; height:60% !important;
  padding: 4rem !important; width: 4rem !important;`,
  tw`w-48 h-48 sm:h-48 bg-cover bg-center`
]);
const SloganContainer = styled.div`
  ${tw`mt-10 text-base flex text-center justify-center lg:justify-center text-gray-700`}
`;

const TextInfo = tw.div`text-lg`;
const TitleContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw(SectionHeading)`font-bold uppercase text-lg mt-10 mb-0 flex justify-center`;
const Tagline = tw(SmallHeadingBase)`font-bold uppercase text-black text-sm mt-0 flex justify-center`;


const CardButton = tw(PrimaryButtonBase)`text-sm`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6 uppercase`;

export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    dots: false,
    draggable: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  /* Change this according to your needs */
  const companies = [
        {
          heading: "Company 1",
          tagline: "Tagline",
          description: "Lorem ipsum donor amet siti ceali placeholder text",
          url: "",
          imageSrc: companyLogo1
        },
        {
          heading: "Company 2",
          tagline: "Tagline",
          description: "Lorem ipsum donor amet siti ceali placeholder text",
          url: "",
          imageSrc: companyLogo2
        },
        {
          heading: "Company 3",
          tagline: "Tagline",
          description: "Lorem ipsum donor amet siti ceali placeholder text",
          url: "",
          imageSrc: companyLogo3
        },
        {
          heading: "Company 4",
          tagline: "Tagline",
          description: "Lorem ipsum donor amet siti ceali placeholder text",
          url: "",
          imageSrc: companyLogo4
        },
        {
          heading: "Company 5",
          tagline: "Tagline",
          description: "Lorem ipsum donor amet siti ceali placeholder text",
          url: "",
          imageSrc: companyLogo1
        },
      ],
  linkText = "Find out More",
  heading = "",
  smallHeading = "Companies"
  return (
    //  className="comp-padding"
    <Container>
      <Content>
        <PrevButton onClick={sliderRef?.slickPrev} className="prevButton"><ChevronLeftIcon/></PrevButton>
        <NextButton onClick={sliderRef?.slickNext} className="nextButton"><ChevronRightIcon/></NextButton>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {companies.map((company, index) => (
            <Card key={index} >
              <CardImage imageSrc={company.imageSrc} />
              <TextInfo>
                {/* <Title>{company.heading}</Title> */}
                {/* <Tagline>{company.tagline}</Tagline> */}
              </TextInfo>
            </Card>
          ))}
        </CardSlider>
        <SloganContainer>
          <div>Trusted by the world's leading businesses</div>
        </SloganContainer>
      </Content>
    </Container>
  );
};
