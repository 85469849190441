import React, { useState, Component} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {SectionHeading, SmallHeading as SmallHeadingBase} from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import {ControlButton, PrevButton, NextButton, CardSlider, CardSliderMain} from "components/misc/Slider";

import {motion} from "framer-motion";

import productImage1 from "images/gallery-main.png";
import productImage2 from "images/gallery-2.png";
import productImage3 from "images/gallery-3.png";


const Container = tw.div`relative pr-10 pl-10 pt-0 pb-0`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-center`;
const Heading = tw(SectionHeading)`text-3xl`;
const SmallHeading = tw.div`tracking-wider text-sm font-medium text-primary-500 `;

const Card = tw(motion.a)`h-full flex! flex-col max-w-sm relative focus:outline-none justify-center items-center `;
const MainCard = tw(motion.a)`h-full flex! flex-col relative focus:outline-none `;
const MainImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");
    width: 500px; 
    // height: 28rem;
    @media screen and (max-width: 900px) {
    width: 300px;
    height: 16rem;
  }
  `,
  tw`h-112 bg-cover bg-center`
]);

const Overlay = tw.div`p-1 bottom-0 text-center left-0 w-full text-base lg:p-3 xl:p-3 sm:p-2 md:p-2 text-black z-0 bg-white opacity-60`;

const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}"); 
        @media screen and (max-width: 846px) {
          width: 120px; 
          height: 100px;
          position: relative;
          overflow: hidden;
        }
        @media screen and (max-width: 500px) {
          width: 80px;
          height: 80px;
        }
        @media screen and (max-width: 400px) {
          width: 70px;
          height: 70px;
        }
        @media screen and (max-width: 360px) {
          width: 60px;
          height: 60px;
        }`,
        // tw(motion.a)`ml-144`,
  tw`h-32 w-40 lg:w-56 lg:h-48 xl:w-56 xl:h-48 bg-cover bg-center`
]);
const TextInfo = tw.div`text-lg text-center`;
const TitleContainer = tw.div`flex flex-col sm:justify-between sm:items-center font-medium text-gray-600 text-center`;
const TitleContainerMain = tw(TitleContainer)`text-lg text-black`;

const Title = tw(SectionHeading)`font-bold uppercase text-lg mt-10 mb-0 flex justify-center`;
const Tagline = tw(SmallHeadingBase)`font-bold uppercase text-black text-sm mt-0 flex justify-center`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6 uppercase`;

const linkText = "Find out More";
const heading = "Image Gallery";
export default class PhotoGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }


  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }


  render() {

    
    return (
        <Container>
          <Content>
            <Heading>{heading}</Heading>
            <CardSliderMain
                asNavFor={this.state.nav2}
                ref={slider => (this.slider1 = slider)}
                arrows={false}
                infinite={false}
                className="mainSlider">
              {this.props.galleryImages.map((image, index) => (
                  <MainCard key={index}>
                     <MainImage imageSrc={image.url}/>
                      {image.displayTitle &&
                          <Overlay>
                            <TitleContainerMain dangerouslySetInnerHTML={{__html: image.displayTitle}}/>
                          </Overlay>
                      }
                  </MainCard>
              ))}
            </CardSliderMain>
            <PrevButton onClick={this.slider2?.slickPrev} className="prevButton"><ChevronLeftIcon/></PrevButton>
            <NextButton onClick={this.slider2?.slickNext}
                        className="nextButton"><ChevronRightIcon/></NextButton>
            <CardSlider
                asNavFor={this.state.nav1}
                ref={slider => (this.slider2 = slider)}
                // {...sliderSettings}
                slidesToShow={4}
                swipeToSlide={true}
                focusOnSelect={true}
                arrows={false}
                infinite={false}
                className={"sliderNav"}>
              {this.props.galleryImages.map((image, index) => (
                  <Card key={index}>
                    <CardImage imageSrc={image.url} className="galleryview"/>
                    {/* <TitleContainer dangerouslySetInnerHTML={{ __html: image.title}} /> */}
                  </Card>
              ))}
            </CardSlider>
          </Content>
        </Container>
    );
  }
}