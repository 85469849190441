import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
export const SectionDescription = tw.p`mt-4 text-sm md:text-base lg:text-lg font-normal leading-normal text-gray-700 max-w-xl tracking-normal`;

//h5 should be the same as SmallHeading in Headings.js TODO would be to work out how to import that into this section
export const customContentStyles = css`
  ul {
    list-style: disc;
    margin-left: 3rem;
  }

  p {
    ${tw`min-h-6`}
  }

  table {
    ${tw`min-w-full divide-y divide-gray-200`}
    table-layout: fixed;
  }

  table td,
  table th {
    ${tw`py-2 px-4`}
    overflow: hidden;
  }

  table img {
    ${tw`block`}
    float: left;
    clear: both;
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 640px) {
    table td,
    .table th {
      ${tw`block w-full`}
    }
  }
  @media (min-width: 768px) {
      .grid-cols-3 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }
  @media (min-width: 1024px) {
    .grid-cols-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  h4 {
    ${tw`text-lg font-bold mt-4`}
  }
  h5 {
    ${tw`text-base font-bold mt-2`}
  }
  h6 {
    ${tw`text-sm font-bold mt-0`}
  }
`;
export const Description = styled.div`
    ${tw`mt-8 text-left md:text-left text-sm md:text-base lg:text-lg font-medium leading-normal text-gray-700`}
      h5 {
        ${tw`font-almostbold text-primary-500 tracking-normal`}
      }
    ${customContentStyles}
    `;
