import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import {SectionHeading, SmallHeading as SmallHeadingBase} from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import {
    ControlsTopRight as Controls,
    PrevButtonTop as PrevButton,
    NextButtonTop as NextButton,
    CardSlider,
    HeadingWithControl
} from "components/misc/Slider";

import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";

import {motion} from "framer-motion";
import industryImage1 from "../../images/industry-image-1.png";
import Decorator from "images/decorator-triangle.svg";
import industryImage2 from "../../images/industry-image-2.png";
import {customContentStyles} from "../misc/Typography.js";

const DecoratorTriangle = styled.div `
        .decorator {
            float:left;
            margin-top:4rem;
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
`;

const Container = tw.div`relative lg:pt-0 sm:p-0 m-0 md:bg-transparent lg:bg-transparent`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center lg:pt-0`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const SliderColumn = tw(Column)`md:w-7/12 flex-shrink-0 relative`;
const TextColumn = tw(Column)`md:w-5/12 pl-10 flex-shrink-0 relative pr-10 pt-3`;

// 6 and 5 
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-2xl sm:text-2xl lg:text-2xl text-left md:text-left leading-tight tracking-tight font-almostbold`;
// const ReadMoreToggler = tw.div`relative pr-10 pl-10 pt-0 pb-0`;
const SmallHeading = tw(SmallHeadingBase)`text-left text-2xl`;

const Description = styled.p`
    ${tw`text-lg mt-4 text-left md:text-left text-sm md:text-base lg:text-lg font-medium leading-normal text-gray-700`}
    ${customContentStyles}

`;
const Card = tw(motion.a)`h-full flex! flex-col max-w-sm relative focus:outline-none`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-80! h-56 sm:h-64 bg-cover bg-center`
]);
const TriangleImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-full h-144 w-144 sm:h-72 bg-cover bg-center`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleContainer = tw.div`flex flex-col justify-between items-center`;
const Title = tw.h5`text-2xl font-bold text-black`;

const CardButton = tw(PrimaryButtonBase)`text-sm`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6 uppercase`;
export default ({
    description = "",
    linkText = "Find out More",
    heading = "Proven expertise to meet industry challenges",
    smallHeading = "Industries",
    showDescription = false,
    cards = [],
}) => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 2,
    dots: true,
    draggable: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  }




  return (
    <Container>
        <DecoratorTriangle>
            <span className="decorator triangle">
              <TriangleImage className="triangle" imageSrc={Decorator} />
            </span>
        </DecoratorTriangle>
      <Content>
      <TwoColumn>
          <TextColumn>
            {smallHeading && <SmallHeading>{smallHeading}</SmallHeading>}
            <HeadingWithControl>
              {heading && <Heading>{heading}</Heading>}
              <Controls>
                <PrevButton onClick={sliderRef?.slickPrev}><ChevronLeftIcon/></PrevButton>
                <NextButton onClick={sliderRef?.slickNext}><ChevronRightIcon/></NextButton>
              </Controls>
            </HeadingWithControl>
            <Description>{description}</Description>
          </TextColumn>
          <SliderColumn>
            <CardSlider ref={setSliderRef} {...sliderSettings}>
              {cards.map((card, index) => (
                  <Card key={index} href={`/${card.uri}`}>
                    <CardImage imageSrc={card.cardImage[0].url} />
                    <TextInfo>
                      <TitleContainer>
                        <Title className="title">{card.heading}</Title>
                      </TitleContainer>
                        {showDescription &&
                            <Description>{card.description}</Description>
                        }
                    </TextInfo>
                  </Card>
              ))}
            </CardSlider>
          </SliderColumn>
        </TwoColumn>
      </Content>
    </Container>
  );
};