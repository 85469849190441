import React, { useState } from "react";
import { motion } from "framer-motion";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, SmallHeading as SmallHeadingBase } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
// import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
// import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { ContentWithPaddingXl } from "components/misc/Layouts.js";
import Product1 from "images/product-1.png";
import Product2 from "images/product-2.png";
import Product3 from "images/product-3.png";
import { SectionDescription } from "components/misc/Typography.js";
import {css} from "styled-components/macro";

const Container = tw.div`relative`;
const SmallHeading = tw(SmallHeadingBase)`text-left text-sm md:text-left lg:min-h-20 xl:min-h-20 ml-6 lg:ml-0 xl:ml-0`;

const Heading = tw(
  SectionHeading
)`font-black text-left text-2xl lg:pl-6 sm:pl-6 sm:text-2xl lg:text-2xl text-center md:text-left leading-tight tracking-tight font-almostbold`;

const Card = styled.div(props => [
  tw`h-full p-4 sm:w-full lg:relative lg:max-w-xxs p-0 focus:outline-none mx-3 my-3 pt-0 lg:ml-5 md:ml-8`,
  `box-shadow: 0px 3px 6px #00000029;
  display: inline-block;`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-xl ml-6 lg:ml-0 xl:ml-0 font-bold text-black lg:min-h-20 xl:min-h-20`;
const Ruler = tw.div`pl-4 border-l border-black`;

const Description = styled.p`
    ${tw`text-sm leading-normal mt-2 sm:mt-4 text-gray-700 lg:min-h-56 xl:min-h-56 mr-6 lg:mr-0 xl:mr-0 ml-6 lg:ml-0 xl:ml-0`}
`;

const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;
const CardButton = tw(PrimaryButtonBase)`w-full items-center text-lg uppercase mt-10 text-primary-500 bg-white border-solid border-4 border-primary-500`;


const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-12 text-sm inline-block mx-auto md:mx-0 text-center lg:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);


const ThreeColumnContainer = styled.div`
  ${tw`flex items-center mx-auto flex flex-wrap`}
`;

const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const TwoColumn = tw.div`py-0 md:justify-between max-w-screen-xl mx-auto md:pt-6 pb-0 items-center`;

export default () => {
  const cards = [
        {
          imageSrc:Product1,
          title: "Trust",
          smallHeading: "Built on Dependability",
          content: "Earning the customer’s trust, highlights the importance of a good track record and longevity. For the customer, dependability means security of business.",
          url: "#"
        },
        {
          imageSrc:Product2,
          title: "Creating Together",
          smallHeading: "With our Customers",
          content: "When customers bring unique needs, together we create elegant solutions.",
          url: "#"
        },
        {
          imageSrc:Product2,
          title: "Integrity",
          smallHeading: "Enables Endurance in Business",
          content: "It is more than what we say, it is who we are and what we do. It is about our Customers.",
          url: "#"
        },
        {
          imageSrc:Product2,
          title: "Teamwork",
          smallHeading: "Finding our Strengths and Contributing to the Result",
          content: "We team with our customers, our suppliers and with ourselves.",
          url: "#"
        },
        {
          imageSrc:Product2,
          title: "Committed to People",
          smallHeading: "Committed to the Future",
          content: "We are improving quality of life and sustainability. We empathise with our customers as we enable their goals to be achieved, together we build the future of Australia.",
          url: "#"
        }
  ],
  linkText = "More Info",
  primaryButtonText = "Download organisational chart",
  primaryButtonUrl = "#",
  heading = "Values"
  return (
    <Container id="values">
       <TwoColumn>
      <Heading dangerouslySetInnerHTML={{__html: heading}}
      />
      </TwoColumn>
    <ContentWithPaddingXl>
     
      <ThreeColumnContainer>        
          {cards.map((card, index) => (
          <Card key={index} href={card.url} target={card.target}>
            <TextInfo className="xs-version">
              <TitleContainer>
                <Title>{card.title}</Title>
              </TitleContainer>
              <Ruler>
              <SmallHeading>{card.smallHeading}</SmallHeading>
              <Description>{card.content}</Description> 
              </Ruler>
            </TextInfo>
          </Card>
          ))}
      </ThreeColumnContainer>
    </ContentWithPaddingXl>
    {/* <PrimaryButton><a href={primaryButtonUrl[0]?.url} target="_blank">{primaryButtonText}</a></PrimaryButton> */}
  </Container>
  );
};


