import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import {customContentStyles} from "components/misc/Typography.js";

import {css} from "styled-components/macro";

const Container = tw.div`relative p-10 pt-10 mx-auto`;
const Content = tw.div`max-w-screen-xl lg:pt-0 mx-auto`;

const HeadingWithControl = tw.div`pt-0 flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)`text-3xl pt-0`;


const Card = styled.a(props => [
  tw`h-full sm:w-full relative focus:outline-none mx-3 my-3 pt-0 md:mx-auto w-full`,
  `box-shadow: 0px 3px 6px #00000029;
  display: inline-block;`
]);
const CardImage = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`w-full h-56 sm:h-64 bg-cover bg-center`
]);
const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;

const TextInfo = tw.div`py-6 px-6 sm:px-10 sm:py-6`;
const TitleContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold text-primary-500`;

const Description = styled.p`
  ${tw`text-sm leading-normal mt-2 sm:mt-4 text-gray-700`}
  ${customContentStyles}
`;
const Remuneration = styled.p`
    ${tw`text-sm leading-normal mt-2 sm:mt-4 text-gray-700`}
`;
const PositionType = styled.p`
    ${tw`text-sm leading-normal mt-2 sm:mt-4 text-gray-700`}
`;
const Location = styled.p`
    ${tw`text-sm leading-normal mt-2 sm:mt-4 text-gray-700`}
`;
const Department = styled.p`
    ${tw`text-sm leading-normal mt-2 sm:mt-4 text-gray-700`}
`;
const PositionCategory = styled.p`
    ${tw`text-sm leading-normal mt-2 sm:mt-4 text-gray-700`}
`;

const NoJobs = tw.div `flex flex-col justify-center justify-between items-center pt-10 pb-10 text-2xl font-almostbold`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;
const CardContainer =tw.div`flex flex-col justify-center justify-between items-center`;
const CardButton = tw(PrimaryButtonBase)`w-1/2 items-center text-lg uppercase mt-10 text-primary-500 bg-white border-solid border-4 border-primary-500`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6 uppercase`;
export default ({

  cards = [],
  linkText = "More Info",
  heading = "listed products"
}) => {
  return (
    <Container>
      <Content>
         <Heading>{heading}</Heading>
          {cards.length > 0 ?
                  cards.map((card, index) => (
                      <Card key={index} href={`/jobs/${card.slug}`} target={card.target}>
                          <TextInfo className="xs-version">
                              <TitleContainer>
                                  <Title>{card.title}</Title>
                              </TitleContainer>
                              {card.department[0] &&
                                  <Department>Department: {card.department[0].title}</Department>
                              }
                              {card.remuneration &&
                                  <Remuneration>Remuneration: {card.remuneration}</Remuneration>
                              }
                              {card.positionType[0] &&
                                  <PositionType>Position Type: {card.positionType[0].title}</PositionType>
                              }
                              {card.category[0] &&
                                  <PositionCategory>Category: {card.category[0].title} {card.category[0].subHeading}</PositionCategory>
                              }
                              {card.location[0] &&
                                  <Location>Location: {card.location[0].title}</Location>
                              }
                              <Description dangerouslySetInnerHTML={{__html: card.shortDescription}}></Description>
                              <CardContainer>
                                  <CardButton>{linkText}</CardButton>
                              </CardContainer>
                          </TextInfo>
                      </Card>
                  ))
              : 
              <NoJobs>No Jobs found. Try resetting filters</NoJobs>
          }
      </Content>
    </Container>
  );
};
