import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import {
  Controls,
  PrevButtonCentred as PrevButton,
  NextButtonCentred as NextButton,
  CardSlider as CardSliderBase,
  HeadingWithControlCentred as HeadingWithControl
} from "components/misc/Slider"
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import ContentImageSlider from "components/features/ContentImageSlider.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed";
import ContactUsForm from "components/forms/ContactUsForm.js";
import { customContentStyles } from "components/misc/Typography.js"

const BannerText = tw.div`p-10`;
const Container = tw.div`relative mb-20 mx-auto`;
const SliderContainer = tw.div`relative`;
const HeaderRow = tw.div`flex justify-between p-6 sm:p-6 md:p-6 lg:p-0 -mt-10 lg:mt-0 md:-mt-20 sm:mt-0 items-center flex-col xl:flex-row`;

const HeaderRowOne = tw.div`max-w-screen-xl mx-auto mb-0 mt-0`;
const Description = tw.div`max-w-screen-xl mx-auto pt-4 mb-0 mt-0 text-black md:text-left text-sm md:text-base lg:text-lg font-medium leading-normal text-gray-700`;
const TabsControl = tw.div`flex flex-wrap bg-white -mt-112 leading-none mt-0 mb-0 xl:mt-0`;
const OneColumn = tw.div`flex flex-col text-left lg:flex-row md:items-center max-w-screen-xl mx-auto lg:py-0 pt-0 mt-0`;
const TwoColumn = tw.div`flex flex-col pt-0 mt-0 lg:flex-row md:items-center max-w-screen-xl`;
const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-left lg:text-left`;
const RightColumn = tw.div`relative lg:w-6/12 flex flex-col justify-center text-center`;
// const Heading = tw(
//   SectionHeading
// )`ml-0 font-black text-left text-2xl sm:text-2xl md:text-center lg:text-2xl md:text-left leading-tight tracking-tight font-almostbold`;
// const ReadMoreToggler = tw.div`relative pr-10 pl-10 pt-0 pb-0`;
const Heading = tw(SectionHeading)`text-2xl`;


const TabControl = styled.div`
  ${tw`leading-6 cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-bold transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-white text-primary-500!`}
  ${props => props.active && `border-bottom: 2px solid #E61E1E;`}

`;

const TabContent = styled(motion.div)`
  ${tw`flex flex-wrap m-0 p-0`}
  ${customContentStyles}
`;
const CardContainer = tw.div`w-full p-0 m-0`;
const Card = tw(motion.a)`bg-white p-0 m-0 block sm:max-w-none sm:mx-0`;

const CardText = tw.div`p-6 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = styled.p`
    ${tw`mt-1 text-sm font-medium text-gray-900`}
    ${customContentStyles}
`;
const TabDescription = tw.div`w-full p-0 m-0`;

const CardSlider = styled(CardSliderBase)`
  ${tw`mt-16`}
  .slick-track {
    ${tw`flex `}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
    :not(&.slick-current) {
        overflow: hidden;
    }
  }
`;
const ImageCard = tw(motion.a)`h-80 lg:h-full xl:h-full flex! flex-col max-w-xl relative focus:outline-none`;
const ImageCardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-auto w-100 sm:h-72 bg-cover bg-center`
]);
const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
    width: 100% !important;
    height: auto !important;
    margin-top: 2% !important;
    display: flex !important;
    justify-content: center !important;
  
    
  ${tw`rounded flex-col justify-between items-center`}
  iframe {
    ${tw`rounded bg-black shadow-xl w-full`}
  }
`;

export default ({
  heading = "",
  description = "",
  tabs = [],
  contentId = "",
  contactForm = false,
  indexIncrement = 0
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(0);
  const sliderRefs = useRef([]);
  const sliderSettings = {
    arrows: false,
    autoplay: false,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    dots: false,
    draggable: true,
  };
  // Initialize the sliderRefs.current array to an empty array
  if (sliderRefs.current.length !== tabs.length) {
    sliderRefs.current = new Array(tabs.length).fill(null);
  }
  const handlePrevClick = (index) => {
    const slider = sliderRefs.current[index];
    slider.slickPrev();
  };

  const handleNextClick = (index) => {
    const slider = sliderRefs.current[index];
    slider.slickNext();
  };

  return (

    <Container id={contentId}>
      <BannerText>
      <HeaderRowOne>
      {heading &&
                    <Heading dangerouslySetInnerHTML={{__html: heading}} />
            }
      </HeaderRowOne>
      <Description>
      
                    <Description dangerouslySetInnerHTML={{__html: description}} />
            
      </Description>
      </BannerText>
      <ContentWithPaddingXl>
     
        <HeaderRow>
       
          <TabsControl>
            {contactForm &&
                <TabControl key={0} active={activeTab === 0} onClick={() => setActiveTab(0)}>
                  Contact Us
                </TabControl>
            }
            {tabs &&
                  tabs.map((tabName, index) => (
                      <TabControl key={index+indexIncrement} active={activeTab === index+indexIncrement} onClick={() => setActiveTab(index+indexIncrement)}>
                        {tabName.titleTab}
                      </TabControl>
                  ))
            }
          </TabsControl>
        </HeaderRow>

        {contactForm &&
            <TabContent
                key={0}
                variants={{
                  current: {
                    opacity: 1,
                    scale:1,
                    display: "flex",
                  },
                  hidden: {
                    opacity: 0,
                    scale:0.8,
                    display: "none",
                  }
                }}
                transition={{ duration: 0.4 }}
                initial={activeTab === 0 ? "current" : "hidden"}
                animate={activeTab === 0 ? "current" : "hidden"}

            >
            <ContactUsForm/>
            </TabContent>
        }
        {tabs &&
            tabs.map((tabKey, index) => (
          <TabContent
            key={index+indexIncrement}
            variants={{
              current: {
                opacity: 1,
                scale:1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale:0.8,
                display: "none",
              }
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === index+indexIncrement ? "current" : "hidden"}
            animate={activeTab === index+indexIncrement ? "current" : "hidden"}

          >
              <CardContainer key={index+indexIncrement}>
                <Card className="group" initial="rest" whileHover="hover" animate="rest">
                  <CardText>
                    <CardContent>
                        {tabKey.galleryimages.length == 0 &&
                        <OneColumn>
                          <TabDescription dangerouslySetInnerHTML={{ __html: tabKey.contentTab }} />
                        </OneColumn>
                        }
                        {tabKey.galleryimages.length > 0 &&
                            <TwoColumn>
                              <LeftColumn>
                                <TabDescription dangerouslySetInnerHTML={{__html: tabKey.contentTab}}/>
                              </LeftColumn>
                              <RightColumn>
                                <SliderContainer className="content-tab-slider">
                                  <CardSlider ref={slider => (sliderRefs.current[index+indexIncrement] = slider)} {...sliderSettings} >

                                    {tabKey.galleryimages.map((image, imageindex) => (
                                        <ImageCard key={imageindex}>
                                          <ImageCardImage imageSrc={image.url}/>
                                        </ImageCard>

                                    ))}

                                  </CardSlider>
                                  <HeadingWithControl>
                                    <Controls className="indicators">
                                      <PrevButton onClick={() => handlePrevClick(index+indexIncrement)}><ChevronLeftIcon/></PrevButton>
                                      <NextButton onClick={() => handleNextClick(index+indexIncrement)}><ChevronRightIcon/></NextButton>
                                    </Controls>
                                  </HeadingWithControl>
                                </SliderContainer>
                              </RightColumn>
                            </TwoColumn>
                        }
                      {tabKey.video.length > 0 &&
                          <>{
                            tabKey.video.map((video, index) => (
                                <StyledResponsiveVideoEmbed
                                    key={index}
                                    url={video.url}
                                    background="transparent"
                                    videoWidth={tabKey.videoWidth}
                                />
                            ))
                          }</>
                      }
                    </CardContent>
                  </CardText>
                </Card>
              </CardContainer>
          </TabContent>
        ))}
      </ContentWithPaddingXl>
    </Container>
  );
};

