import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ContentWithPaddingXl } from "components/misc/Layouts.js";

import GraphQlHandler from "api/craft/GraphQlHandler";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithImage.js";
import {HeroHeading as HeroHeadingBase, HeroSmallHeading as HeroSmallHeadingBase} from "./components/misc/Headings";
import Solutions from "components/features/TwoColSolutions.js";
import Content from "components/cards/TabCardGridCommonContent.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import ContactWarning from "components/features/OneColDescription.js";
import ZohoForm from "components/forms/ZohoForm.js"
import styled from "styled-components";

import { HR as HRBase } from "./components/misc/Layouts";
import CommonContent from "./commonContent";
import KeyBenefits from "./components/features/OneColKeyCommonBenefits";

const HR = tw(HRBase)``;
const ContentContainer = tw.div`pt-10`;
const Container = tw.div`-mt-8`;
export default () => {
  const [entries, setContactUsEntry] = useState([]);
  const SmallHeading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw``;

  const HeroSmallHeading = tw(
    HeroSmallHeadingBase
)`pt-4 text-3xl md:text-5xl leading-extratight max-w-md
`;

  useEffect(() => {
    GraphQlHandler.query(`
    query MyQuery {
      contactUsEntries {
        ... on contactUs_contactUs_Entry {
          id
          subHeading
          siteId
          mainDescription
          heroImage {
            url
            id
          }
          heroIcon {
            id
            url
          }
          contentTabs {
            ... on contentTabs_BlockType {
              id
              contentTab
              titleTab
              video {
                id
                mimeType
                url
                size
              }
              videoWidth
              galleryimages {
                id
                url
                title
              }
            
            }
          }
        }
      }     
    }
    
    `)
        .then((data) => {
            setContactUsEntry(data.contactUsEntries);
        });

  }, [])
    useEffect(() => {
        console.log("INITIATED");
        var divElement = "zf_div_po-cr6GhUS29yu9pYGevFN8jmtoZXityHEtJthLnelI";
        var formImport = "https://forms.zohopublic.com.au/tenfourmedi/form/TestForm/formperma/po-cr6GhUS29yu9pYGevFN8jmtoZXityHEtJthLnelI?zf_rszfm=1";
        ZohoForm(formImport, divElement);
    }, [entries]);
  return (

    <AnimationRevealPage>
  {
            (entries || []).map((contactusEntry, index) =>

                <>
                    <Hero
                          key={index}
                          heading={<HeroSmallHeading>Contact us</HeroSmallHeading>}
                          smallHeading={<></>}
                          breadcrumbCategory={<>Contact Us</>}
                          // description={<></>}
                          iconAlt={<>Contact us</>}
                          imageSrc={contactusEntry.heroImage}
                          iconSrc={contactusEntry.heroIcon}
                          imageCss={imageCss}
                    />
                      
                      <Container>
                      <Content
                            tabs={contactusEntry.contentTabs}
                            contactForm = {true}
                            indexIncrement = {1}
                        />
                        </Container>

                    {/* <ContentContainer>
                    <ContactWarning dangerouslySetInnerHTML={{_html: contactusEntry.mainDescription}} /> 
                    
                        <ContactWarning description = {contactusEntry.mainDescription}/>

                    
                    </ContentContainer> */}

                </>
     )
    }
         <>
          <Solutions

          />
          <CommonContent
             showCompanies={false}
          />
         </>

          <Footer />
    </AnimationRevealPage>
  );

}
