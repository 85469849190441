import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line

import { useLocation } from 'react-router-dom';
import GraphQlHandler from "api/craft/GraphQlHandler";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithImage.js";
import Solutions from "components/features/TwoColSolutions.js";
import IndustriesCats from "components/features/ThreeColCategory.js";
import Industries from "components/features/ThreeColSliderIndustries.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import defaultHeroImage from "images/BowenBsnApril15.jpg";
import defaultHeroIcon from "images/map.svg";
// import KeyBenefits from "components/features/OneColKeyCommonBenefits.js";


import styled from "styled-components";
import {
    HeroHeading as HeroHeadingBase,
    HeroSmallHeading as HeroSmallHeadingBase,
    SectionHeading
} from "./components/misc/Headings";
import { HR as HRBase } from "./components/misc/Layouts";
import CommonContent from "./commonContent";

const HR = tw(HRBase)``;



const Heading = tw(SectionHeading)`text-3xl`;
const HeroHeading = tw(
    HeroHeadingBase
)`pt-4 text-3xl md:text-5xl leading-extratight max-w-md
`;
const HeroSmallHeading = tw(
    HeroSmallHeadingBase
)`pt-4 text-3xl md:text-5xl leading-extratight max-w-md
`;
export default () => {
    const [mainEntryData, setMainEntryData] = useState([]);
    const [categories, setCategories] = useState([]);
    const SmallHeading = tw.span`tracking-wider text-sm font-medium`;
    const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
    const Description = tw.span`inline-block mt-8`;
    const imageCss = tw``;
    const location = useLocation();
    const subPage = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1) !== "" ?
        true : false
    ;
    const slug = subPage ?
        window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1) : "*"
    ;

    useEffect(() => {
        GraphQlHandler.query(`
        query MyQuery {
          mainEntryData: industriesLPEntries {
            ... on industriesLP_industriesLP_Entry {
              id
              shortDescription
              subHeading
              heroIcon {
                ... on icons_Asset {
                  id
                  url
                }
              }
              keyBenefits {
                ... on keyBenefits_BlockType {
                  id
                  keyBenefitContent
                  keyBenefitsTitle
                }
              }
              heading
              heroImage {
                ... on headerImages_Asset {
                  id
                  url
                }
              }
            }
          }
          industries: industriesCategoriesEntries(slug: "${slug}") {
            ... on industriesCategories_default_Entry {
              id
              externalUrl
              categoryImage: industriesCategoryImage {
                id
                url
              }
              shortDescription
              slug
              title
              uri
              industryLinks {
                id
                title
                uri
                ... on categories_category_Entry {
                  id
                  slug
                  categoryImage : productCategoryImage {
                    ... on productImages_Asset {
                      id
                      url
                    }
                  }
                
                }
                ... on servicesCategories_default_Entry {
                  id
                  categoryImage: servicesCategoryImage {
                    id
                    url
                  }
                
                }
              }
              keyBenefits {
                ... on keyBenefits_BlockType {
                  id
                  keyBenefitContent
                }
              }
              heroIcon {
                ... on icons_Asset {
                  id
                  url
                }
              }
              heroImage {
                ... on headerImages_Asset {
                  id
                  url
                }
              }
            }
          }
        }



    `)
    // line 107 and 115 can have shortDescription added if they need shordescription in the backend.
            .then((data) => {
                setMainEntryData(data.mainEntryData);
                setCategories(data.industries);
            });
    }, [location])

    return (

        <AnimationRevealPage>

            {
                (categories || []).map((mainEntry, index) =>
                    (index === 0 &&
                        <>

                            <Hero
                                  key={index}
                                  heading={
                                      <HeroSmallHeading>{subPage ? mainEntry.title : "Industries"}</HeroSmallHeading>}
                                  smallHeading={<></>}
                                  breadcrumbType={<>Industries</>}
                                  breadcrumbName={subPage ? mainEntry.title : "All"}
                                  description={subPage ? mainEntry.shortDescription : "See our Industries below"}
                                  iconSrc={subPage ? mainEntry.heroIcon : mainEntryData[0].heroIcon}
                                  iconAlt={<>{mainEntry.heading}</>}
                                  imageSrc={subPage ? categories[0].heroImage : mainEntryData[0].heroImage}
                                  imageCss={imageCss}
                                  subPage={subPage}

                            />

                    {/* <KeyBenefits
                                 key={index}
                                 className="keyBenefits"
                                 heading={productEntry.keyBenefits[0].keyBenefitsTitle !== null ? productEntry.keyBenefits[0].keyBenefitsTitle : ""}
                                 contents={categories[0].keyBenefits}
                                 /> */}
                    

                                <IndustriesCats
                                    heading={<Heading>{subPage ?  "Products and services in this industry" : "Our Industries"}</Heading>}
                                    showHeading = {true}
                                    cards={subPage ? categories[0].industryLinks  : categories}
                                />

                        </>
                    )

            )}



            <Solutions

            />

            <CommonContent
                showCompanies={false}
                showIndustries={false}
            />

            <Footer />
        </AnimationRevealPage>
    );
}
