import React, { useState } from "react";
import { motion } from "framer-motion";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, SmallHeading as SmallHeadingBase } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
// import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
// import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import {customContentStyles} from "components/misc/Typography.js";

import Product1 from "images/product-1.png";
import Product2 from "images/product-2.png";
import Product3 from "images/product-3.png";

import {css} from "styled-components/macro";

const Container = tw.div`relative pt-0 mx-auto`;
const Content = tw.div`max-w-screen-xl lg:pt-0 mx-auto`;
const SmallHeading = tw(SmallHeadingBase)`text-left text-lg md:text-left`;
const HeadingWithControl = tw.div`pt-0 flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)`text-center text-base md:text-xl pt-16 pb-20 bg-red-1100 text-white sm:text-base`;

const Card = styled.a(props => [
  tw`relative `,
  `
  // box-shadow: 0px 3px 6px #00000029;
  display: inline-block;
  @media screen and (min-width: 1280px) {
    width: 640px;
    
    }
  @media screen and (max-width: 1280px) {
    width: 100%;
    }`
]);

//lg:max-w-screen-sm

const CardImage = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`w-full h-56 sm:h-64 bg-cover bg-center`
]);

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-xl font-bold text-black`;

const Description = styled.p`
    ${tw`text-sm leading-normal min-h-6 mt-2 sm:mt-4 text-gray-700`}
    ${customContentStyles}
`;
const Ruler = tw.div`m-4 pl-4 border-l border-black lg:m-0`;
const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;
const CardButton = tw(PrimaryButtonBase)`w-full items-center text-lg uppercase mt-10 text-primary-500 bg-white border-solid border-4 border-primary-500`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6 uppercase`;
export default () => {
  const cards = [
        {
          imageSrc:Product1,
          title: "Basic Commmitment of the Toshiba Group",
          smallHeading: "Commited to People, Commited to the Future",
          content: "At Toshiba, we commit to raising the quality of life for the people around the world, ensuring progress and that there is harmony with our planet.",
          url: "#"
        },
        {
          imageSrc:Product2,
          title: "Our Purpose",
          smallHeading: "We turn on the promise of a new day",
          content: "We are Toshiba. We have an unwavering drive to make and do things that lead to a better world.",
          url: "#"
        }
  ],
  linkText = "More Info",
  heading = "The Essence of Toshiba"
  return (
    <Container>
      <Content>
        <Heading className="clip-back" id="essence">{heading}</Heading>
          {cards.map((card, index) => (
            <Card key={index} href={card.url} target={card.target}>
              {/* <CardImage imageSrc={card.imageSrc} /> */}
              <TextInfo className="xs-version">
              <Ruler>
                <TitleContainer>
                  <Title>{card.title}</Title>
                </TitleContainer>
                <SmallHeading>{card.smallHeading}</SmallHeading>
                <Description>{card.content}</Description>
                {/* <CardButton>{linkText}</CardButton> */}
                </Ruler>
              </TextInfo>
            </Card>
          ))}
      </Content>
    </Container>
  );
};
