import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {motion} from "framer-motion";
import {SectionHeading, SmallHeading as SmallHeadingBase} from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import {ControlButton, PrevButton, NextButton, CardSlider} from "components/misc/Slider";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";


const Container = tw.div`relative pr-10 pl-10 mb-20 mx-auto`;
const Content = tw.div`max-w-screen-xl mx-auto mt-20 lg:py-0`;
const Heading = tw(SectionHeading)`text-2xl mb-10`;
// const ReadMoreToggler = tw.div`relative pr-10 pl-10 pt-0 pb-0`;

const SmallHeading = tw.div`tracking-wider text-sm font-medium text-primary-500 `;

const Card = tw(motion.a)`h-full flex! flex-col max-w-sm relative focus:outline-none`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-64 h-40 sm:h-48 bg-cover bg-center`
]);

const TextInfo = tw.div`text-lg`;
const TitleContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw(SectionHeading)`font-bold uppercase text-sm mt-10 mb-0 flex justify-center`;
const Tagline = tw(SmallHeadingBase)`font-bold uppercase text-black text-sm mt-0 flex justify-center`;


const CardButton = tw(PrimaryButtonBase)`text-sm`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6 uppercase`;
export default ({
    subCategories = [],
    linkText = "Find out More",
    categoryheading = "Categories",
    smallHeading = "Companies",
    subPage = true
}) => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    dots: true,
    draggable: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <Container>
      <Content>
      <Heading>{categoryheading}</Heading>
        <PrevButton onClick={sliderRef?.slickPrev} className="prevButton"><ChevronLeftIcon/></PrevButton>
        <NextButton onClick={sliderRef?.slickNext} className="nextButton"><ChevronRightIcon/></NextButton>
        <CardSlider ref={setSliderRef} {...sliderSettings} >

          {subCategories.map((category, index) => (
              (category.productCategoryImage && (
                      <Card key={index} href={`/${category.uri}`}>
                          <CardImage imageSrc={category.productCategoryImage[0].url}/>
                          <TextInfo>
                              <Title>{category.heading}</Title>
                              {/* <Tagline>{company.tagline}</Tagline> */}
                          </TextInfo>
                      </Card>
                  ))
          ))}
        </CardSlider>

      </Content>
    </Container>
  );
};
