import ReactDOM from "react-dom";
import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, SmallHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";

import {css} from "styled-components/macro";

const Container = tw.div`relative pt-0 inline-flex pl-6 lg:pl-0 md:pl-6 sm:pl-6`;
const Content = tw.div`max-w-screen-xl text-center mx-auto lg:pt-0`
const Select = tw.div`inline-block m-10 text-justify`;
const Heading = tw(SectionHeading)`text-xl pt-0`;
const option = tw``;
export default ({
  filterList = [],
  heading = "",
  showHeading = false,
  listName="filterList",
  onFilterChange

}) => {

  return (
    <Container>
      <Content>
        <Heading>
          {showHeading &&
              <label htmlFor={listName}>{heading}</label>
          }
          <Select>
          <select name={listName} id={listName} onChange={event => onFilterChange(event)}>
              <option value="*">All</option>
              {filterList.map((card, index) => (
                  <option value={card.id}>{card.title}</option>
              ))}
          </select>
          </Select>
          </Heading>
      </Content>
    </Container>
  );
};
