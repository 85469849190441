import appSettings from 'AppSettings'
import { GraphQLClient, gql } from 'graphql-request'

const graphQLClient = new GraphQLClient(appSettings.graphQlEndpoint, {
  headers: {},
})

const GraphQlHandler = {
    query: async function(queryText){
        try {
            const data = await graphQLClient.request(gql`${queryText}`);
            console.debug('GraphQl request completed', {queryText, data});

            return data;
        }
        catch(err){
            console.error('GraphQl - Failed to make graphQL request', {queryText});
            console.error(err);
            return null;
        }
    } 
}

export default GraphQlHandler;