import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithImage.js";
import Content from "components/cards/TabCardGridCommonContent.js";
import Downloads from "components/features/MultiColumnProductDownloads.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import KeyBenefits from "components/features/OneColKeyCommonBenefits.js";
import GraphQlHandler from "api/craft/GraphQlHandler";
import PhotoGallery from "components/features/FourColSliderProductsGallery.js";
import { HR as HRBase } from "./components/misc/Layouts";
import {
    HeroHeading as HeroHeadingBase,
    HeroSmallHeading as HeroSmallHeadingBase,
    SectionHeading
} from "./components/misc/Headings";
import CommonContent from "./commonContent";
import { useLocation } from 'react-router-dom';
import ContentWithVideo from "./components/features/TwoColContentWithVideo";
const HR = tw(HRBase)``;
const Heading = tw(SectionHeading)`text-3xl`;
const HeroHeading = tw(
    HeroHeadingBase
)`pt-4 text-3xl md:text-5xl leading-extratight max-w-md
`;
const HeroSmallHeading = tw(
    HeroSmallHeadingBase
)`pt-4 text-3xl md:text-5xl leading-extratight max-w-md
`;
const ContentContainer = tw.div`pt-5`;
export default () => {
  const [entries, setServiceEntry] = useState([]);
  const SmallHeading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw``;

  const location = useLocation();
  const slug = window.location.pathname.replace(/\/$/, "").substring(window.location.pathname.replace(/\/$/, "").lastIndexOf('/') + 1);

    useEffect(() => {
      GraphQlHandler.query(`
          query MyQuery {
              servicesEntries(slug: "${slug}") {
                ... on services_default_Entry {
                  id
                  keyBenefits {
                    ... on keyBenefits_BlockType {
                      id
                      keyBenefitContent
                    }
                  }
                  subHeading
                  title
                  heroIcon {
                    slug
                    title
                    ... on icons_Asset {
                      id
                      url
                    }
                  }
                  heroImage {
                    ... on headerImages_Asset {
                      id
                      url
                    }
                  }
                  keyBenefits {
                    ... on keyBenefits_BlockType {
                      id
                      keyBenefitContent
                      showTitle
                      keyBenefitsTitle
                    }
                  }
                  downloads {
                    ... on downloads_BlockType {
                      id
                      downloadType
                      description
                      buttonToggle
                      file {
                        ... on downloads_Asset {
                          id
                          url
                        }
                      }
                      externalUrl
                    }
                  }
                  galleryImages {
                    ... on productImages_Asset {
                      id
                      url
                      title
                      displayTitle
                    }
                  }
                  contentTabs {
                    ... on contentTabs_BlockType {
                      id
                      video {
                        id
                        url
                        mimeType
                        size
                      }
                      galleryimages {
                        id
                        url
                        title
                      }
                      contentTab
                      titleTab
                    }
                  }
                  bottomContent {
                    ... on bottomContent_BlockType {
                      id
                      contentMainTitle
                      mainContent
                      showMainTitle
                      showSubTitle
                      video {
                        ... on videos_Asset {
                          id
                          url
                        }
                      }
                      subTitle
                    }
                  }
                  topContent {
                    ... on topContent_BlockType {
                      id
                      contentMainTitle
                      mainContent
                      showMainTitle
                      showSubTitle
                      video {
                        ... on videos_Asset {
                          id
                          url
                        }
                      }
                      subTitle
                    }
                  }
                }
              }
            }

      `)
        .then((data) => {
            setServiceEntry(data.servicesEntries);
        });
    }, [location])

  return (

    <AnimationRevealPage>
 {
        (entries || []).map((serviceEntry, index) =>
<>
            <Hero
                  heading={<HeroSmallHeading>{serviceEntry.title}</HeroSmallHeading>}
                  smallHeading={<></>}
                  breadcrumbType={<>Services</>}
                  breadcrumbName={serviceEntry.title}
                  children={<></>}
                  iconSrc={serviceEntry.heroIcon}
                  iconAlt={serviceEntry.title}
                  imageSrc={serviceEntry.heroImage}
                  imageCss={imageCss}

            />


                <KeyBenefits
                                 key={index}
                                 className="keyBenefits"
                                 heading={serviceEntry.keyBenefits[0].keyBenefitsTitle !== null ? serviceEntry.keyBenefits[0].keyBenefitsTitle : "Key Benefits"}
                                 contents={serviceEntry.keyBenefits}

                    />
                {
                    (serviceEntry.topContent || []).map((topContent, index) =>
                        <>
                            <ContentWithVideo
                                key={index}
                                contentMainTitle={topContent.contentMainTitle}
                                mainContent={topContent.mainContent}
                                showMainTitle={topContent.showMainTitle}
                                showSubTitle={topContent.showSubTitle}
                                subTitle={topContent.subTitle}
                                video={topContent.video}

                            />
                        </>
                    )
                }
            
            <ContentContainer>
                <Content
                    tabs={serviceEntry.contentTabs}
                />
            </ContentContainer>
            {
                (serviceEntry.bottomContent || []).map((bottomContent, index) =>
                    <>
                        <ContentWithVideo
                            key={index}
                            contentMainTitle={bottomContent.contentMainTitle}
                            mainContent={bottomContent.mainContent}
                            showMainTitle={bottomContent.showMainTitle}
                            showSubTitle={bottomContent.showSubTitle}
                            subTitle={bottomContent.subTitle}
                            video={bottomContent.video}

                        />
                    </>
                )
            }
           
           
            {serviceEntry.galleryImages.length > 0 &&
                        <>
                           <PhotoGallery
                                key={index}
                                galleryImages={serviceEntry.galleryImages}

                            />
                            <HR/>
                        </>
                    }
            <Downloads
                            key={index}
                            downloads={serviceEntry.downloads}
                        />
 </>
 )}
     
              <CommonContent
                  showCompanies={false}
                  showServices={false}
              />

      <Footer />
    </AnimationRevealPage>
  );
}
