import React from "react";
import tw from "twin.macro";
// import { ReadMoreToggler } from 'read-more-read-less-toggler';
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, SmallHeading as SmallHeadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import SolutionImageSrc from "images/solution-1.png";
import { Description } from "components/misc/Typography.js";
// import TriangleDecoration from "images/purple-triangle.svg";
// import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
// import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
// import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

const Container = tw.div`relative pr-10 pl-10`;
const TwoColumn = tw.div`py-0 md:justify-between max-w-screen-xl mx-auto py-0 pb-0 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  // tw`md:w-6/12 mt-16 md:mt-0`,
  // props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
],
    `
    padding: 15px;
    `
);

const TextContent = tw.div`lg:py-8 text-left md:text-left`;
const SmallHeading = tw(SmallHeadingBase)`text-left text-lg md:text-left`;
const Subdescription = tw.p`mt-8 text-left md:text-left text-sm md:text-base lg:text-lg font-medium leading-normal text-gray-700`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-2xl sm:text-2xl lg:text-2xl text-left md:text-left leading-tight tracking-tight font-almostbold`;
// const ReadMoreToggler = tw.div`relative pr-10 pl-10 pt-0 pb-0`;

export default ({
  smallHeading = "Our Values and Commitment",
  subdescription = "We, based on our total commitment to people and to the future, are determined to help create a higher quality of life for all the people, and to do our part to help ensure that progress continues within the world community.",
  heading = (
    <Heading id="future">
     Our Future Direction
    </Heading>
  ),
  description = "The regional and export business continues to expand from the Oceania region and beyond. In resposnse to customers needs, TIC is developing its capabilities to add and support a competitive portfolio of products, systems and solutions. TIC will continue to innovate and cultivate products and systems that allow us to provide long term solutions and services that enhance the value of the customer's business.",
  primaryButtonText = "Read More",
  primaryButtonUrl = "#",
  imageSrc = SolutionImageSrc,
  buttonRounded = false,
  imageRounded = false,
  imageBorder = false,
  imageShadow = false,
  showDecoratorBlob = true,
  textOnLeft = false,
  features = null,
  iconRoundedFull = true,
  iconFilled = true,
  iconContainerCss = null
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.


  return (
    <Container id="future">
      <TwoColumn>
        <TextColumn>
          <TextContent>
            <Heading>{heading}</Heading>
            {description &&
                    <Description dangerouslySetInnerHTML={{__html: description}}/>
            }<br/>
            {/* <SmallHeading>{smallHeading}</SmallHeading> */}
            {/* <Subdescription>{subdescription}</Subdescription> */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};