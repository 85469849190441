import tw from "twin.macro";
import styled from "styled-components";

export const Container = tw.div`relative`;
export const ContentWithPaddingXl= tw.div`max-w-screen-xl mx-auto py-20 lg:py-10`;
export const ContentWithPaddingLg= tw.div`max-w-screen-lg mx-auto py-10 lg:py-10`;
export const ContentWithVerticalPadding = tw.div`py-20 lg:py-24`;
export const Content2Xl= tw.div`max-w-screen-2xl mx-auto`;
export const HR = styled.hr(props => [
    `border-top: 1px dotted red; width: 80%; margin: auto; margin-top: 2rem; margin-bottom: 0rem;`,
    tw``
]);