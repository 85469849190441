import React, { useEffect, useState  } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import {SectionHeading, HeroHeading as HeroHeadingBase, HeroSmallHeading as HeroSmallHeadingBase} from "components/misc/Headings";
import Hero from "components/hero/TwoColumnWithImage.js";
import HeroDetails from "components/features/TwoColSimple.js";
import Solutions from "components/features/TwoColSolutions.js";
import Products from "components/features/ThreeColSliderProducts.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import CommonContent from "./commonContent.js";
import GraphQlHandler from "api/craft/GraphQlHandler";

export default () => {
  const [entries, setHomePageEntry] = useState([]);
  const SmallHeading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`bg-transparent`;
  // const Services = tw`bg-blue-100`;
    const HeroHeading = tw(
        HeroHeadingBase
    )`pt-4 text-3xl md:text-5xl leading-extratight max-w-md
`;
    const HeroSmallHeading = tw(
        HeroSmallHeadingBase
    )`pt-4 text-3xl md:text-5xl leading-extratight max-w-xl
`;
    const Heading = tw(
        SectionHeading
    )`pt-4 text-3xl text-red-600 md:text-5xl leading-extratight max-w-xl

`;


    useEffect(() => {
      GraphQlHandler.query(`
      query MyQuery {
          entries(slug: "homepage") {
            ... on homepage_homepage_Entry {
              id
              subHeading
              mainDescription 
              heading
              heroImage {
                id
                url
              }
              heroIcon {
                    slug
                    title
                    ... on icons_Asset {
                      id
                      url
                    }
                  }
              homeKeyPoints {
                ... on homeKeyPoints_BlockType {
                  id
                  heading
                  blurb
                  icon {
                    ... on icons_Asset {
                      id
                      url
                    }
                  }
                }
              }
            }
          }
        }

      `)
      .then((data) => {
          setHomePageEntry(data.entries);
      });
    }, [])

  const mainFeatures = <HeroDetails
      buttonRounded={false}
      textOnLeft={false}
      cards={
              (entries || []).map((homePageEntry) =>
                      homePageEntry.homeKeyPoints
                  )
            }
  />
  return (

    <AnimationRevealPage>
        {
            (entries || []).map((homePageEntry, index) =>
                <>
                  <Hero
                    key={index}
                    heading={<HeroHeading>{homePageEntry.heading}<HeroSmallHeading>{homePageEntry.subHeading}</HeroSmallHeading></HeroHeading>}
                    smallHeading={<></>}
                    heroExtraDetails={mainFeatures}
                    imageSrc={homePageEntry.heroImage}
                    imageCss={imageCss}
                    iconSrc={homePageEntry.heroIcon}
                    largeHeader={false}
                  />


                  
                    <Solutions
                        key={index}

                    />



                </>
            )
        }
        <CommonContent/>
      <Footer />
    </AnimationRevealPage>
  );
}
