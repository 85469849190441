import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Description } from "components/misc/Typography.js";

const Container = tw.div`relative pr-10 pl-10`;
const TwoColumn = tw.div`py-0 md:justify-between max-w-screen-xl mx-auto py-0 pb-0 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)(props => [
]);


const TextContent = tw.div`lg:py-8 text-center md:text-left`;

export default ({
   description = ""
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.


  return (
    <Container>
      <TwoColumn>
        <TextColumn>
          <TextContent>
            {description &&
                    <Description dangerouslySetInnerHTML={{__html: description}}/>
            }<br/>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};