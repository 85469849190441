import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import GraphQlHandler from "api/craft/GraphQlHandler";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithImage.js";
import Solutions from "components/features/TwoColSolutions.js";
import KeyBenefits from "components/features/OneColKeyCommonBenefits.js";
import { useLocation } from 'react-router-dom';
import Content from "components/cards/TabCardGridCommonContent.js";
import PhotoGallery from "components/features/FourColSliderProductsGallery.js";
import Downloads from "components/features/MultiColumnProductDownloads.js";
import ContentWithVideo from "components/features/TwoColContentWithVideo.js";
import Footer from "components/footers/FiveColumnWithBackground.js";

import styled from "styled-components";
import {HeroHeading as HeroHeadingBase, HeroSmallHeading as HeroSmallHeadingBase} from "./components/misc/Headings";
import { HR as HRBase } from "./components/misc/Layouts";
import CommonContent from "./commonContent";
import InfoRows from "./components/features/VerticalRowsWithInfoProducts";

const HR = tw(HRBase)``;
const HeroHeading = tw(
    HeroHeadingBase
)`pt-4 text-3xl md:text-5xl leading-extratight max-w-md
`;
const HeroSmallHeading = tw(
    HeroSmallHeadingBase
)`pt-4 text-3xl md:text-5xl leading-extratight max-w-xl
`;
export default () => {
  const location = useLocation();
  const [entries, setProductEntry] = useState([]);
  const [globals, setGlobals] = useState([]);
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw``;
  const subPage = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1) !== "" ?
    true : false
  ;
  const slug = subPage ?
    window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1) : "*"
  ;
  useEffect(() => {
    GraphQlHandler.query(`
      query MyQuery {
            entries(slug: "${slug}") {
                ... on product_products_Entry {
                  id
                  title
                  treatAsCategory
                  infoRows {
                    ... on infoRows_BlockType {
                      id
                      description
                      displaytitle
                      image {
                        ... on productImages_Asset {
                          id
                          url
                        }
                      }
                      linktocontent {
                        id
                        uri
                      }
                      externalLink
                    }
                  }
                  infoRowsHeading: heading
                  productCategory {
                    title
                    id
                    uri
                    parent {
                      id
                      title
                      uri
                    }
                  }
                  
                  contentTabs(title: "") {
                    ... on contentTabs_BlockType {
                      id
                      video {
                        id
                        url
                        mimeType
                        size
                      }
                      galleryimages {
                        id
                        url
                        title
                      }
                      contentTab
                      titleTab
                    }
                  }
                  keyBenefits {
                    ... on keyBenefits_BlockType {
                      id
                      keyBenefitContent
                      showTitle
                      keyBenefitsTitle
                    }
                  }
                  downloads {
                    ... on downloads_BlockType {
                      id
                      downloadType
                      description
                      buttonToggle
                      file {
                        ... on downloads_Asset {
                          id
                          url
                        }
                      }
                      externalUrl
                    }
                  }
                  heroImage {
                      id
                      url
                  }
                  galleryImages {
                    ... on productImages_Asset {
                      id
                      url
                      title
                      displayTitle
                    }
                  }
                  heroIcon {
                    id
                    url
                  }
                  bottomContent {
                    ... on bottomContent_BlockType {
                      id
                      contentMainTitle
                      mainContent
                      showMainTitle
                      showSubTitle
                      video {
                        ... on videos_Asset {
                          id
                          url
                        }
                      }
                      subTitle
                    }
                  }
                  topContent {
                    ... on topContent_BlockType {
                      id
                      contentMainTitle
                      mainContent
                      showMainTitle
                      showSubTitle
                      video {
                        ... on videos_Asset {
                          id
                          url
                        }
                      }
                      subTitle
                    }
                  }
                  showSolutions
                }
              }
              globalSets(handle: "general") {
                ... on general_GlobalSet {
                  contentTabs{
                    ... on contentTabs_BlockType {
                      id
                      video {
                        id
                        url
                        mimeType
                        size
                      }
                      galleryimages {
                        url
                      }
                      contentTab
                      titleTab
                    }
                  }
                }
              } 
            }

    `)
        .then((data) => {
            setProductEntry(data.entries);
            setGlobals(data.globalSets);
        });


  }, [location])
  return (

    <AnimationRevealPage>
        {
        (entries || []).map((productEntry, index) =>


                <>

                    <Hero
                          key={index}
                          heading={<HeroSmallHeading>{productEntry.title}</HeroSmallHeading>}
                          smallHeading={<></>}
                          breadcrumbType={<>Products</>}
                          breadcrumbName={productEntry.treatAsCategory === "yes" ? productEntry.title : productEntry.productCategory[0].title}
                          parent={productEntry.treatAsCategory === "yes" ? "" : productEntry.productCategory[0].parent}
                          children={<></>}
                          iconSrc={productEntry.heroIcon}
                          iconAlt={<>Transmission Products</>}
                          imageSrc={productEntry.heroImage}
                          imageCss={imageCss}
                          reduceHeroSize={true}

                    />

                    <KeyBenefits
                          key={index}
                          className="keyBenefits"
                          heading={productEntry.keyBenefits[0].keyBenefitsTitle !== null ? productEntry.keyBenefits[0].keyBenefitsTitle : "Key Benefits"}
                          contents={productEntry.keyBenefits}
                    />

                    {productEntry.downloads && 
                      <Downloads
                            key={index}
                            downloads={productEntry.downloads}
                            slug={slug}
                            tag={productEntry.title}                          
                        />                    
                    }
                    
                    {
                        (productEntry.topContent || []).map((topContent, index) =>
                        <>
                            <ContentWithVideo
                                key={index}
                                contentMainTitle={topContent.contentMainTitle}
                                mainContent={topContent.mainContent}
                                showMainTitle={topContent.showMainTitle}
                                showSubTitle={topContent.showSubTitle}
                                subTitle={topContent.subTitle}
                                video={topContent.video}

                            />
                        </>
                        )
                    }
                                       
                    <HR/>

                    {productEntry.contentTabs &&
                        <Content
                            key={index}
                            tabs={productEntry.contentTabs}

                        />
                    }
                     {productEntry.infoRows &&
                        <InfoRows
                            key={index}
                            information={productEntry.infoRows}
                            heading={productEntry.infoRowsHeading}
                            linkText={<>Find Out More</>}
                        />
                    }
                    {
                        (productEntry.bottomContent || []).map((bottomContent, index) =>
                            <>
                                <ContentWithVideo
                                    key={index}
                                    contentMainTitle={bottomContent.contentMainTitle}
                                    mainContent={bottomContent.mainContent}
                                    showMainTitle={bottomContent.showMainTitle}
                                    showSubTitle={bottomContent.showSubTitle}
                                    subTitle={bottomContent.subTitle}
                                    video={bottomContent.video}

                                />
                            </>
                        )
                    }
                    <HR/>
                    { productEntry.showSolutions && productEntry.showSolutions == "yes" &&
                        (globals || []).map((global, index) =>
                            <>
                                <Content
                                    heading={"Solutions"}
                                    tabs={global.contentTabs}
                                    contentId={"solutions"}
                                    description = "Innovative thinking is in our DNA. Since our inception, our engineers and sales teams have proactively worked to understand our customers needs and develop tailored technology solutions fit for Australia's unique environment.<br/>  Our commitment is to develop and innovate, to support our clients by delivering solutions that will continue to power Australian industry."
                                />
                                <HR/>
                            </>
                        )
                    }
                    {productEntry.galleryImages.length > 0 &&
                        <>
                           <PhotoGallery
                                key={index}
                                galleryImages={productEntry.galleryImages}

                            />
                            <HR/>
                        </>
                    }


                    
                </>
             )
         }
         <>
              
              {/* <HR/><Solutions

              /> */}
              <CommonContent
                 showCompanies={false}
                 showProducts={false}
              />
         </>
          <Footer />
    </AnimationRevealPage>
  );
}
