import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import GraphQlHandler from "api/craft/GraphQlHandler";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithImage.js";
import KeyBenefits from "components/features/OneColKeyCommonBenefits.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import styled from "styled-components";
import { SectionDescription } from "components/misc/Typography.js";
import {HeroHeading as HeroHeadingBase, HeroSmallHeading as HeroSmallHeadingBase} from "./components/misc/Headings";
import { HR as HRBase } from "./components/misc/Layouts";
import {PrimaryButton as PrimaryButtonBase} from "./components/misc/Buttons";
import { customContentStyles } from "components/misc/Typography.js"

const HR = tw(HRBase)``;
const HeroHeading = tw(
    HeroHeadingBase
)`pt-4 text-3xl md:text-5xl leading-extratight max-w-md
`;
const HeroSmallHeading = tw(
    HeroSmallHeadingBase
)`pt-4 text-3xl md:text-5xl leading-extratight max-w-md
`;
const TextInfo = tw.div`py-6 px-6 sm:px-10 sm:py-6`;
const TitleContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold text-primary-500`;
const Card = styled.a(props => [
    tw`h-full sm:w-full relative focus:outline-none mx-3 my-3 pt-0 md:mx-auto w-full`,
    ``
]);
const CardButton = tw(PrimaryButtonBase)`w-full items-center text-lg uppercase mt-10 text-primary-500 bg-white border-solid border-4 border-primary-500`;
const Description = styled.p`
    ${tw`text-sm leading-normal mt-2 sm:mt-4 text-gray-700`}
    ul,ol {
        list-style: disc;
        margin: 11px;
        margin-left: 33px;
        padding-left: 2rem;
    }
    ${customContentStyles}
`;
const CommonContent = tw(Description)``;

const Remuneration = styled.p`
    ${tw`text-sm leading-normal mt-2 sm:mt-4 text-gray-700`}
`;
const PositionType = styled.p`
    ${tw`text-sm leading-normal mt-2 sm:mt-4 text-gray-700`}
`;
const Location = styled.p`
    ${tw`text-sm leading-normal mt-2 sm:mt-4 text-gray-700`}
`;
const Department = styled.p`
    ${tw`text-sm leading-normal mt-2 sm:mt-4 text-gray-700`}
`;
const PositionCategory = styled.p`
    ${tw`text-sm leading-normal mt-2 sm:mt-4 text-gray-700`}
`;
const CareerVideo = tw.div`pt-10 mx-auto`;
const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
const imageCss = tw``;
const Container = tw.div`relative p-10 pt-0 mx-auto`;
const Content = tw.div`max-w-screen-xl lg:pt-0 mx-auto`;

export default ({
    linkText = "Apply Now",
}) => {
    const [jobsMain, setMainEntry] = useState([]);
    const [jobs, setJobEntry] = useState([]);
    const [globals, setGlobals] = useState([]);
    useEffect(() => {
        GraphQlHandler.query(`
              query MyQuery {
                entries(slug: "`+window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)+`") {
                  ... on careers_careers_Entry {
                      id
                      department {
                        ... on careersDepartment_default_Entry {
                          id
                          title
                        }
                      }
                      pageStatus
                      seekLink
                      mainDescription
                      slug
                      title
                      remuneration
                      showVideo
                      keyBenefits {
                        ... on keyBenefits_BlockType {
                          id
                          keyBenefitContent
                          keyBenefitsTitle
                        }
                      }
                      category {
                        ... on careersCategories_default_Entry {
                          id
                          title
                          subHeading
                        }
                      }
                      location {
                        ... on careersLocations_default_Entry {
                          id
                          title
                        }
                      }
                      positionType {
                        ... on careersPositionType_default_Entry {
                          id
                          title
                        }
                      }
                    }
                }
                careersCLPEntries {
                    ... on careersCLP_careersCLP_Entry {
                      commonContent : mainDescription
                      commonHighlight : shortDescription
                      heroImage {
                        url
                      }
                      heroIcon {
                        url
                      }
                       }
                  }
                  globalSets (handle: "careers") {
                    ... on careers_GlobalSet {
                      id
                      careersVideo
                    }
                  }
                }
            `)
            .then((data) => {
                setMainEntry(data.careersCLPEntries);
                setJobEntry(data.entries);
                setGlobals(data.globalSets);
            });


    }, [])
    return (

    <AnimationRevealPage>
        {
        (jobs || []).map((jobEntry) =>


                <>

                    <Hero
                          heading={<HeroSmallHeading>{jobEntry.title}</HeroSmallHeading>}
                          smallHeading={<></>}
                          breadcrumbType={<>Jobs</>}
                          breadcrumbName={jobEntry.title}
                          children={<></>}
                          iconSrc={jobsMain[0].heroIcon}
                          iconAlt={<>Careers</>}
                          imageSrc={jobsMain[0].heroImage}
                          imageCss={imageCss}

                    />


                    <KeyBenefits className="keyBenefits"
                                 heading={jobEntry.keyBenefits[0].keyBenefitsTitle !== null ? jobEntry.keyBenefits[0].keyBenefitsTitle : "Our Company"}
                                 contents={[{keyBenefitContent : jobsMain[0].commonHighlight, showTitle: "true"}]}

                    />
                    <Container>
                        <Content>
                            <TextInfo className="xs-version">
                                <TitleContainer>
                                    <Title>{jobEntry.title}</Title>
                                </TitleContainer>
                                {jobEntry.department[0] &&
                                    <Department>Department: {jobEntry.department[0].title}</Department>
                                }
                                {jobEntry.remuneration &&
                                    <Remuneration>Remuneration: {jobEntry.remuneration}</Remuneration>
                                }
                                {jobEntry.positionType[0] &&
                                    <PositionType>Job Type: {jobEntry.positionType[0].title}</PositionType>
                                }
                                {jobEntry.location[0] &&
                                    <Location>Location: {jobEntry.location[0].title}</Location>
                                }
                                {jobEntry.category[0] &&
                                    <PositionCategory>Category: {jobEntry.category[0].title} {jobEntry.category[0].subHeading}</PositionCategory>
                                }
                                <Description dangerouslySetInnerHTML={{__html: jobEntry.mainDescription}}></Description>
                                <CommonContent  dangerouslySetInnerHTML={{__html: jobsMain[0].commonContent}}></CommonContent>

                                {jobEntry.showVideo == "true" &&
                                    <CareerVideo>{
                                        globals.map((video) => (
                                            <iframe
                                                src={video.careersVideo}
                                                frameborder="0"
                                                allow="autoplay; encrypted-media"
                                                allowfullscreen
                                                title="video"
                                                width={'100%'}
                                                height={'450px'}
                                            />
                                          ))
                                    }</CareerVideo>

                                }
                                <Card href={jobEntry.seekLink} target="_blank">
                                    <CardButton>{linkText}</CardButton>
                                </Card>
                            </TextInfo>
                        </Content>
                    </Container>

                </>
             )
         }

          <Footer />
    </AnimationRevealPage>
  );
}
