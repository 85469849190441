import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import GraphQlHandler from "api/craft/GraphQlHandler";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithImage.js";
import Solutions from "components/features/TwoColSolutions.js";
import Jobs from "components/features/RowsJobs.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import FilterDropdown from "./components/misc/filterDropdowns.js";
import CareersSections from "components/features/ThreeColCategory.js";
import TalentNetwork from "components/forms/TalentNetworkForm";
import { useLocation } from 'react-router-dom';
import styled from "styled-components";
import {
    HeroHeading as HeroHeadingBase,
    HeroSmallHeading as HeroSmallHeadingBase,
    SectionHeading
} from "./components/misc/Headings";
import { HR as HRBase } from "./components/misc/Layouts";
import KeyBenefits from "./components/features/OneColKeyCommonBenefits";
import CommonContent from "./commonContent";

const HR = tw(HRBase)``;
const Heading = tw(SectionHeading)`text-3xl`;
const HeroHeading = tw(
    HeroHeadingBase
)`pt-4 text-3xl md:text-5xl leading-extratight max-w-md
`;
const HeroSmallHeading = tw(
    HeroSmallHeadingBase
)`pt-4 text-3xl md:text-5xl leading-extratight max-w-md
`;
const CardFilter = tw(SectionHeading)`pt-10 text-left lg:p-12 lg:text-center`;

export default () => {
  const [entries, setMainEntry] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [locations, setLocations] = useState([]);
  const [positionTypes, setPositionTypes] = useState([]);
  const [departmentSelected, setDepartmentSelected] = useState("");
  const [locationSelected, setLocationSelected] = useState("");
  const [sectionSelected, setSectionSelected] = useState("");
  const [positionTypeSelected, setPositionTypeSelected] = useState("");
  const [jobs, setJobsEntry] = useState([]);
  const [careerSection, setCareerSection] = useState([]);
  const location = useLocation();
  const filterDepartment = (event) => {
       setDepartmentSelected(event.target.value !== '*' ? ", department: \""+event.target.value+"\"" : "");
  };
  const filterLocations = (event) => {
      setLocationSelected(event.target.value !== '*' ? ", location: \""+event.target.value+"\"" : "");
  };
  const filterPositionTypes = (event) => {
      setPositionTypeSelected(event.target.value !== '*' ? ", positionType: \""+event.target.value+"\"" : "");
  };

  const SmallHeading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw``;
  const subPage = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1) !== "" ?
      true : false
  ;
  const slug = subPage ?
      window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1) : "*"
  ;
  const checkSectionFromSlug = (careerSection) => {
      console.log(careerSection);
      {careerSection.slug === slug &&
          setSectionSelected(", careerSection: \"" + careerSection.id + "\"");
      }
  };
  const level = subPage ? "" : ", level: 1"; // If its a subpage we want to get level 2, but if top level only get 1
    /*entries(section: "careers", department: "2301") {*/
    useEffect(() => {
        GraphQlHandler.query(`
            query MyQuery {
              section : careersSectionEntries {
                ... on careersSection_careerSection_Entry {
                  id
                  title
                  uri
                  slug
                  mainDescription
                }
              }
            }

    `)
            .then((data) => {
                setCareerSection(data.section);
                console.log("CAREER",data.section);
                {(data.section || []).map((careerSection) =>
                    checkSectionFromSlug(careerSection)
                )}
            });
    }, [location])

  useEffect(() => {
    GraphQlHandler.query(`
        query MyQuery {
          jobs: entries(section: "careers" ${departmentSelected} ${locationSelected} ${positionTypeSelected} ${sectionSelected}) {
            ... on careers_careers_Entry {
              id
              department {
                ... on careersDepartment_default_Entry {
                  id
                  title
                }
              }
              pageStatus
              seekLink
              shortDescription
              slug
              title
              remuneration
              category {
                ... on careersCategories_default_Entry {
                  id
                  title
                  subHeading
                }
              }
              location {
                ... on careersLocations_default_Entry {
                  id
                  title
                }
              }
              positionType {
                ... on careersPositionType_default_Entry {
                  id
                  title
                }
              }
              careerSection {
                ... on careersSection_careerSection_Entry {
                  id
                  title
                }
              }
            }
          }
        }




    `)
    .then((data) => {
        setJobsEntry(data.jobs);
    });
  }, [departmentSelected, locationSelected, positionTypeSelected, sectionSelected])

    useEffect(() => {
        GraphQlHandler.query(`
        query MyQuery {
          careersCLPEntries {
            ... on careersCLP_careersCLP_Entry {
              id
              title
              subHeading
              keyBenefits {
                ... on keyBenefits_BlockType {
                  id
                  keyBenefitContent
                  showTitle
                  keyBenefitsTitle
                }
              }
              heroImage {
                url
              }
              heroIcon {
                url
              }
              heading
            }
          }
          departments: careersDepartmentEntries {
            ... on careersDepartment_default_Entry {
              id
              title
            }
          }
          locations: careersLocationsEntries {
            ... on careersLocations_default_Entry {
              id
              title
            }
          }
          positionTypes: careersPositionTypeEntries {
            ... on careersPositionType_default_Entry {
              id
              title
            }
          }
        }




    `)
            .then((data) => {
                setMainEntry(data.careersCLPEntries);
                setDepartments(data.departments);
                setLocations(data.locations);
                setPositionTypes(data.positionTypes);
            });
    }, [])
  return (

    <AnimationRevealPage>
        {
            (entries || []).map((careerEntry) =>
                <>
                  <Hero
                    heading={<HeroSmallHeading>{subPage ? careerEntry.heading : "Careers"}</HeroSmallHeading>}
                    smallHeading={<></>}
                    breadcrumbType={<>Careers</>}
                    breadcrumbName={subPage ? careerEntry.heading : "All"}
                    description={subPage ? careerEntry.mainDescription : "View all job opportunities below:"}
                    iconSrc={careerEntry.heroIcon}
                    iconAlt={<>{careerEntry.heading}</>}
                    imageSrc={careerEntry.heroImage}
                    imageCss={imageCss}

                  />
                    <KeyBenefits className="keyBenefits"
                                 heading={careerEntry.keyBenefits[0].keyBenefitsTitle !== null ? careerEntry.keyBenefits[0].keyBenefitsTitle : "Key Benefits"}
                                 contents={careerEntry.keyBenefits}

                    />
                    {subPage && slug !== "talent-network" &&
                        <CardFilter>
                            <FilterDropdown
                                filterList={departments}
                                heading={<>Department</>}
                                showHeading={true}
                                onFilterChange={filterDepartment}
                            />
                            <FilterDropdown
                                filterList={locations}
                                heading={<>Location</>}
                                showHeading={true}
                                listName={"locationsFilter"}
                                onFilterChange={filterLocations}
                            />
                            <FilterDropdown
                                filterList={positionTypes}
                                heading={<>Position Type</>}
                                showHeading={true}
                                listName={"positionTypeFilter"}
                                onFilterChange={filterPositionTypes}
                            />
                        </CardFilter>
                    }
                </>
            )
        }
        {subPage && slug !== "talent-network" &&
            <Jobs
                heading={
                    <Heading>
                        Career Opportunities
                    </Heading>
                }
                cards={jobs}
                linkText={<>View Role</>}
            />
        }
        {!subPage &&

            <CareersSections
                cards={careerSection}
                heading={"Select"}
                showHeading={true}
                linkText={<>Select</>}
                titleAsLink={true}
            >
            </CareersSections>

        }
        {subPage && slug === "talent-network" &&
            (careerSection || []).map((section) =>
                (section.slug === "talent-network" &&
                <TalentNetwork
                heading={<>Talent Network</>}
                    description={section.mainDescription}
                    locations={locations}
                    departments={departments}
                />
                )
            )
        }
        <HR/>
        <Solutions

        />

        <CommonContent
            showCompanies={false}
        />

      <Footer />
    </AnimationRevealPage>
  );
}