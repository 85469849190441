import React from "react";
import tw from "twin.macro";
// import { ReadMoreToggler } from 'read-more-read-less-toggler';
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, SmallHeading as SmallHeadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Description } from "components/misc/Typography.js";
import TriangleDecoration from "images/purple-triangle.svg";
import CardTriangleDecoration from "images/decorator-triangle.svg";
import CardTriangleDecorationone from "images/Creating.png";
import CardTriangleDecorationtwo from "images/Integrity.png";
import CardTriangleDecorationthree from "images/Teamwork.png";
import CardTriangleDecorationfour from "images/Committed.png";
import { ContentWithPaddingXl } from "components/misc/Layouts.js";
import Product1 from "images/product-1.png";
import Product2 from "images/product-2.png";
import Product3 from "images/product-3.png";
import Decorator from "images/decorator-triangle.svg";

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-xl ml-6 lg:ml-0 xl:ml-0 font-bold text-black 2xl:min-h-20`;
const Ruler = tw.div`pl-4 ml-8 mt-5 lg:mt-0 lg:ml-0 xl:mt-0 xl:ml-0 sm:mt-0 sm:ml-0 md:mt-0 md:ml-0 border-l border-black`;

const SubSectionCard = styled.div(props => [
  tw`h-full p-4 !w-full lg:relative md:max-w-none sm:max-w-none 2xl:max-w-xxs ml-0 p-0 focus:outline-none mx-3 pt-0 xl:mr-5`,
  `box-shadow: 0px 3px 6px #00000029;
  display: inline-block;
  @media screen and (max-width: 1280px) {
    width: 100% !important;
    }`
]);

const SubSectionDescription = styled.p`
    ${tw`text-sm leading-normal mt-2 sm:mt-4 text-gray-700 2xl:min-h-56 mr-6 lg:mr-0 xl:mr-0 ml-6 lg:ml-0 xl:ml-0`}
`;

const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;
const CardButton = tw(PrimaryButtonBase)`w-full items-center text-lg uppercase mt-10 text-primary-500 bg-white border-solid border-4 border-primary-500`;

const ThreeColumnContainer = styled.div`
  ${tw`flex items-center mx-auto flex flex-wrap`}
`;

const Container = tw.div`relative pr-10 pl-10 sm:pt-0 lg:pt-0 md:pt-0`;
const SubContainer = tw.div`relative sm:pt-0 lg:pt-0 md:pt-0`;
const TwoColumn = tw.div`flex flex-col py-0 xl:flex-row justify-between max-w-screen-xl mx-auto py-0 md:pt-2 pb-0`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0 pb-10`;
const ImageColumn = tw(Column)`xl:w-6/12 flex-shrink-0 relative md:pb-10`;
const TextColumn = styled(Column)(props => [
  tw`xl:w-6/12 mt-16 md:mt-0`,
]);


const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
],
    `
    padding: 15px;
    @media screen and (max-width: 1200px) {
      padding: 0px;
    }
    `
);

const DecoratorBlob = styled.div(props => [
  `
  background-image: url("${TriangleDecoration}");
  left: -22px;
  top: -22px;
  width: 124px;
  height: 124px;
  @media screen and (max-width: 480px) {
  left: 0px;
  }
  `,
  tw`absolute fill-current text-primary-500 z-10`,
]);

const CardDecoratorBlob = styled.div(props => [
  `
  background-image: url("${CardTriangleDecoration}");
    // left: 558px;
    top: 71px;
    width: 3.4rem;
    height: 7rem;
    background-size: contain;
    position: absolute;
    fill: currentColor;
    --tw-text-opacity: 1;
    color: rgba(230,30,30,var(--tw-text-opacity));
    z-index: 10;
    transform: rotate(180deg);

  
  @media screen and (max-width: 1440px) {
    right: 0;
    display: none;
    }
    
    @media screen and (min-width: 1010px) and (max-width: 1313px) {
      display: none;
    }
  `,
  tw`absolute fill-current text-primary-500 z-10`,
]);

const CardDecoratorBlobOne = styled.div(props => [
  `
  background-image: url("${CardTriangleDecorationone}");
    // left: 558px;
    top: 220px;
    width: 1.5rem;
    image-rendering: pixelated;
    height: 187px;
    background-size: contain;
    position: absolute;
    fill: currentColor;
    --tw-text-opacity: 1;
    color: rgba(230,30,30,var(--tw-text-opacity));
    z-index: 10;
   
      @media screen and (max-width: 1440px) {
        right: 0;
        top: 220px;
        transform: rotate(180deg);
        display: none;
        }
        @media screen and (min-width: 1010px) and (max-width: 1313px) {
          display: none;
        }
        @media screen and (max-width: 450px) {
          // top: 310px;
          }
  `,
  tw`absolute fill-current text-primary-500 z-10`,
]);

const CardDecoratorBlobTwo = styled.div(props => [
  `
  background-image: url("${CardTriangleDecorationtwo}");
    // left: 558px;
    top: 140px;
    width: 3.4rem;
    height: 6.7rem;
    background-size: contain;
    position: absolute;
    fill: currentColor;
    --tw-text-opacity: 1;
    color: rgba(230,30,30,var(--tw-text-opacity));
    z-index: 10;
    transform: rotate(180deg);

 
  @media screen and (max-width: 1440px) {
    right: 0;
    top: 360px;
    transform: rotate(180deg);
    display: none;
    }
    @media screen and (min-width: 1010px) and (max-width: 1313px) {
      display: none;
    }
    @media screen and (max-width: 450px) {
     top: 480px;
      }
  `,
  tw`absolute fill-current text-primary-500 z-10`,
]);

const CardDecoratorBlobThree = styled.div(props => [
  `
  background-image: url("${CardTriangleDecorationthree}");
    // left: 558px;
    top: 541px;
    width: 3rem;
    height: 1.4rem;
    background-size: contain;
    position: absolute;
    fill: currentColor;
    --tw-text-opacity: 1;
    color: rgba(230,30,30,var(--tw-text-opacity));
    z-index: 10;
  

  @media screen and (max-width: 1440px) {
    right: 0;
    top: 500px;
    transform: rotate(180deg);
    display: none;
    }
    @media screen and (min-width: 1010px) and (max-width: 1313px) {
      display: none;
    }
    @media screen and (max-width: 450px) {
      top: 640px;
       }
  `,
  tw`absolute fill-current text-primary-500 z-10`,
]);

const CardDecoratorBlobFour = styled.div(props => [
  `
  background-image: url("${CardTriangleDecorationfour}");
    // left: 558px;
    top: 280px;
    width: 25px;
    height: 11rem;
    background-size: contain;
    position: absolute;
    fill: currentColor;
    image-rendering: pixelated;
    --tw-text-opacity: 1;
    color: rgba(230,30,30,var(--tw-text-opacity));
    z-index: 10;
    
  @media screen and (max-width: 1440px) {
    right: 0;
    top: 640px;
    transform: rotate(180deg);
    display: none;
    }
    @media screen and (min-width: 1010px) and (max-width: 1313px) {
      display: none;
    }
       @media screen and (max-width: 450px) {
        top: 860px;
         }
  `,
  tw`absolute fill-current text-primary-500 z-10`,
]);

const TextContent = tw.div`pr-10 lg:py-8 text-left md:text-left`;
const SubSectionSmallHeading = tw(SmallHeadingBase)`text-left text-sm md:text-left 2xl:min-h-20 ml-6 lg:ml-0 xl:ml-0`;
const Subdescription = tw.p`mt-8 text-left md:text-left text-sm md:text-sm lg:text-sm font-medium leading-normal text-gray-700`;
const Heading = tw(
  SectionHeading
)`font-black text-left text-2xl sm:text-2xl lg:text-2xl text-left md:text-left leading-tight tracking-tight font-almostbold`;

const SubSectionHeading = tw(
  SectionHeading
)`font-black mt-8 text-left text-2xl sm:text-2xl lg:text-2xl !text-left md:text-left leading-tight tracking-tight font-almostbold`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-12 text-sm inline-block ml-3 text-center`,
  props.buttonRounded && tw`rounded-full`
]);


export default ({
  smallHeading = "Energy",
  smallHeadingone = "Industrial Equipment",
  heading = (
    <Heading id="profile">
      Company Profile
    </Heading>
  ),
  cards = [
    {
      imageSrc:Product1,
      title: "Trust",
      smallHeading: "Built on Dependability",
      content: "Earning the customer’s trust, highlights the importance of a good track record and longevity. For the customer, dependability means security of business.",
      url: "#"
    },
    {
      imageSrc:Product2,
      title: "Creating Together",
      smallHeading: "With our Customers",
      content: "When customers bring unique needs, together we create elegant solutions.",
      url: "#"
    },
    {
      imageSrc:Product2,
      title: "Integrity",
      smallHeading: "Enables Endurance in Business",
      content: "It is more than what we say, it is who we are and what we do. It is about our Customers.",
      url: "#"
    },
    {
      imageSrc:Product2,
      title: "Teamwork",
      smallHeading: "Finding our Strengths and Contributing to the Result",
      content: "We team with our customers, our suppliers and with ourselves.",
      url: "#"
    },
    {
      imageSrc:Product2,
      title: "Committed to People",
      smallHeading: "Committed to the Future",
      content: "We are improving quality of life and sustainability. We empathise with our customers as we enable their goals to be achieved, together we build the future of Australia.",
      url: "#"
    }
],
  description = "Since TIC's incorporation in 1978, it has continued to expand operations throughout Australia. The teams include development engineers, project managers and technical support staff. In addition to our local employees, we have access to Toshiba Group to provide assistance to ensure our projects are successfully delivered and supported. TIC has 2 key divisions, firstly energy and secondly industrial equipment.",
  subdescriptionmodone = "TIC is a market leader in the energy sector providing a range of services including project management, engineering services and field services encompassing installation, commissioning and maintenance. The services incorporate Toshiba's generators, turbines, transformers, switchgear and associated energy infrastructure for this industry. TIC's future focus is on developing and supporting the transition to a cleaner energy future incorporating asset performance management, hydro power, geothermal, energy storage, smart grid and hydrogen power systems. The Energy Division utilises its subsidiary (PGSR) to provide resource and tooling when required for site works undertaken by TIC.",
  subdescriptionmodtwo = "TIC supplies motor/drive systems into the mining, material handling, steel, oil and gas industries. This includes engineering, sales, service, warehouse and workshop facilities, testing and training facilities.",
  primaryButtonText = "Download organisational chart",
  primaryButtonUrl = "#",
  imageSrc = null,
  buttonRounded = false,
  imageRounded = false,
  imageBorder = false,
  imageShadow = false,
  showDecoratorBlob = true,
  textOnLeft = false,
  features = null,
  iconRoundedFull = true,
  iconFilled = true,
  iconContainerCss = null,
  contents = null,
  subsectionheading = "Our Values"
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.


  return (
    <Container id="profile">
      <TwoColumn>
      <TextColumn>
          <TextContent>
            <Heading>{heading}</Heading><br/>
            {description &&
                    <Description dangerouslySetInnerHTML={{__html: description}}/>
            }
            {/* {smallHeading && 
                <SmallHeading dangerouslySetInnerHTML={ {__html: smallHeading}} />
             } */}
            {/* <SmallHeading>{smallHeading}</SmallHeading>
            <Subdescription>{subdescriptionmodone}</Subdescription><br/> */}
            {/* <SmallHeading>{smallHeadingone}</SmallHeading>
            <Subdescription>{subdescriptionmodtwo}</Subdescription> */}

        
          </TextContent>
        </TextColumn>
        <ImageColumn>
          {/* img-overlay-text */}
        <div className="imageBanner">
          <Image src={imageSrc[0]?.url} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
          {showDecoratorBlob && <DecoratorBlob/>}
        </div>
        </ImageColumn>
      </TwoColumn>
      
      <SubContainer id="values">
       <TwoColumn>
      <SubSectionHeading dangerouslySetInnerHTML={{__html: subsectionheading}}
      />
      </TwoColumn>
    <ContentWithPaddingXl>
      <ThreeColumnContainer>        
          {cards.map((card, index) => (
          <SubSectionCard key={index} href={card.url} target={card.target}>
            <TextInfo className="xs-version">
              <TitleContainer>
                <Title>{card.title}</Title>
              </TitleContainer>
              <Ruler>
              <SubSectionSmallHeading>{card.smallHeading}</SubSectionSmallHeading>
              <SubSectionDescription>{card.content}</SubSectionDescription>             
              </Ruler>
            </TextInfo>
          </SubSectionCard>
          ))}
          <span class="test-traing">
          {showDecoratorBlob && <CardDecoratorBlob/>}
          </span>
          <span class="test-traingone">
          {showDecoratorBlob && <CardDecoratorBlobOne/>}
          </span>
          <span class="test-traingtwo">
          {showDecoratorBlob && <CardDecoratorBlobTwo/>}
          </span>
          <span class="test-traingthree">
          {showDecoratorBlob && <CardDecoratorBlobThree/>}
          </span>
          <span class="test-traingfour">
          {showDecoratorBlob && <CardDecoratorBlobFour/>}
          </span>
      </ThreeColumnContainer>
      <PrimaryButton><a href={primaryButtonUrl[0]?.url} target="_blank">{primaryButtonText}</a></PrimaryButton>
    </ContentWithPaddingXl>
  </SubContainer>
  
    </Container>
    
  );
};
