import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { useLocation } from 'react-router-dom';
import { css } from "styled-components/macro"; //eslint-disable-line
import GraphQlHandler from "api/craft/GraphQlHandler";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithImage.js";
import Services from "components/features/ThreeColCategory.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import defaultHeroImage from "images/Toshiba Blue Texture.jpg";
import defaultHeroIcon from "images/map.svg";

import styled from "styled-components";
import {
    HeroHeading as HeroHeadingBase,
    HeroSmallHeading as HeroSmallHeadingBase,
    SectionHeading
} from "./components/misc/Headings";
import { HR as HRBase } from "./components/misc/Layouts";
import CommonContent from "./commonContent";
import SubCategories from "./components/features/FourColSliderSubCategory";
import Products from "./components/features/ThreeColCategory";

const HR = tw(HRBase)``;



const Heading = tw(SectionHeading)`text-3xl`;
const HeroHeading = tw(
    HeroHeadingBase
)`pt-4 text-3xl md:text-5xl leading-extratight max-w-md
`;
const HeroSmallHeading = tw(
    HeroSmallHeadingBase
)`pt-4 text-3xl md:text-5xl leading-extratight max-w-md
`;
export default () => {
    const [mainEntryData, setMainEntryData] = useState([]);
    const [entries, setCategoryEntry] = useState([]);
    const [servicesEntries, setServicesEntries] = useState([]);
    const [cats, setCats] = useState("");
    const SmallHeading = tw.span`tracking-wider text-sm font-medium`;
    const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
    const Description = tw.span`inline-block mt-8`;
    const imageCss = tw``;
    const location = useLocation();
    const subPage = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1) !== "" ?
        true : false
    ;
    const slug = subPage ?
        window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1) : "*"
    ;
    const level = subPage ? "" : ", level: 1"; // If its a subpage we want to get level 2, but if top level only get 1

    useEffect(() => {
        GraphQlHandler.query(`
        query MyQuery {
          mainEntryData: servicesLPEntries {
            ... on servicesLP_servicesLP_Entry {
              id
              shortDescription
              subHeading
              heroIcon {
                ... on icons_Asset {
                  id
                  url
                }
              }
              keyBenefits {
                ... on keyBenefits_BlockType {
                  id
                  keyBenefitContent
                }
              }
              heading
              heroImage {
                ... on headerImages_Asset {
                  id
                  url
                }
              }
            }
          }
          entries(section: "servicesCategories", slug: "${slug}" ${level}) {
            ... on servicesCategories_default_Entry {
              id
              slug
              uri
              title
              heroImage {
                url
                id
              }
              categoryImage: servicesCategoryImage {
                url
                id
              }
              subHeading
              heading
              children {
                id
                title
                slug
                ... on servicesCategories_default_Entry {
                  id
                  uri
                  heading
                  categoryImage: servicesCategoryImage {
                    url
                    id
                  }
                  
                }
              }
              heroIcon {
                id
                url
              }
              shortDescription
              parent {
                id
                title
                uri
              }
            }
          }
        }

    `)
            .then((data) => {
                setMainEntryData(data.mainEntryData);
                setCategoryEntry(data.entries);
                var catsList = ""
                {(data.entries || []).map((entry) => {
                    catsList = catsList == "" ? "\""+entry.slug+"\"" : catsList+",\""+entry.slug+"\""
                    {(entry.children || []).map((children) => {
                        catsList = catsList == "" ? "\""+children.slug+"\"" : catsList+",\""+children.slug+"\""
                    } )}
                } )}
                setCats(catsList);
            });
    }, [location])
    useEffect(() => {
        GraphQlHandler.query(`
            query MyQuery {
              
              servicesEntries(relatedToEntries: {slug: [${cats}]}) {
                ... on services_default_Entry {
                  id
                  title
                  productLink {
                    id
                    uri
                  }
                  slug
                  uri
                  shortDescription
                  servicesCategory {
                    id
                    slug
                  }
                  categoryImage : servicesCategoryImage {
                    id
                    url
                    ... on productImages_Asset {
                      id
                      url
                    }
                  }
                  heroIcon {
                    id
                    url
                  }
                }
              }
            }
    
        `)
            .then((data) => {
                setServicesEntries(data.servicesEntries);
            });
    }, [cats, location])

    return (

        <AnimationRevealPage>

            {
                (entries || []).map((categoryEntry, index) =>
                    index == 0 &&
                    <>

                        <Hero
                              heading={
                                  <HeroSmallHeading>{subPage ? categoryEntry.heading : "Services"}</HeroSmallHeading>}
                              smallHeading={<></>}
                              breadcrumbType={<>Services</>}
                              breadcrumbName={subPage ? categoryEntry.heading : "All"}
                              parent={categoryEntry.parent}
                              children={categoryEntry.children}
                              description={subPage ? categoryEntry.mainDescription : "View our range of services below"}
                              iconSrc={subPage ? categoryEntry.heroIcon : mainEntryData[0].heroIcon}
                              iconAlt={<>{categoryEntry.heading}</>}
                              imageSrc={subPage ? categoryEntry.heroImage : mainEntryData[0].heroImage}
                              imageCss={imageCss}
                              subPage={subPage}

                        />

                    </>

                )
            }

            <Services
                heading={
                    <Heading>
                        Our Services
                    </Heading>
                }
                cards={servicesEntries}
            />
            <CommonContent
                showCompanies={false}
                showServices={false}
            />

            <Footer />
        </AnimationRevealPage>
    );
}
