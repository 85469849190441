import React from "react";
import tw from "twin.macro";
// import { ReadMoreToggler } from 'read-more-read-less-toggler';
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, SmallHeading as SmallHeadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import {customContentStyles, Description} from "components/misc/Typography.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed";

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
    width: 100% !important;
    height: auto !important;
    margin-top: 2% !important;
    display: flex !important;
    justify-content: center !important;
  
    
  ${tw`rounded flex-col justify-between items-center`}
  iframe {
    ${tw`rounded bg-black shadow-xl w-full`}
  }
`;

const Container = tw.div`relative pr-10 pl-10 py-0 pb-20 bg-gray-100`;
const TwoColumn = tw.div`flex flex-col py-0 md:flex-row justify-between max-w-screen-xl mx-auto py-0 md:pt-16 pb-0 items-center`;
const Column = tw.div`w-full p-0 max-w-xl mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`pr-20 mb-20 pr-0 mb-0 mt-16 md:mt-0 sm:pr-0 lg:pr-20 mb-0`,
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
],
    `
    padding: 15px;
    `
);

const TextContent = styled.div`
    ${tw`ml-0 mr-0 lg:pt-16 text-left md:text-left lg:ml-10 lg:mr-10 md:ml-10 md:m-10 mb-10`}
    ${customContentStyles}
`;
const SmallHeading = tw(SmallHeadingBase)`text-left text-lg md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-2xl sm:text-2xl lg:text-2xl text-left md:text-left leading-tight tracking-tight font-almostbold`;
// const ReadMoreToggler = tw.div`relative pr-10 pl-10 pt-0 pb-0`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-12 text-sm inline-block mx-auto md:mx-0 w-2/6 text-center lg:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);


export default ({
 smallHeading = "Our Commitment",
  heading = (
    <Heading id="commitment">
      We remain true to "Committed to People, Commited to the Future."
    </Heading>
  ),
  description = "This video shows some of Toshiba's contributions of the quest carbon neutrality and the realization of resillient infrastructure.",
  secondescription = "",
  secondsmallheading= "",
  secondheading = "",
  primaryButtonText = "Read More",
  primaryButtonUrl = "#",
  // imageSrc = SolutionImageSrc,
  video = null,
  secondvideo = null,
  buttonRounded = false,
  imageRounded = false,
  imageBorder = false,
  imageShadow = false,
  showDecoratorBlob = true,
  textOnLeft = false,
  features = null,
  iconRoundedFull = true,
  iconFilled = true,
  iconContainerCss = null,
  autoPlay = false,
  url, 
  background="black",
  className="video", 
  videoWidth="100%",
  contentMainTitle = "",
  subTitle = "",
  mainContent = "",
  showMainTitle = 'yes',
  showSubTitle = "yes"
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.


  return (
    <Container>
      <TwoColumn>
        <TextColumn>
          <TextContent>
          {contentMainTitle && showMainTitle == "yes" &&
                    <SmallHeading dangerouslySetInnerHTML={{__html: contentMainTitle}} />
            }
            {subTitle && showSubTitle == "yes" &&
                    <Heading dangerouslySetInnerHTML={{__html: subTitle}} />
            }
            { mainContent &&
                <Description dangerouslySetInnerHTML={{__html: mainContent}} />
            }
          </TextContent>
        </TextColumn>
        {video.length > 0 &&
                          <>{
                            video.map((video, index) => (
                                <StyledResponsiveVideoEmbed
                                    key={index}
                                    url={video.url}
                                    background="transparent"
                                    videoWidth={videoWidth}
                                />

                            ))
                          }</>
                      }

      </TwoColumn>
    </Container>
  );
};
